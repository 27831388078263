import { UploadAPI } from '../api';

export const TYPES = {
  UPLOAD_FILE_START: 'UPLOAD_FILE_START',
  UPLOAD_FILE_SIGNED: 'UPLOAD_FILE_UPLOAD_FILE_SIGNED',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_FAILURE: 'UPLOAD_FILE_FAILURE',
};

export const uploadImage = file => {
  return async dispatch => {
    try {
      const params = {
        filename: file.name,
        'content-type': file.type,
        kind: 'images',
        size: file.size,
      };

      dispatch({ type: TYPES.UPLOAD_FILE_START, payload: { params } });
      const imageData = await UploadAPI.GenerateUploadURL(params);
      dispatch({ type: TYPES.UPLOAD_FILE_SIGNED, payload: imageData });

      await UploadAPI.UploadFile(file, imageData.url);

      dispatch({ type: TYPES.UPLOAD_FILE_SUCCESS, payload: imageData });
    } catch (e) {
      dispatch({ type: TYPES.UPLOAD_FILE_FAILURE, payload: e });
    }
  };
};
