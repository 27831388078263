import { GendersAPI } from '../api';

export const TYPES = {
  GENDERS_FETCH_START: 'GENDERS_FETCH_START',
  GENDERS_FETCH_SUCCESS: 'GENDERS_FETCH_SUCCESS',
  GENDERS_FETCH_FAILURE: 'GENDERS_FETCH_FAILURE',
  GENDERS_FETCH_RESET: 'GENDERS_FETCH_RESET',

  GENDER_FETCH_START: 'GENDER_FETCH_START',
  GENDER_FETCH_SUCCESS: 'GENDER_FETCH_SUCCESS',
  GENDER_FETCH_FAILURE: 'GENDER_FETCH_FAILURE',
  GENDER_FETCH_RESET: 'GENDER_FETCH_RESET',

  GENDER_CREATE_START: 'GENDER_CREATE_START',
  GENDER_CREATE_SUCCESS: 'GENDER_CREATE_SUCCESS',
  GENDER_CREATE_FAILURE: 'GENDER_CREATE_FAILURE',
  GENDER_CREATE_RESET: 'GENDER_CREATE_RESET',

  GENDER_UPDATE_START: 'GENDER_UPDATE_START',
  GENDER_UPDATE_SUCCESS: 'GENDER_UPDATE_SUCCESS',
  GENDER_UPDATE_FAILURE: 'GENDER_UPDATE_FAILURE',
  GENDER_UPDATE_RESET: 'GENDER_UPDATE_RESET',

  GENDER_DELETE_START: 'GENDER_DELETE_START',
  GENDER_DELETE_SUCCESS: 'GENDER_DELETE_SUCCESS',
  GENDER_DELETE_FAILURE: 'GENDER_DELETE_FAILURE',
  GENDER_DELETE_RESET: 'GENDER_DELETE_RESET',
};

export const createGender = gender => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.GENDER_CREATE_START, payload: gender });
      const data = await GendersAPI.CreateGender(gender);
      dispatch({ type: TYPES.GENDER_CREATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.GENDER_CREATE_FAILURE, payload: e });
    }
  };
};

export const updateGender = gender => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.GENDER_UPDATE_START, payload: gender });
      const data = await GendersAPI.UpdateGender(gender);
      dispatch({ type: TYPES.GENDER_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.GENDER_UPDATE_FAILURE, payload: e });
    }
  };
};

export const deleteGender = gender => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.GENDER_DELETE_START, payload: gender });
      const data = await GendersAPI.DeleteGender(gender);
      dispatch({ type: TYPES.GENDER_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.GENDER_DELETE_FAILURE, payload: e });
    }
  };
};

export const fetchGender = id => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.GENDER_FETCH_START, payload: id });
      const data = await GendersAPI.FetchGender(id);
      dispatch({ type: TYPES.GENDER_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.GENDER_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchGenders = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.GENDERS_FETCH_START, payload: params });
      const data = await GendersAPI.FetchGenders(params);
      dispatch({ type: TYPES.GENDERS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.GENDERS_FETCH_FAILURE, payload: e });
    }
  };
};

export const resetGendersList = () => {
  return {
    type: TYPES.GENDERS_FETCH_RESET,
  };
};

export const resetActiveGender = () => {
  return {
    type: TYPES.GENDER_FETCH_RESET,
  };
};

export const setActiveGender = gender => {
  return {
    type: TYPES.GENDER_FETCH_SUCCESS,
    payload: gender,
  };
};
