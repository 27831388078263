import BaseAPI from './base-api';

const LookupsAPI = {};

LookupsAPI.FetchGenders = async params => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`lookups/genders?${query}`);

  return response.data;
};

LookupsAPI.FetchCategories = async params => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`lookups/categories?${query}`);

  return response.data;
};

LookupsAPI.FetchRootCategories = async params => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`lookups/root-categories?${query}`);

  return response.data;
};

LookupsAPI.FetchPartnerRoles = async params => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`lookups/partner-roles?${query}`);

  return response.data;
};

export default LookupsAPI;
