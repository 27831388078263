import { RoleActions } from '../actions';

import { MODE } from './mode';

const INITIAL_STATE = {
  rolesList: { data: [], params: {}, pager: null, error: null, loading: false },
  activeRole: { data: null, error: null, mode: null },
};

export const roles = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RoleActions.TYPES.ROLE_CREATE_RESET:
    case RoleActions.TYPES.ROLE_UPDATE_RESET:
    case RoleActions.TYPES.ROLE_DELETE_RESET:
    case RoleActions.TYPES.ROLE_FETCH_RESET:
      return {
        ...state,
        activeRole: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== ROLE CREATE START ==================================//
    case RoleActions.TYPES.ROLE_CREATE_START:
      return {
        ...state,
        activeRole: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case RoleActions.TYPES.ROLE_CREATE_FAILURE:
      return {
        ...state,
        activeRole: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case RoleActions.TYPES.ROLE_CREATE_SUCCESS:
      return {
        ...state,
        rolesList: {
          ...state.rolesList,
          data: state.rolesList.data.concat(action.payload),
          error: null,
          loading: false,
        },
        activeRole: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  ROLE CREATE END  ==================================//
    //================================== ROLE UPDATE START ==================================//
    case RoleActions.TYPES.ROLE_UPDATE_START:
      return {
        ...state,
        activeRole: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case RoleActions.TYPES.ROLE_UPDATE_FAILURE:
      return {
        ...state,
        activeRole: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case RoleActions.TYPES.ROLE_UPDATE_SUCCESS:
      return {
        ...state,
        rolesList: {
          ...state.rolesList,
          data: state.rolesList.data.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeRole: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  ROLE UPDATE END  ==================================//
    //================================== ROLE DELETE START ==================================//
    case RoleActions.TYPES.ROLE_DELETE_START:
      return {
        ...state,
        activeRole: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case RoleActions.TYPES.ROLE_DELETE_FAILURE:
      return {
        ...state,
        activeRole: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case RoleActions.TYPES.ROLE_DELETE_SUCCESS:
      return {
        ...state,
        rolesList: {
          ...state.rolesList,
          data: state.rolesList.data.filter(role => role.id !== action.payload.id),
          error: null,
          loading: false,
        },
        activeRole: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  ROLE DELETE END  ==================================//
    //================================== ROLE FETCH START ==================================//
    case RoleActions.TYPES.ROLE_FETCH_START:
      return {
        ...state,
        activeRole: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case RoleActions.TYPES.ROLE_FETCH_FAILURE:
      return {
        ...state,
        activeRole: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case RoleActions.TYPES.ROLE_FETCH_SUCCESS:
      return {
        ...state,
        activeRole: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  ROLE FETCH END  ==================================//
    //================================== ROLES FETCH START ==================================//
    case RoleActions.TYPES.ROLES_FETCH_START:
      return {
        ...state,
        rolesList: {
          data: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case RoleActions.TYPES.ROLES_FETCH_SUCCESS:
      return {
        ...state,
        rolesList: {
          ...state.rolesList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case RoleActions.TYPES.ROLES_FETCH_FAILURE:
      return {
        ...state,
        rolesList: {
          ...state.rolesList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case RoleActions.TYPES.ROLES_FETCH_RESET:
      return {
        ...state,
        rolesList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  ROLES FETCH END  ==================================//
    default:
      return state;
  }
};
