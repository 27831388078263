import { PaymentAPI } from '../api';

export const TYPES = {
  CREATE_PAYMENT_SESSION_START: 'CREATE_PAYMENT_SESSION_START',
  CREATE_PAYMENT_SESSION_SUCCESS: 'CREATE_PAYMENT_SESSION_SUCCESS',
  CREATE_PAYMENT_SESSION_FALIURE: 'CREATE_PAYMENT_SESSION_FALIURE',
  CREATE_PAYMENT_SESSION_RESET: 'CREATE_PAYMENT_SESSION_RESET',

  PAYMENT_AVAILABLE_TAGS_START: 'PAYMENT_AVAILABLE_TAGS_START',
  PAYMENT_AVAILABLE_TAGS_SUCCESS: 'PAYMENT_AVAILABLE_TAGS_SUCCESS',
  PAYMENT_AVAILABLE_TAGS_FALIURE: 'PAYMENT_AVAILABLE_TAGS_FALIURE',

  PAYMENT_SESSION_COMPLETE_START: 'PAYMENT_SESSION_COMPLETE_START',
  PAYMENT_SESSION_COMPLETE_SUCCESS: 'PAYMENT_SESSION_COMPLETE_SUCCESS',
  PAYMENT_SESSION_COMPLETE_FALIURE: 'PAYMENT_SESSION_COMPLETE_FALIURE',
  PAYMENT_SESSION_COMPLETE_RESET: 'PAYMENT_SESSION_COMPLETE_RESET',

  SAVE_SHIPPING_DATA_START: 'SAVE_SHIPPING_DATA_START',
  SAVE_SHIPPING_DATA_SUCCESS: 'SAVE_SHIPPING_DATA_SUCCESS',
  SAVE_SHIPPING_DATA_FALIURE: 'SAVE_SHIPPING_DATA_FALIURE',
  SAVE_SHIPPING_DATA_RESET: 'SAVE_SHIPPING_DATA_RESET',

  SHIPMENT_RESET: 'SHIPMENT_RESET',
  VALIDATE_SHIPPING_DATA_START: 'VALIDATE_SHIPPING_DATA_START',
  VALIDATE_SHIPPING_DATA_SUCCESS: 'VALIDATE_SHIPPING_DATA_SUCCESS',
  VALIDATE_SHIPPING_DATA_FALIURE: 'VALIDATE_SHIPPING_DATA_FALIURE',
  VALIDATE_SHIPPING_DATA_RESET: 'VALIDATE_SHIPPING_DATA_RESET',

  PAYMENT_TAG_SERIES_FETCH_START: 'PAYMENT_TAG_SERIES_FETCH_START',
  PAYMENT_TAG_SERIES_FETCH_SUCCESS: 'PAYMENT_TAG_SERIES_FETCH_SUCCESS',
  PAYMENT_TAG_SERIES_FETCH_FAILURE: 'PAYMENT_TAG_SERIES_FETCH_FAILURE',
  PAYMENT_TAG_SERIES_FETCH_RESET: 'PAYMENT_TAG_SERIES_FETCH_RESET',

  PAYMENT_PARTNER_FETCH_START: 'PAYMENT_PARTNER_FETCH_START',
  PAYMENT_PARTNER_FETCH_SUCCESS: 'PAYMENT_PARTNER_FETCH_SUCCESS',
  PAYMENT_PARTNER_FETCH_FAILURE: 'PAYMENT_PARTNER_FETCH_FAILURE',
  PAYMENT_PARTNER_FETCH_RESET: 'PAYMENT_PARTNER_FETCH_RESET',

  PAYMENT_SAVE_EMAIL_START: 'PAYMENT_SAVE_EMAIL_START',
  PAYMENT_SAVE_EMAIL_SUCCESS: 'PAYMENT_SAVE_EMAIL_SUCCESS',
  PAYMENT_SAVE_EMAIL_FAILURE: 'PAYMENT_SAVE_EMAIL_FAILURE',
  PAYMENT_SAVE_EMAIL_RESET: 'PAYMENT_SAVE_EMAIL_RESET',

  PAYMENT_TAG_ADDRESS_FETCH_START: 'PAYMENT_TAG_ADDRESS_FETCH_START',
  PAYMENT_TAG_ADDRESS_FETCH_SUCCESS: 'PAYMENT_TAG_ADDRESS_FETCH_SUCCESS',
  PAYMENT_TAG_ADDRESS_FETCH_FAILURE: 'PAYMENT_TAG_ADDRESS_FETCH_FAILURE',
  PAYMENT_TAG_ADDRESS_FETCH_RESET: 'PAYMENT_TAG_ADDRESS_FETCH_RESET',

  PAYMENT_CHECKOUT_SESSIONS_FETCH_START: 'PAYMENT_CHECKOUT_SESSIONS_FETCH_START',
  PAYMENT_CHECKOUT_SESSIONS_FETCH_SUCCESS: 'PAYMENT_CHECKOUT_SESSIONS_FETCH_SUCCESS',
  PAYMENT_CHECKOUT_SESSIONS_FETCH_FAILURE: 'PAYMENT_CHECKOUT_SESSIONS_FETCH_FAILURE',
  PAYMENT_CHECKOUT_SESSIONS_FETCH_RESET: 'PAYMENT_CHECKOUT_SESSIONS_FETCH_RESET',

  PAYMENT_LANDING_PAGE_DATA_FETCH_START: 'PAYMENT_LANDING_PAGE_DATA_FETCH_START',
  PAYMENT_LANDING_PAGE_DATA_FETCH_SUCCESS: 'PAYMENT_LANDING_PAGE_DATA_FETCH_SUCCESS',
  PAYMENT_LANDING_PAGE_DATA_FETCH_FAILURE: 'PAYMENT_LANDING_PAGE_DATA_FETCH_FAILURE',
  PAYMENT_LANDING_PAGE_DATA_FETCH_RESET: 'PAYMENT_LANDING_PAGE_DATA_FETCH_RESET',
};

export const fetchAvailableTag = (partnerId, tagSeriesId, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PAYMENT_AVAILABLE_TAGS_START, payload: partnerId });
      const data = await PaymentAPI.FetchAvailableTag(partnerId, tagSeriesId, params);
      dispatch({ type: TYPES.PAYMENT_AVAILABLE_TAGS_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PAYMENT_AVAILABLE_TAGS_FALIURE, payload: e });
    }
  };
};

export const createPaymentSession = (partnerId, checkout, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.CREATE_PAYMENT_SESSION_START, payload: partnerId });
      const data = await PaymentAPI.CreateCheckoutSession(partnerId, checkout, params);
      dispatch({ type: TYPES.CREATE_PAYMENT_SESSION_SUCCESS, payload: data });
      dispatch({ type: TYPES.SAVE_SHIPPING_DATA_SUCCESS, payload: data.tag_address });
    } catch (e) {
      dispatch({ type: TYPES.CREATE_PAYMENT_SESSION_FALIURE, payload: e });
    }
  };
};

export const paymentSessionSuccess = sessionId => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PAYMENT_SESSION_COMPLETE_START, payload: sessionId });
      const data = await PaymentAPI.CheckoutSessionSuccess(sessionId);
      dispatch({ type: TYPES.PAYMENT_SESSION_COMPLETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PAYMENT_SESSION_COMPLETE_FALIURE, payload: e });
    }
  };
};

export const saveShippingAddress = (sessionId, tagShippingAddress) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.SAVE_SHIPPING_DATA_START, payload: sessionId });
      const data = await PaymentAPI.SaveShippingAddress(sessionId, tagShippingAddress);
      dispatch({ type: TYPES.SAVE_SHIPPING_DATA_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.SAVE_SHIPPING_DATA_FALIURE, payload: e });
    }
  };
};

export const createShipment = (partnerId, shippingAddress) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.VALIDATE_SHIPPING_DATA_START, payload: shippingAddress });
      const data = await PaymentAPI.CreateShipment(partnerId, shippingAddress);
      dispatch({ type: TYPES.VALIDATE_SHIPPING_DATA_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.VALIDATE_SHIPPING_DATA_FALIURE, payload: e });
    }
  };
};

export const resetShipment = () => {
  return {
    type: TYPES.SHIPMENT_RESET,
  };
};

export const fetchPartner = id => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PAYMENT_PARTNER_FETCH_START, payload: id });
      const data = await PaymentAPI.FetchPartner(id);
      dispatch({ type: TYPES.PAYMENT_PARTNER_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PAYMENT_PARTNER_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchTagSeries = (partnerId, tagSeriesId, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PAYMENT_TAG_SERIES_FETCH_START, payload: params });
      const data = await PaymentAPI.FetchTagSeries(partnerId, tagSeriesId, params);
      dispatch({ type: TYPES.PAYMENT_TAG_SERIES_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PAYMENT_TAG_SERIES_FETCH_FAILURE, payload: e });
    }
  };
};

export const saveEmail = email => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PAYMENT_SAVE_EMAIL_START, payload: email });
      const data = await PaymentAPI.SaveEmail(email);
      dispatch({ type: TYPES.PAYMENT_SAVE_EMAIL_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PAYMENT_SAVE_EMAIL_FAILURE, payload: e });
    }
  };
};

// CreateCheckoutSession

export const fetchTagAddress = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PAYMENT_TAG_ADDRESS_FETCH_START, payload: params });
      const data = await PaymentAPI.FetchTagAddress(params);
      dispatch({ type: TYPES.PAYMENT_TAG_ADDRESS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PAYMENT_TAG_ADDRESS_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchCheckoutSessions = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PAYMENT_CHECKOUT_SESSIONS_FETCH_START, payload: params });
      const data = await PaymentAPI.FetchCheckoutSessions(params);
      dispatch({ type: TYPES.PAYMENT_CHECKOUT_SESSIONS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PAYMENT_CHECKOUT_SESSIONS_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchPartnerData = id => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PAYMENT_LANDING_PAGE_DATA_FETCH_START, payload: id });
      const data = await PaymentAPI.FetchPartnerData(id);
      dispatch({ type: TYPES.PAYMENT_LANDING_PAGE_DATA_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PAYMENT_LANDING_PAGE_DATA_FETCH_FAILURE, payload: e });
    }
  };
};
