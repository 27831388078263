import { PartnerActions } from '../actions';

import { MODE } from './mode';

const INITIAL_STATE = {
  partnersList: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerUsersList: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerTagSeriesList: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerTagsList: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerTagsPrintList: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerLabelsPrintList: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerEventsList: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerBoostersList: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerBoostersTemplatesList: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerCheckinsList: { data: [], params: {}, pager: null, error: null, loading: false },
  leaderboardList: { data: [], params: {}, pager: null, error: null, loading: false },
  soldTagsList: { data: [], params: {}, pager: null, mode: null, error: null, loading: false },
  partnerStoresList: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerSponsorsList: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerRewardsList: { data: [], params: {}, pager: null, error: null, loading: false },
  activePartnerStore: { data: null, error: null, mode: null },
  activePartnerSponsor: { data: null, error: null, mode: null },
  activePartnerReward: { data: null, error: null, mode: null },
  activePartner: { data: null, error: null, mode: null },
  activeTagSeries: { data: null, error: null, mode: null },
  activeEvent: { data: null, error: null, mode: null },
  activeBooster: { data: null, error: null, mode: null },
  activeBoosterTemplate: { data: null, error: null, mode: null },
  referralCode: { data: null, error: null, mode: null },
  activeTag: { data: null, error: null, mode: null },
};

export const partners = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PartnerActions.TYPES.PARTNER_CREATE_RESET:
    case PartnerActions.TYPES.PARTNER_UPDATE_RESET:
    case PartnerActions.TYPES.PARTNER_DELETE_RESET:
    case PartnerActions.TYPES.PARTNER_FETCH_RESET:
      return {
        ...state,
        activePartner: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== PARTNER CREATE START ==================================//
    case PartnerActions.TYPES.PARTNER_CREATE_START:
      return {
        ...state,
        activePartner: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_CREATE_FAILURE:
      return {
        ...state,
        activePartner: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_CREATE_SUCCESS:
      return {
        ...state,
        partnersList: {
          ...state.partnersList,
          data: state.partnersList.data.concat(action.payload),
          error: null,
          loading: false,
        },
        activePartner: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  PARTNER CREATE END  ==================================//
    //================================== PARTNER UPDATE START ==================================//
    case PartnerActions.TYPES.PARTNER_UPDATE_START:
      return {
        ...state,
        activePartner: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_UPDATE_FAILURE:
      return {
        ...state,
        activePartner: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_UPDATE_SUCCESS:
      return {
        ...state,
        partnersList: {
          ...state.partnersList,
          data: state.partnersList.data.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activePartner: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  PARTNER UPDATE END  ==================================//
    //================================== PARTNER DELETE START ==================================//
    case PartnerActions.TYPES.PARTNER_DELETE_START:
      return {
        ...state,
        activePartner: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case PartnerActions.TYPES.PARTNER_DELETE_FAILURE:
      return {
        ...state,
        activePartner: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case PartnerActions.TYPES.PARTNER_DELETE_SUCCESS:
      return {
        ...state,
        partnersList: {
          ...state.partnersList,
          data: state.partnersList.data.filter(partner => partner.id !== action.payload.id),
          error: null,
          loading: false,
        },
        activePartner: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  PARTNER DELETE END  ==================================//
    //================================== PARTNER FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_FETCH_START:
      return {
        ...state,
        activePartner: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_FETCH_FAILURE:
      return {
        ...state,
        activePartner: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_FETCH_SUCCESS:
      return {
        ...state,
        activePartner: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  PARTNER FETCH END  ==================================//
    //================================== PARTNERS FETCH START ==================================//
    case PartnerActions.TYPES.PARTNERS_FETCH_START:
      return {
        ...state,
        partnersList: {
          ...state.partnersList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case PartnerActions.TYPES.PARTNERS_FETCH_SUCCESS:
      return {
        ...state,
        partnersList: {
          ...state.partnersList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNERS_FETCH_FAILURE:
      return {
        ...state,
        partnersList: {
          ...state.partnersList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNERS_FETCH_RESET:
      return {
        ...state,
        partnersList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PARTNERS FETCH END  ==================================//
    //================================== PARTNER USERS FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_USERS_FETCH_START:
      return {
        ...state,
        partnerUsersList: {
          ...state.partnerUsersList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case PartnerActions.TYPES.PARTNER_USERS_FETCH_SUCCESS:
      return {
        ...state,
        partnerUsersList: {
          ...state.partnerUsersList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_USERS_FETCH_FAILURE:
      return {
        ...state,
        partnerUsersList: {
          ...state.partnerUsersList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_USERS_FETCH_RESET:
      return {
        ...state,
        partnerUsersList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PARTNER USERS FETCH END  ==================================//
    //================================== PARTNER USERS CREATE START ==================================//
    case PartnerActions.TYPES.PARTNER_USER_CREATE_START:
      return {
        ...state,
        activePartner: {
          ...state.activePartner,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_USER_CREATE_SUCCESS:
      return {
        ...state,
        activePartner: {
          ...state.activePartner,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_USER_CREATE_FAILURE:
      return {
        ...state,
        activePartner: {
          ...state.activePartner,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_USER_CREATE_RESET:
      return {
        ...state,
        activePartner: {
          ...state.activePartner,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  PARTNER USERS FETCH END  ==================================//
    //================================== PARTNER TAG SERIES FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_TAG_SERIES_FETCH_START:
      return {
        ...state,
        partnerTagSeriesList: {
          ...state.partnerTagSeriesList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAG_SERIES_FETCH_SUCCESS:
      return {
        ...state,
        partnerTagSeriesList: {
          ...state.partnerTagSeriesList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAG_SERIES_FETCH_FAILURE:
      return {
        ...state,
        partnerTagSeriesList: {
          ...state.partnerTagSeriesList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAG_SERIES_FETCH_RESET:
      return {
        ...state,
        partnerTagSeriesList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PARTNER TAG SERIES FETCH END  ==================================//
    //================================== PARTNER TAG SERIES CREATE START ==================================//
    case PartnerActions.TYPES.PARTNER_TAG_SERIES_CREATE_START:
      return {
        ...state,
        activeTagSeries: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAG_SERIES_CREATE_FAILURE:
      return {
        ...state,
        activeTagSeries: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAG_SERIES_CREATE_SUCCESS:
      return {
        ...state,
        activeTagSeries: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  PARTNER TAG SERIES CREATE END  ==================================//
    //================================== PARTNER TAG SERIE FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_TAG_SERIE_FETCH_START:
      return {
        ...state,
        activeTagSeries: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAG_SERIE_FETCH_FAILURE:
      return {
        ...state,
        activeTagSeries: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAG_SERIE_FETCH_SUCCESS:
      return {
        ...state,
        activeTagSeries: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAG_SERIE_FETCH_RESET:
      return {
        ...state,
        activeTagSeries: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //==================================  PARTNER TAG SERIE FETCH END  ==================================//
    //================================== PARTNER TAG SERIE UPDATE START ==================================//
    case PartnerActions.TYPES.PARTNER_TAG_SERIES_UPDATE_START:
      return {
        ...state,
        activeTagSeries: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAG_SERIES_UPDATE_FAILURE:
      return {
        ...state,
        activeTagSeries: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAG_SERIES_UPDATE_SUCCESS:
      return {
        ...state,
        activeTagSeries: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  PARTNER TAG SERIE UPDATE END  ==================================//
    //================================== PARTNER TAG FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_TAG_FETCH_START:
      return {
        ...state,
        activeTag: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAG_FETCH_FAILURE:
      return {
        ...state,
        activeTag: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAG_FETCH_SUCCESS:
      return {
        ...state,
        activeTag: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  PARTNER TAG FETCH END  ==================================//
    //================================== PARTNER TAG UPDATE START ==================================//
    case PartnerActions.TYPES.PARTNER_TAG_UPDATE_START:
      return {
        ...state,
        activeTag: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAG_UPDATE_FAILURE:
      return {
        ...state,
        activeTag: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAG_UPDATE_SUCCESS:
      return {
        ...state,
        activeTag: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  PARTNER TAG UPDATE END  ==================================//
    //================================== PARTNER TAGS FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_TAGS_FETCH_START:
      return {
        ...state,
        partnerTagsList: {
          ...state.partnerTagsList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAGS_FETCH_SUCCESS:
      return {
        ...state,
        partnerTagsList: {
          ...state.partnerTagsList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAGS_FETCH_FAILURE:
      return {
        ...state,
        partnerTagsList: {
          ...state.partnerTagsList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
        activeTagSeries: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAGS_FETCH_RESET:
      return {
        ...state,
        partnerTagsList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PARTNER TAGS FETCH END  ==================================//
    //================================== PARTNER EVENTS FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_EVENTS_FETCH_START:
      return {
        ...state,
        partnerEventsList: {
          ...state.partnerEventsList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case PartnerActions.TYPES.PARTNER_EVENTS_FETCH_SUCCESS:
      return {
        ...state,
        partnerEventsList: {
          ...state.partnerEventsList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_EVENTS_FETCH_FAILURE:
      return {
        ...state,
        partnerEventsList: {
          ...state.partnerEventsList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_EVENTS_FETCH_RESET:
      return {
        ...state,
        partnerEventsList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PARTNER EVENTS FETCH END  ==================================//
    //================================== PARTNER EVENT CREATE START ==================================//
    case PartnerActions.TYPES.PARTNER_EVENT_CREATE_START:
      return {
        ...state,
        activeEvent: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_EVENT_CREATE_FAILURE:
      return {
        ...state,
        activeEvent: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_EVENT_CREATE_SUCCESS:
      return {
        ...state,
        activeEvent: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  PARTNER EVENT CREATE END  ==================================//
    //================================== PARTNER BOOSTER CREATE START ==================================//
    case PartnerActions.TYPES.PARTNER_BOOSTER_CREATE_START:
      return {
        ...state,
        activeBooster: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTER_CREATE_FAILURE:
      return {
        ...state,
        activeBooster: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTER_CREATE_SUCCESS:
      return {
        ...state,
        activeBooster: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  PARTNER BOOSTER CREATE END  ==================================//
    //================================== PARTNER BOOSTER FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_BOOSTER_FETCH_START:
      return {
        ...state,
        activeBooster: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTER_FETCH_FAILURE:
      return {
        ...state,
        activeBooster: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTER_FETCH_SUCCESS:
      return {
        ...state,
        activeBooster: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTER_FETCH_RESET:
      return {
        ...state,
        activeBooster: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //==================================  PARTNER BOOSTER FETCH END  ==================================//
    //================================== PARTNER BOOSTERS FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_BOOSTERS_FETCH_START:
      return {
        ...state,
        partnerBoostersList: {
          ...state.partnerBoostersList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTERS_FETCH_SUCCESS:
      return {
        ...state,
        partnerBoostersList: {
          ...state.partnerBoostersList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTERS_FETCH_FAILURE:
      return {
        ...state,
        partnerBoostersList: {
          ...state.partnerBoostersList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTERS_FETCH_RESET:
      return {
        ...state,
        partnerBoostersList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PARTNER BOOSTERS FETCH END  ==================================//
    //================================== PARTNER BOOSTER UPDATE START ==================================//
    case PartnerActions.TYPES.PARTNER_BOOSTER_UPDATE_START:
      return {
        ...state,
        activeBooster: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTER_UPDATE_FAILURE:
      return {
        ...state,
        activeBooster: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTER_UPDATE_SUCCESS:
      return {
        ...state,
        activeBooster: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  PARTNER BOOSTER UPDATE END  ==================================//
    //================================== PARTNER EVENT FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_EVENT_FETCH_START:
      return {
        ...state,
        activeEvent: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_EVENT_FETCH_FAILURE:
      return {
        ...state,
        activeEvent: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_EVENT_FETCH_SUCCESS:
      return {
        ...state,
        activeEvent: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_EVENT_FETCH_RESET:
      return {
        ...state,
        activeEvent: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //==================================  PARTNER EVENT FETCH END  ==================================//
    //================================== PARTNER EVENT UPDATE START ==================================//
    case PartnerActions.TYPES.PARTNER_EVENT_UPDATE_START:
      return {
        ...state,
        activeEvent: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_EVENT_UPDATE_FAILURE:
      return {
        ...state,
        activeEvent: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_EVENT_UPDATE_SUCCESS:
      return {
        ...state,
        activeEvent: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  PARTNER EVENT UPDATE END  ==================================//
    //================================== PARTNER TAGS PRINT FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_TAGS_PRINT_FETCH_START:
      return {
        ...state,
        partnerTagsPrintList: {
          ...state.partnerTagsPrintList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAGS_PRINT_FETCH_SUCCESS:
      return {
        ...state,
        partnerTagsPrintList: {
          ...state.partnerTagsPrintList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAGS_PRINT_FETCH_FAILURE:
      return {
        ...state,
        partnerTagsPrintList: {
          ...state.partnerTagsPrintList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_TAGS_PRINT_FETCH_RESET:
      return {
        ...state,
        partnerTagsPrintList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PARTNER TAGS PRINT FETCH END  ==================================//
    //================================== SHIPPING LABELS PRINT FETCH START ==================================//
    case PartnerActions.TYPES.SHIPPING_LABELS_PRINT_FETCH_START:
      return {
        ...state,
        partnerLabelsPrintList: {
          ...state.partnerLabelsPrintList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case PartnerActions.TYPES.SHIPPING_LABELS_PRINT_FETCH_SUCCESS:
      return {
        ...state,
        partnerLabelsPrintList: {
          ...state.partnerLabelsPrintList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case PartnerActions.TYPES.SHIPPING_LABELS_PRINT_FETCH_FAILURE:
      return {
        ...state,
        partnerLabelsPrintList: {
          ...state.partnerLabelsPrintList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case PartnerActions.TYPES.SHIPPING_LABELS_PRINT_FETCH_RESET:
      return {
        ...state,
        partnerLabelsPrintList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  SHIPPING LABELS PRINT FETCH END  ==================================//
    //================================== PARTNER LEADERBOARD FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_LEADERBOARD_FETCH_START:
      return {
        ...state,
        leaderboardList: {
          ...state.leaderboardList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case PartnerActions.TYPES.PARTNER_FETCH_LEADERBOARD_SUCCESS:
      return {
        ...state,
        leaderboardList: {
          ...state.leaderboardList,
          data: action.payload,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_FETCH_LEADERBOARD_FAILURE:
      return {
        ...state,
        leaderboardList: {
          ...state.leaderboardList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_FETCH_LEADERBOARD_RESET:
      return {
        ...state,
        leaderboardList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PARTNER TAGS FETCH END  ==================================//
    //================================== PARTNER CHECKINS FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_CHECKINS_FETCH_START:
      return {
        ...state,
        partnerCheckinsList: {
          ...state.partnerCheckinsList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case PartnerActions.TYPES.PARTNER_CHECKINS_FETCH_SUCCESS:
      return {
        ...state,
        partnerCheckinsList: {
          ...state.partnerCheckinsList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_CHECKINS_FETCH_FAILURE:
      return {
        ...state,
        partnerCheckinsList: {
          ...state.partnerCheckinsList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_CHECKINS_FETCH_RESET:
      return {
        ...state,
        partnerCheckinsList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PARTNER CHECKINS FETCH END  ==================================//
    //================================== PARTNERS SELL TAGS START ==================================//
    case PartnerActions.TYPES.PARTNER_SELL_TAGS_START:
      return {
        ...state,
        soldTagsList: {
          ...state.soldTagsList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case PartnerActions.TYPES.PARTNER_SELL_TAGS_SUCCESS:
      return {
        ...state,
        soldTagsList: {
          ...state.soldTagsList,
          mode: MODE.CREATE,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_SELL_TAGS_FAILURE:
      return {
        ...state,
        soldTagsList: {
          ...state.soldTagsList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_SELL_TAGS_RESET:
      return {
        ...state,
        soldTagsList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PARTNERS SELL TAGS END  ==================================//
    //================================== PARTNER STORES FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_STORES_FETCH_START:
      return {
        ...state,
        partnerStoresList: {
          ...state.partnerStoresList,
          params: action.payload,
          error: null,
          loading: true,
        },
      };
    case PartnerActions.TYPES.PARTNER_STORES_FETCH_SUCCESS:
      return {
        ...state,
        partnerStoresList: {
          ...state.partnerStoresList,
          data: action.payload.results,
          error: null,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_STORES_FETCH_FAILURE:
      return {
        ...state,
        partnerStoresList: {
          ...state.partnerStoresList,
          data: [],
          error: action.payload,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_STORES_FETCH_RESET:
      return {
        ...state,
        partnerStoresList: {
          data: [],
          params: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PARTNER STORES FETCH END  ==================================//
    //================================== PARTNER STORE CREATE START ==================================//
    case PartnerActions.TYPES.PARTNER_STORE_CREATE_START:
      return {
        ...state,
        activePartnerStore: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_STORE_CREATE_FAILURE:
      return {
        ...state,
        activePartnerStore: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_STORE_CREATE_SUCCESS:
      return {
        ...state,
        activePartnerStore: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  PARTNER STORE CREATE END  ==================================//
    //================================== PARTNER STORE FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_STORE_FETCH_START:
      return {
        ...state,
        activePartnerStore: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_STORE_FETCH_FAILURE:
      return {
        ...state,
        activePartnerStore: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_STORE_FETCH_SUCCESS:
      return {
        ...state,
        activePartnerStore: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_STORE_FETCH_RESET:
      return {
        ...state,
        activePartnerStore: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //==================================  PARTNER STORE FETCH END  ==================================//
    //================================== PARTNER STORE UPDATE START ==================================//
    case PartnerActions.TYPES.PARTNER_STORE_UPDATE_START:
      return {
        ...state,
        activePartnerStore: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_STORE_UPDATE_FAILURE:
      return {
        ...state,
        activePartnerStore: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_STORE_UPDATE_SUCCESS:
      return {
        ...state,
        activePartnerStore: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  PARTNER STORE UPDATE END  ==================================//
    //================================== PARTNER SPONSORS FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_SPONSORS_FETCH_START:
      return {
        ...state,
        partnerSponsorsList: {
          ...state.partnerSponsorsList,
          params: action.payload,
          error: null,
          loading: true,
        },
      };
    case PartnerActions.TYPES.PARTNER_SPONSORS_FETCH_SUCCESS:
      return {
        ...state,
        partnerSponsorsList: {
          ...state.partnerSponsorsList,
          data: action.payload.results,
          error: null,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_SPONSORS_FETCH_FAILURE:
      return {
        ...state,
        partnerSponsorsList: {
          ...state.partnerSponsorsList,
          data: [],
          error: action.payload,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_SPONSORS_FETCH_RESET:
      return {
        ...state,
        partnerSponsorsList: {
          data: [],
          params: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PARTNER SPONSORS FETCH END  ==================================//
    //================================== PARTNER SPONSOR CREATE START ==================================//
    case PartnerActions.TYPES.PARTNER_SPONSOR_CREATE_START:
      return {
        ...state,
        activePartnerSponsor: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_SPONSOR_CREATE_FAILURE:
      return {
        ...state,
        activePartnerSponsor: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_SPONSOR_CREATE_SUCCESS:
      return {
        ...state,
        activePartnerSponsor: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  PARTNER SPONSOR CREATE END  ==================================//
    //================================== PARTNER SPONSOR FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_SPONSOR_FETCH_START:
      return {
        ...state,
        activePartnerSponsor: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_SPONSOR_FETCH_FAILURE:
      return {
        ...state,
        activePartnerSponsor: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_SPONSOR_FETCH_SUCCESS:
      return {
        ...state,
        activePartnerSponsor: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_SPONSOR_FETCH_RESET:
      return {
        ...state,
        activePartnerSponsor: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //==================================  PARTNER SPONSOR FETCH END  ==================================//
    //================================== PARTNER SPONSOR UPDATE START ==================================//
    case PartnerActions.TYPES.PARTNER_SPONSOR_UPDATE_START:
      return {
        ...state,
        activePartnerSponsor: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_SPONSOR_UPDATE_FAILURE:
      return {
        ...state,
        activePartnerSponsor: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_SPONSOR_UPDATE_SUCCESS:
      return {
        ...state,
        activePartnerSponsor: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  PARTNER SPONSOR UPDATE END  ==================================//
    //================================== PARTNER REWARDS FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_REWARDS_FETCH_START:
      return {
        ...state,
        partnerRewardsList: {
          ...state.partnerRewardsList,
          params: action.payload,
          error: null,
          loading: true,
        },
      };
    case PartnerActions.TYPES.PARTNER_REWARDS_FETCH_SUCCESS:
      return {
        ...state,
        partnerRewardsList: {
          ...state.partnerRewardsList,
          data: action.payload.results,
          error: null,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_REWARDS_FETCH_FAILURE:
      return {
        ...state,
        partnerRewardsList: {
          ...state.partnerRewardsList,
          data: [],
          error: action.payload,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_REWARDS_FETCH_RESET:
      return {
        ...state,
        partnerRewardsList: {
          data: [],
          params: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PARTNER REWARDS FETCH END  ==================================//
    //================================== PARTNER REWARD CREATE START ==================================//
    case PartnerActions.TYPES.PARTNER_REWARD_CREATE_START:
      return {
        ...state,
        activePartnerReward: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_REWARD_CREATE_FAILURE:
      return {
        ...state,
        activePartnerReward: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_REWARD_CREATE_SUCCESS:
      return {
        ...state,
        activePartnerReward: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  PARTNER REWARD CREATE END  ==================================//
    //================================== PARTNER REWARD FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_REWARD_FETCH_START:
      return {
        ...state,
        activePartnerReward: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_REWARD_FETCH_FAILURE:
      return {
        ...state,
        activePartnerReward: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_REWARD_FETCH_SUCCESS:
      return {
        ...state,
        activePartnerReward: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_REWARD_FETCH_RESET:
      return {
        ...state,
        activePartnerReward: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //==================================  PARTNER REWARD FETCH END  ==================================//
    //================================== PARTNER REWARD UPDATE START ==================================//
    case PartnerActions.TYPES.PARTNER_REWARD_UPDATE_START:
      return {
        ...state,
        activePartnerReward: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_REWARD_UPDATE_FAILURE:
      return {
        ...state,
        activePartnerReward: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_REWARD_UPDATE_SUCCESS:
      return {
        ...state,
        activePartnerReward: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  PARTNER REWARD UPDATE END  ==================================//
    //================================== PARTNER BOOSTER-TEMPLATE CREATE START ==================================//
    case PartnerActions.TYPES.PARTNER_BOOSTER_TEMPLATE_CREATE_START:
      return {
        ...state,
        activeBoosterTemplate: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTER_TEMPLATE_CREATE_FAILURE:
      return {
        ...state,
        activeBoosterTemplate: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTER_TEMPLATE_CREATE_SUCCESS:
      return {
        ...state,
        activeBoosterTemplate: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  PARTNER BOOSTER-TEMPLATE CREATE END  ==================================//
    //================================== PARTNER BOOSTER-TEMPLATE FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_BOOSTER_TEMPLATE_FETCH_START:
      return {
        ...state,
        activeBoosterTemplate: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTER_TEMPLATE_FETCH_FAILURE:
      return {
        ...state,
        activeBoosterTemplate: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTER_TEMPLATE_FETCH_SUCCESS:
      return {
        ...state,
        activeBoosterTemplate: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTER_TEMPLATE_FETCH_RESET:
      return {
        ...state,
        activeBoosterTemplate: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //==================================  PARTNER BOOSTER-TEMPLATE FETCH END  ==================================//
    //================================== PARTNER BOOSTERS-TEMPLATES FETCH START ==================================//
    case PartnerActions.TYPES.PARTNER_BOOSTERS_TEMPLATES_FETCH_START:
      return {
        ...state,
        partnerBoostersTemplatesList: {
          ...state.partnerBoostersTemplatesList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTERS_TEMPLATES_FETCH_SUCCESS:
      return {
        ...state,
        partnerBoostersTemplatesList: {
          ...state.partnerBoostersTemplatesList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTERS_TEMPLATES_FETCH_FAILURE:
      return {
        ...state,
        partnerBoostersTemplatesList: {
          ...state.partnerBoostersTemplatesList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTERS_TEMPLATES_FETCH_RESET:
      return {
        ...state,
        partnerBoostersTemplatesList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PARTNER BOOSTERS-TEMPLATES FETCH END  ==================================//
    //================================== PARTNER BOOSTER-TEMPLATE UPDATE START ==================================//
    case PartnerActions.TYPES.PARTNER_BOOSTER_TEMPLATE_UPDATE_START:
      return {
        ...state,
        activeBoosterTemplate: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTER_TEMPLATE_UPDATE_FAILURE:
      return {
        ...state,
        activeBoosterTemplate: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case PartnerActions.TYPES.PARTNER_BOOSTER_TEMPLATE_UPDATE_SUCCESS:
      return {
        ...state,
        activeBoosterTemplate: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  PARTNER BOOSTER-TEMPLATE UPDATE END  ==================================//
    //================================== REFERRAL_CODE FETCH START ==================================//
    case PartnerActions.TYPES.REFERRAL_CODE_FETCH_START:
      return {
        ...state,
        referralCode: {
          data: null,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.REFERRAL_CODE_FETCH_FAILURE:
      return {
        ...state,
        referralCode: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case PartnerActions.TYPES.REFERRAL_CODE_FETCH_SUCCESS:
      return {
        ...state,
        referralCode: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  REFERRAL_CODE FETCH END  ==================================//
    default:
      return state;
  }
};
