import { AccountActions } from '../actions';

import { MODE } from './mode';

const INITIAL_STATE = {
  token: typeof localStorage.getItem('token') === 'string' ? localStorage.getItem('token') : '',
  refreshToken:
    typeof localStorage.getItem('refresh_token') === 'string'
      ? localStorage.getItem('refresh_token')
      : '',
  user: null,
  error: null,
  loading: false,
  params: null,
  // emailConfirmed parameter should ony be used for confirmEmail page
  emailConfirmation: null,

  mode: MODE.VIEW,
};

export const account = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AccountActions.TYPES.ACCOUNT_REFRESH_TOKEN_START:
    case AccountActions.TYPES.ACCOUNT_LOGIN_START:
    case AccountActions.TYPES.ACCOUNT_GOOGLE_LOGIN_START:
    case AccountActions.TYPES.ACCOUNT_FACEBOOK_LOGIN_START:
    case AccountActions.TYPES.ACCOUNT_REGISTER_START:
    case AccountActions.TYPES.ACCOUNT_FORGOT_PASSWORD_START:
    case AccountActions.TYPES.ACCOUNT_RESET_PASSWORD_START:
    case AccountActions.TYPES.ACCOUNT_FETCH_START:
    case AccountActions.TYPES.ACCOUNT_UPDATE_START:
    case AccountActions.TYPES.ACCOUNT_ACCEPT_INVITE_START:
    case AccountActions.TYPES.ACCOUNT_LOGOUT_START:
      return {
        ...state,
        error: null,
        loading: true,
        params: action.payload,
      };
    case AccountActions.TYPES.ACCOUNT_LOGIN_FAILURE:
    case AccountActions.TYPES.ACCOUNT_GOOGLE_LOGIN_FAILURE:
    case AccountActions.TYPES.ACCOUNT_FACEBOOK_LOGIN_FAILURE:
    case AccountActions.TYPES.ACCOUNT_REGISTER_FAILURE:
    case AccountActions.TYPES.ACCOUNT_FORGOT_PASSWORD_FAILURE:
    case AccountActions.TYPES.ACCOUNT_FETCH_FAILURE:
    case AccountActions.TYPES.ACCOUNT_UPDATE_FAILURE:
    case AccountActions.TYPES.ACCOUNT_ACCEPT_INVITE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case AccountActions.TYPES.ACCOUNT_LOGIN_SUCCESS:
    case AccountActions.TYPES.ACCOUNT_GOOGLE_LOGIN_SUCCESS:
    case AccountActions.TYPES.ACCOUNT_FACEBOOK_LOGIN_SUCCESS:
    case AccountActions.TYPES.ACCOUNT_REGISTER_SUCCESS:
      localStorage.setItem('token', action.payload.token ? action.payload.token : '');
      localStorage.setItem(
        'refresh_token',
        action.payload.refresh_token ? action.payload.refresh_token : ''
      );

      return {
        ...state,
        loading: false,
        error: null,
        params: null,
        token: action.payload.token,
        refreshToken: action.payload.refresh_token,
        user: action.payload.user,
      };
    case AccountActions.TYPES.ACCOUNT_REFRESH_TOKEN_SUCCESS:
      localStorage.setItem('token', action.payload.token ? action.payload.token : '');
      localStorage.setItem(
        'refresh_token',
        action.payload.refresh_token ? action.payload.refresh_token : ''
      );
      return {
        ...state,
        loading: false,
        error: null,
        params: null,
        token: action.payload.token,
        refreshToken: action.payload.refresh_token,
      };
    case AccountActions.TYPES.ACCOUNT_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        params: null,
      };
    case AccountActions.TYPES.ACCOUNT_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        params: null,
      };
    case AccountActions.TYPES.ACCOUNT_REFRESH_TOKEN_FAILURE:
    case AccountActions.TYPES.ACCOUNT_LOGOUT_SUCCESS:
      localStorage.removeItem('token');
      localStorage.removeItem('refresh_token');
      return {
        token: '',
        refreshToken: '',
        user: null,
        error: null,
        loading: false,
        params: null,
      };
    case AccountActions.TYPES.ACCOUNT_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        params: null,
        mode: MODE.VIEW,
        user: action.payload,
      };
    case AccountActions.TYPES.ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        params: null,
        mode: MODE.UPDATE,
        user: action.payload,
      };
    case AccountActions.TYPES.ACCOUNT_LOGIN_RESET:
    case AccountActions.TYPES.ACCOUNT_GOOGLE_LOGIN_RESET:
    case AccountActions.TYPES.ACCOUNT_FACEBOOK_LOGIN_RESET:
    case AccountActions.TYPES.ACCOUNT_REGISER_RESET:
    case AccountActions.TYPES.ACCOUNT_FORGOT_PASSWORD_RESET:
    case AccountActions.TYPES.ACCOUNT_LOGOUT_RESET:
    case AccountActions.TYPES.ACCOUNT_RESET_PASSWORD_RESET:
      return {
        ...state,
        error: null,
        loading: false,
        params: null,
        mode: MODE.VIEW,
      };
    case AccountActions.TYPES.ACCOUNT_CONFIRM_EMAIL_START:
      return {
        ...state,
        emailConfirmation: {
          emailConfirmed: false,
          loading: false,
          error: null,
          params: null,
        },
      };
    case AccountActions.TYPES.ACCOUNT_CONFIRM_EMAIL_FAILURE:
      return {
        ...state,
        emailConfirmation: {
          emailConfirmed: 0,
          loading: false,
          error: action.payload,
          params: null,
        },
      };
    case AccountActions.TYPES.ACCOUNT_CONFIRM_EMAIL_RESET:
      return {
        ...state,
        emailConfirmation: {
          emailConfirmed: 0,
          loading: false,
          error: null,
          params: null,
        },
      };
    case AccountActions.TYPES.ACCOUNT_CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        emailConfirmation: {
          emailConfirmed: 1,
          loading: false,
          error: null,
          params: null,
        },
      };
    default:
      return state;
  }
};
