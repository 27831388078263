import { RolesAPI } from '../api';

export const TYPES = {
  ROLES_FETCH_START: 'ROLES_FETCH_START',
  ROLES_FETCH_SUCCESS: 'ROLES_FETCH_SUCCESS',
  ROLES_FETCH_FAILURE: 'ROLES_FETCH_FAILURE',
  ROLES_FETCH_RESET: 'ROLES_FETCH_RESET',

  ROLE_FETCH_START: 'ROLE_FETCH_START',
  ROLE_FETCH_SUCCESS: 'ROLE_FETCH_SUCCESS',
  ROLE_FETCH_FAILURE: 'ROLE_FETCH_FAILURE',
  ROLE_FETCH_RESET: 'ROLE_FETCH_RESET',

  ROLE_CREATE_START: 'ROLE_CREATE_START',
  ROLE_CREATE_SUCCESS: 'ROLE_CREATE_SUCCESS',
  ROLE_CREATE_FAILURE: 'ROLE_CREATE_FAILURE',
  ROLE_CREATE_RESET: 'ROLE_CREATE_RESET',

  ROLE_UPDATE_START: 'ROLE_UPDATE_START',
  ROLE_UPDATE_SUCCESS: 'ROLE_UPDATE_SUCCESS',
  ROLE_UPDATE_FAILURE: 'ROLE_UPDATE_FAILURE',
  ROLE_UPDATE_RESET: 'ROLE_UPDATE_RESET',

  ROLE_DELETE_START: 'ROLE_DELETE_START',
  ROLE_DELETE_SUCCESS: 'ROLE_DELETE_SUCCESS',
  ROLE_DELETE_FAILURE: 'ROLE_DELETE_FAILURE',
  ROLE_DELETE_RESET: 'ROLE_DELETE_RESET',
};

export const createRole = role => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ROLE_CREATE_START, payload: role });
      const data = await RolesAPI.CreateRole(role);
      dispatch({ type: TYPES.ROLE_CREATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ROLE_CREATE_FAILURE, payload: e });
    }
  };
};

export const updateRole = role => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ROLE_UPDATE_START, payload: role });
      const data = await RolesAPI.UpdateRole(role);
      dispatch({ type: TYPES.ROLE_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ROLE_UPDATE_FAILURE, payload: e });
    }
  };
};

export const deleteRole = role => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ROLE_DELETE_START, payload: role });
      const data = await RolesAPI.DeleteRole(role);
      dispatch({ type: TYPES.ROLE_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ROLE_DELETE_FAILURE, payload: e });
    }
  };
};

export const fetchRole = id => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ROLE_FETCH_START, payload: id });
      const data = await RolesAPI.FetchRole(id);
      dispatch({ type: TYPES.ROLE_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ROLE_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchRoles = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ROLES_FETCH_START, payload: params });
      const data = await RolesAPI.FetchRoles(params);
      dispatch({ type: TYPES.ROLES_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ROLES_FETCH_FAILURE, payload: e });
    }
  };
};

export const resetRolesList = () => {
  return {
    type: TYPES.ROLES_FETCH_RESET,
  };
};

export const resetActiveRole = () => {
  return {
    type: TYPES.ROLE_FETCH_RESET,
  };
};
