import React from 'react';
import { Route } from 'react-router-dom';

const AppRoute = route => {
  return (
    <Route
      path={route.path}
      render={props => {
        props.scope = route.props && route.props.scope ? route.props.scope : '';
        return (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} route={route} />
        );
      }}
    />
  );
};

export default AppRoute;
