import { PlatformsAPI } from '../api';

export const TYPES = {
  PLATFORMS_FETCH_START: 'PLATFORMS_FETCH_START',
  PLATFORMS_FETCH_SUCCESS: 'PLATFORMS_FETCH_SUCCESS',
  PLATFORMS_FETCH_FAILURE: 'PLATFORMS_FETCH_FAILURE',
  PLATFORMS_FETCH_RESET: 'PLATFORMS_FETCH_RESET',

  PLATFORM_FETCH_START: 'PLATFORM_FETCH_START',
  PLATFORM_FETCH_SUCCESS: 'PLATFORM_FETCH_SUCCESS',
  PLATFORM_FETCH_FAILURE: 'PLATFORM_FETCH_FAILURE',
  PLATFORM_FETCH_RESET: 'PLATFORM_FETCH_RESET',

  PLATFORM_CREATE_START: 'PLATFORM_CREATE_START',
  PLATFORM_CREATE_SUCCESS: 'PLATFORM_CREATE_SUCCESS',
  PLATFORM_CREATE_FAILURE: 'PLATFORM_CREATE_FAILURE',
  PLATFORM_CREATE_RESET: 'PLATFORM_CREATE_RESET',

  PLATFORM_UPDATE_START: 'PLATFORM_UPDATE_START',
  PLATFORM_UPDATE_SUCCESS: 'PLATFORM_UPDATE_SUCCESS',
  PLATFORM_UPDATE_FAILURE: 'PLATFORM_UPDATE_FAILURE',
  PLATFORM_UPDATE_RESET: 'PLATFORM_UPDATE_RESET',

  PLATFORM_DELETE_START: 'PLATFORM_DELETE_START',
  PLATFORM_DELETE_SUCCESS: 'PLATFORM_DELETE_SUCCESS',
  PLATFORM_DELETE_FAILURE: 'PLATFORM_DELETE_FAILURE',
  PLATFORM_DELETE_RESET: 'PLATFORM_DELETE_RESET',
};

export const createPlatform = platform => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PLATFORM_CREATE_START, payload: platform });
      const data = await PlatformsAPI.CreatePlatform(platform);
      dispatch({ type: TYPES.PLATFORM_CREATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PLATFORM_CREATE_FAILURE, payload: e });
    }
  };
};

export const updatePlatform = platform => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PLATFORM_UPDATE_START, payload: platform });
      const data = await PlatformsAPI.UpdatePlatform(platform);
      dispatch({ type: TYPES.PLATFORM_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PLATFORM_UPDATE_FAILURE, payload: e });
    }
  };
};

export const deletePlatform = platform => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PLATFORM_DELETE_START, payload: platform });
      const data = await PlatformsAPI.DeletePlatform(platform);
      dispatch({ type: TYPES.PLATFORM_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PLATFORM_DELETE_FAILURE, payload: e });
    }
  };
};

export const fetchPlatform = id => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PLATFORM_FETCH_START, payload: id });
      const data = await PlatformsAPI.FetchPlatform(id);
      dispatch({ type: TYPES.PLATFORM_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PLATFORM_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchPlatforms = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PLATFORMS_FETCH_START, payload: params });
      const data = await PlatformsAPI.FetchPlatforms(params);
      dispatch({ type: TYPES.PLATFORMS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PLATFORMS_FETCH_FAILURE, payload: e });
    }
  };
};

export const resetPlatformsList = () => {
  return {
    type: TYPES.PLATFORMS_FETCH_RESET,
  };
};

export const resetActivePlatform = () => {
  return {
    type: TYPES.PLATFORM_FETCH_RESET,
  };
};

export const setActivePlatform = platform => {
  return {
    type: TYPES.PLATFORM_FETCH_SUCCESS,
    payload: platform,
  };
};
