import BaseAPI from './base-api';

const NotificationsApi = {};

NotificationsApi.SearchTagAddresses = async (city, country) => {
  const response = await BaseAPI.get(`notification/tag-addresses/?city=${city}&country=${country}`);

  return response.data;
};

NotificationsApi.CreateNotificationRequest = async (city, country, message) => {
  const notificationRequest = {
    message: message,
    city: city,
    country: country,
  };

  const response = await BaseAPI.post(`/notification/notification-request/`, {
    ...notificationRequest,
  });
  return response.data;
};

NotificationsApi.FetchNotificationRequests = async () => {
  const response = await BaseAPI.get(`/notification/notification-request/`);

  return response.data;
};

NotificationsApi.FetchNotificationRequestsAdmin = async () => {
  const response = await BaseAPI.get(`/notification/`);

  return response.data;
};

NotificationsApi.ApproveRequest = async notificationRequestId => {
  const notificationRequest = {
    notificationRequestId: notificationRequestId,
  };

  const response = await BaseAPI.post(`/notification/`, {
    ...notificationRequest,
  });
  return response.data;
};

NotificationsApi.ForbidRequest = async (notificationRequestId, status, message) => {
  const notificationRequest = {
    notificationRequestId: notificationRequestId,
    notificationRequestStatus: status,
    notificationRequestStatusMessage: message,
  };

  const response = await BaseAPI.post(`/notification/cancel-request/`, {
    ...notificationRequest,
  });
  return response.data;
};

export default NotificationsApi;
