import BaseAPI from './base-api';

const UsersAPI = {};

UsersAPI.CreateUser = async user => {
  const response = await BaseAPI.post(`users/`, {
    ...user,
  });

  return response.data;
};

UsersAPI.UpdateUser = async user => {
  const response = await BaseAPI.put(`users/${user.id}/`, {
    ...user,
  });

  return response.data;
};

UsersAPI.DeleteUser = async user => {
  const response = await BaseAPI.delete(`users/${user.id}/`, {
    ...user,
  });

  return response.data;
};

UsersAPI.FetchUser = async id => {
  const response = await BaseAPI.get(`users/${id}/`);

  return response.data;
};

UsersAPI.FetchUsers = async params => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`users/?${query}`);

  return response.data;
};

UsersAPI.FetchUserRoles = async (id, params) => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`users/${id}/roles/?${query}`);

  return response.data;
};

UsersAPI.AddUserRole = async (userId, roleId) => {
  const response = await BaseAPI.post(`users/${userId}/roles/${roleId}`);
  return response.data;
};

UsersAPI.DeleteUserRole = async (userId, roleId) => {
  const response = await BaseAPI.delete(`users/${userId}/roles/${roleId}`);
  return response.data;
};

UsersAPI.FetchUserSessions = async (id, params) => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`users/${id}/sessions/?${query}`);

  return response.data;
};

UsersAPI.DeleteUserSession = async (userId, sessionId) => {
  const response = await BaseAPI.delete(`users/${userId}/sessions/${sessionId}`);

  return response.data;
};

UsersAPI.FetchTags = async (userId, params) => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`users/${userId}/tags?${query}`);

  return response.data;
};

UsersAPI.FetchTag = async id => {
  const response = await BaseAPI.get(`tags/${id}/`);
  return response.data;
};

UsersAPI.FetchCheckins = async id => {
  const response = await BaseAPI.get(`users/${id}/checkins`);
  return response.data;
};

export default UsersAPI;
