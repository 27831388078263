import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { i18nState } from 'redux-i18n';

import { account } from './account';
import { analytics } from './analytics';
import { categories } from './categories';
import { genders } from './genders';
import { lookups } from './lookups';
import { notifications } from './notifications';
import { partners } from './partners';
import { platforms } from './platforms';
import { payment } from './payment';
import { roles } from './roles';
import { tags } from './tags';
import { upload } from './upload';
import { users } from './users';

const appReducer = combineReducers({
  i18nState,
  form: formReducer,
  account,
  analytics,
  categories,
  genders,
  lookups,
  notifications,
  partners,
  platforms,
  payment,
  roles,
  tags,
  upload,
  users,
});

export const rootReducer = (state, action) => {
  return appReducer(state, action);
};
