import { TagActions } from '../actions';

import { MODE } from './mode';

const INITIAL_STATE = {
  tagsList: { data: [], params: {}, pager: null, error: null, loading: false },
  activeTag: { data: null, error: null, mode: null },
};

export const tags = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TagActions.TYPES.TAG_CREATE_RESET:
    case TagActions.TYPES.TAG_UPDATE_RESET:
    case TagActions.TYPES.TAG_DELETE_RESET:
    case TagActions.TYPES.TAG_FETCH_RESET:
      return {
        ...state,
        activeTag: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== TAG CREATE START ==================================//
    case TagActions.TYPES.TAG_CREATE_START:
      return {
        ...state,
        activeTag: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case TagActions.TYPES.TAG_CREATE_FAILURE:
      return {
        ...state,
        activeTag: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case TagActions.TYPES.TAG_CREATE_SUCCESS:
      return {
        ...state,
        tagsList: {
          ...state.tagsList,
          data: state.tagsList.data.concat(action.payload),
          error: null,
          loading: false,
        },
        activeTag: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  TAG CREATE END  ==================================//
    //================================== TAG UPDATE START ==================================//
    case TagActions.TYPES.TAG_UPDATE_START:
      return {
        ...state,
        activeTag: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case TagActions.TYPES.TAG_UPDATE_FAILURE:
      return {
        ...state,
        activeTag: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case TagActions.TYPES.TAG_UPDATE_SUCCESS:
      return {
        ...state,
        tagsList: {
          ...state.tagsList,
          data: state.tagsList.data.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeTag: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  TAG UPDATE END  ==================================//
    //================================== TAG DELETE START ==================================//
    case TagActions.TYPES.TAG_DELETE_START:
      return {
        ...state,
        activeTag: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case TagActions.TYPES.TAG_DELETE_FAILURE:
      return {
        ...state,
        activeTag: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case TagActions.TYPES.TAG_DELETE_SUCCESS:
      return {
        ...state,
        tagsList: {
          ...state.tagsList,
          data: state.tagsList.data.filter(tag => tag.id !== action.payload.id),
          error: null,
          loading: false,
        },
        activeTag: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  TAG DELETE END  ==================================//
    //================================== TAG FETCH START ==================================//
    case TagActions.TYPES.TAG_FETCH_START:
      return {
        ...state,
        activeTag: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case TagActions.TYPES.TAG_FETCH_FAILURE:
      return {
        ...state,
        activeTag: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case TagActions.TYPES.TAG_FETCH_SUCCESS:
      return {
        ...state,
        activeTag: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  TAG FETCH END  ==================================//
    //================================== TAGS FETCH START ==================================//
    case TagActions.TYPES.TAGS_FETCH_START:
      return {
        ...state,
        tagsList: {
          data: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case TagActions.TYPES.TAGS_FETCH_SUCCESS:
      return {
        ...state,
        tagsList: {
          ...state.tagsList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case TagActions.TYPES.TAGS_FETCH_FAILURE:
      return {
        ...state,
        tagsList: {
          ...state.tagsList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case TagActions.TYPES.TAGS_FETCH_RESET:
      return {
        ...state,
        tagsList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  TAGS FETCH END  ==================================//
    default:
      return state;
  }
};
