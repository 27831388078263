import { AccountAPI, AuthAPI } from '../api';

// Auth Action types
export const TYPES = {
  ACCOUNT_REFRESH_TOKEN_START: 'ACCOUNT_REFRESH_TOKEN_START',
  ACCOUNT_REFRESH_TOKEN_SUCCESS: 'ACCOUNT_REFRESH_TOKEN_SUCCESS',
  ACCOUNT_REFRESH_TOKEN_FAILURE: 'ACCOUNT_REFRESH_TOKEN_FAILURE',

  ACCOUNT_LOGIN_START: 'ACCOUNT_LOGIN_START',
  ACCOUNT_LOGIN_SUCCESS: 'ACCOUNT_LOGIN_SUCCESS',
  ACCOUNT_LOGIN_FAILURE: 'ACCOUNT_LOGIN_FAILURE',
  ACCOUNT_LOGIN_RESET: 'ACCOUNT_LOGIN_RESET',

  ACCOUNT_GOOGLE_LOGIN_START: 'ACCOUNT_GOOGLE_LOGIN_START',
  ACCOUNT_GOOGLE_LOGIN_SUCCESS: 'ACCOUNT_GOOGLE_LOGIN_SUCCESS',
  ACCOUNT_GOOGLE_LOGIN_FAILURE: 'ACCOUNT_GOOGLE_LOGIN_FAILURE',
  ACCOUNT_GOOGLE_LOGIN_RESET: 'ACCOUNT_GOOGLE_LOGIN_RESET',

  ACCOUNT_FACEBOOK_LOGIN_START: 'ACCOUNT_FACEBOOK_LOGIN_START',
  ACCOUNT_FACEBOOK_LOGIN_SUCCESS: 'ACCOUNT_FACEBOOK_LOGIN_SUCCESS',
  ACCOUNT_FACEBOOK_LOGIN_FAILURE: 'ACCOUNT_FACEBOOK_LOGIN_FAILURE',
  ACCOUNT_FACEBOOK_LOGIN_RESET: 'ACCOUNT_FACEBOOK_LOGIN_RESET',

  ACCOUNT_REGISTER_START: 'ACCOUNT_REGISTER_START',
  ACCOUNT_REGISTER_SUCCESS: 'ACCOUNT_REGISTER_SUCCESS',
  ACCOUNT_REGISTER_FAILURE: 'ACCOUNT_REGISTER_FAILURE',
  ACCOUNT_REGISER_RESET: 'ACCOUNT_REGISER_RESET',

  ACCOUNT_FORGOT_PASSWORD_START: 'ACCOUNT_FORGOT_PASSWORD_START',
  ACCOUNT_FORGOT_PASSWORD_SUCCESS: 'ACCOUNT_FORGOT_PASSWORD_SUCCESS',
  ACCOUNT_FORGOT_PASSWORD_FAILURE: 'ACCOUNT_FORGOT_PASSWORD_FAILURE',
  ACCOUNT_FORGOT_PASSWORD_RESET: 'ACCOUNT_FORGOT_PASSWORD_RESET',

  ACCOUNT_RESET_PASSWORD_START: 'ACCOUNT_RESET_PASSWORD_START',
  ACCOUNT_RESET_PASSWORD_SUCCESS: 'ACCOUNT_RESET_PASSWORD_SUCCESS',
  ACCOUNT_RESET_PASSWORD_FAILURE: 'ACCOUNT_RESET_PASSWORD_FAILURE',
  ACCOUNT_RESET_PASSWORD_RESET: 'ACCOUNT_RESET_PASSWORD_RESET',

  ACCOUNT_ACCEPT_INVITE_START: 'ACCOUNT_ACCEPT_INVITE_START',
  ACCOUNT_ACCEPT_INVITE_SUCCESS: 'ACCOUNT_ACCEPT_INVITE_SUCCESS',
  ACCOUNT_ACCEPT_INVITE_FAILURE: 'ACCOUNT_ACCEPT_INVITE_FAILURE',
  ACCOUNT_ACCEPT_INVITE_RESET: 'ACCOUNT_ACCEPT_INVITE_RESET',

  ACCOUNT_LOGOUT_START: 'ACCOUNT_LOGOUT_START',
  ACCOUNT_LOGOUT_SUCCESS: 'ACCOUNT_LOGOUT_SUCCESS',
  ACCOUNT_LOGOUT_FAILURE: 'ACCOUNT_LOGOUT_FAILURE',
  ACCOUNT_LOGOUT_RESET: 'ACCOUNT_LOGOUT_RESET',

  ACCOUNT_FETCH_START: 'ACCOUNT_FETCH_START',
  ACCOUNT_FETCH_SUCCESS: 'ACCOUNT_FETCH_SUCCESS',
  ACCOUNT_FETCH_FAILURE: 'ACCOUNT_FETCH_FAILURE',
  ACCOUNT_FETCH_RESET: 'ACCOUNT_FETCH_RESET',

  ACCOUNT_UPDATE_START: 'ACCOUNT_UPDATE_START',
  ACCOUNT_UPDATE_SUCCESS: 'ACCOUNT_UPDATE_SUCCESS',
  ACCOUNT_UPDATE_FAILURE: 'ACCOUNT_UPDATE_FAILURE',
  ACCOUNT_UPDATE_RESET: 'ACCOUNT_UPDATE_RESET',

  ACCOUNT_CONFIRM_EMAIL_START: 'ACCOUNT_CONFIRM_EMAIL_START',
  ACCOUNT_CONFIRM_EMAIL_SUCCESS: 'ACCOUNT_CONFIRM_EMAIL_SUCCESS',
  ACCOUNT_CONFIRM_EMAIL_FAILURE: 'ACCOUNT_CONFIRM_EMAIL_FAILURE',
  ACCOUNT_CONFIRM_EMAIL_RESET: 'ACCOUNT_CONFIRM_EMAIL_RESET',
};

export const refreshToken = token => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ACCOUNT_REFRESH_TOKEN_START, payload: token });
      const data = await AccountAPI.AccountRefreshToken(token);
      dispatch({ type: TYPES.ACCOUNT_REFRESH_TOKEN_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ACCOUNT_REFRESH_TOKEN_FAILURE, payload: e });
    }
  };
};

export const login = (email, password) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ACCOUNT_LOGIN_START, payload: { email, password } });
      const data = await AccountAPI.AccountLogin(email, password);
      dispatch({ type: TYPES.ACCOUNT_LOGIN_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ACCOUNT_LOGIN_FAILURE, payload: e });
    }
  };
};

export const googleLogin = token => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ACCOUNT_GOOGLE_LOGIN_START, payload: { token } });
      const data = await AuthAPI.GoogleLogin(token);
      dispatch({ type: TYPES.ACCOUNT_GOOGLE_LOGIN_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ACCOUNT_GOOGLE_LOGIN_FAILURE, payload: e });
    }
  };
};

export const facebookLogin = token => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ACCOUNT_FACEBOOK_LOGIN_START, payload: { token } });
      const data = await AuthAPI.FacebookLogin(token);
      dispatch({ type: TYPES.ACCOUNT_FACEBOOK_LOGIN_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ACCOUNT_FACEBOOK_LOGIN_FAILURE, payload: e });
    }
  };
};

export const logout = token => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ACCOUNT_LOGOUT_START });
      const data = await AccountAPI.Logout(token);
      dispatch({ type: TYPES.ACCOUNT_LOGOUT_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ACCOUNT_LOGOUT_FAILURE, payload: e });
    }
  };
};

export const register = (email, password) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ACCOUNT_REGISTER_START, payload: { email, password } });
      const data = await AccountAPI.AccountRegister(email, password);
      dispatch({ type: TYPES.ACCOUNT_REGISTER_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ACCOUNT_REGISTER_FAILURE, payload: e });
    }
  };
};

export const confirmEmail = token => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ACCOUNT_CONFIRM_EMAIL_START, payload: token });
      const data = await AccountAPI.ConfirmEmail(token);
      dispatch({ type: TYPES.ACCOUNT_CONFIRM_EMAIL_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ACCOUNT_CONFIRM_EMAIL_FAILURE, payload: e });
    }
  };
};

export const forgotPassword = email => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ACCOUNT_FORGOT_PASSWORD_START, payload: email });
      const data = await AccountAPI.AccountForgotPassword(email);
      dispatch({ type: TYPES.ACCOUNT_FORGOT_PASSWORD_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ACCOUNT_FORGOT_PASSWORD_FAILURE, payload: e });
    }
  };
};

export const resetPassword = (token, password, password_confirm) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.ACCOUNT_RESET_PASSWORD_START,
        payload: { token, password, password_confirm },
      });
      const data = await AccountAPI.AccountResetPassword(token, password, password_confirm);
      dispatch({ type: TYPES.ACCOUNT_FORGOT_PASSWORD_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ACCOUNT_FORGOT_PASSWORD_FAILURE, payload: e });
    }
  };
};

export const changePassword = (password, new_password, confirm_password) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.ACCOUNT_RESET_PASSWORD_START,
        payload: { password, new_password, confirm_password },
      });
      const data = await AccountAPI.ChangePassword(password, new_password, confirm_password);
      dispatch({ type: TYPES.ACCOUNT_FORGOT_PASSWORD_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ACCOUNT_FORGOT_PASSWORD_FAILURE, payload: e });
    }
  };
};

export const acceptInvite = (token, password, password_confirm) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.ACCOUNT_ACCEPT_INVITE_START,
        payload: { token, password, password_confirm },
      });
      const data = await AccountAPI.AcceptInvite(token, password, password_confirm);
      dispatch({ type: TYPES.ACCOUNT_ACCEPT_INVITE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ACCOUNT_ACCEPT_INVITE_FAILURE, payload: e });
    }
  };
};

export const reset = () => {
  return {
    type: TYPES.ACCOUNT_LOGIN_RESET,
  };
};

export const fetchProfile = () => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.ACCOUNT_FETCH_START,
      });
      const data = await AccountAPI.FetchProfile();
      dispatch({ type: TYPES.ACCOUNT_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ACCOUNT_FETCH_FAILURE, payload: e });
    }
  };
};

export const updateProfile = profile => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.ACCOUNT_UPDATE_START,
        payload: profile,
      });
      const data = await AccountAPI.UpdateProfile(profile);
      dispatch({ type: TYPES.ACCOUNT_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ACCOUNT_UPDATE_FAILURE, payload: e });
    }
  };
};
