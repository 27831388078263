import BaseAPI from './base-api';

const AccountAPI = {};

AccountAPI.AccountRefreshToken = async token => {
  const response = await BaseAPI.post(`account/refresh-token`, {
    refresh_token: token,
  });

  return response.data;
};

AccountAPI.AccountLogin = async (email, password) => {
  const response = await BaseAPI.post(`account/login`, {
    email,
    password,
  });

  return response.data;
};

AccountAPI.AccountRegister = async (email, password) => {
  const response = await BaseAPI.post(`account/register`, {
    email,
    password,
  });

  return response.data;
};

AccountAPI.ConfirmEmail = async token => {
  const response = await BaseAPI.post('account/confirm-email', {
    token,
  });

  return response.data;
};

AccountAPI.AccountForgotPassword = async email => {
  const response = await BaseAPI.post('account/forgot-password', {
    email,
  });

  return response.data;
};

AccountAPI.AccountResetPassword = async (token, password, password_confirm) => {
  const response = await BaseAPI.post('account/reset-password', {
    token,
    password,
    password_confirm,
  });
  return response.data;
};

AccountAPI.ChangePassword = async (password, new_password, confirm_password) => {
  const response = await BaseAPI.post('account/change-password', {
    password,
    new_password,
    confirm_password,
  });
  return response.data;
};

AccountAPI.FetchProfile = async () => {
  const response = await BaseAPI.get('account/');
  return response.data;
};

AccountAPI.UpdateProfile = async profile => {
  const response = await BaseAPI.put('account/', {
    ...profile,
  });
  return response.data;
};

AccountAPI.InviteUser = async user => {
  const response = await BaseAPI.post('account/invite', {
    ...user,
  });
  return response.data;
};

AccountAPI.AcceptInvite = async (token, password, password_confirm) => {
  const response = await BaseAPI.post('account/accept-invite', {
    token,
    password,
    password_confirm,
  });
  return response.data;
};

AccountAPI.Logout = async refresh_token => {
  const response = await BaseAPI.post('account/logout', {
    refresh_token: refresh_token,
  });
  return response.data;
};

export default AccountAPI;
