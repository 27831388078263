import BaseAPI from './base-api';

const GendersAPI = {};

GendersAPI.CreateGender = async gender => {
  const response = await BaseAPI.post(`genders/`, {
    ...gender,
  });

  return response.data;
};

GendersAPI.UpdateGender = async gender => {
  const response = await BaseAPI.put(`genders/${gender.id}/`, {
    ...gender,
  });

  return response.data;
};

GendersAPI.DeleteGender = async gender => {
  const response = await BaseAPI.delete(`genders/${gender.id}/`, {
    ...gender,
  });

  return response.data;
};

GendersAPI.FetchGender = async id => {
  const response = await BaseAPI.get(`genders/${id}/`);

  return response.data;
};

GendersAPI.FetchGenders = async params => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`genders/?${query}`);

  return response.data;
};

export default GendersAPI;
