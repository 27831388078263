import BaseAPI from './base-api';

const AnalyticsApi = {};

AnalyticsApi.FetchCheckinAnalytics = async partnerId => {
  const response = await BaseAPI.get(`analytics/${partnerId}/checkins`);

  return response.data;
};

AnalyticsApi.SoldTagsWithFee = async partnerId => {
  const response = await BaseAPI.get(`analytics/${partnerId}/sold-tags-fee`);

  return response.data;
};

AnalyticsApi.SoldTags = async partnerId => {
  const response = await BaseAPI.get(`analytics/${partnerId}/sold-tags`);

  return response.data;
};

AnalyticsApi.LastEvent = async partnerId => {
  const response = await BaseAPI.get(`analytics/${partnerId}/last-event`);

  return response.data;
};

AnalyticsApi.GenreTopSeller = async partnerId => {
  const response = await BaseAPI.get(`analytics/${partnerId}/genre-top-seller`);

  return response.data;
};

AnalyticsApi.FetchTagAddressesAnalytics = async partnerId => {
  const response = await BaseAPI.get(`analytics/${partnerId}/tag-addresses`);

  return response.data;
};

AnalyticsApi.FetchPartnerEvents = async (partnerId, filter) => {
  const response = await BaseAPI.get(`me/partners/${partnerId}/events?filter=${filter}`);

  return response.data;
};

AnalyticsApi.FetchPartnerRewards = async (partnerId, filter) => {
  const response = await BaseAPI.get(`me/partners/${partnerId}/rewards?filter=${filter}`);

  return response.data;
};

export default AnalyticsApi;
