import BaseAPI from './base-api';

const RolesAPI = {};

RolesAPI.CreateRole = async role => {
  const response = await BaseAPI.post(`roles`, {
    role,
  });

  return response.data;
};

RolesAPI.UpdateRole = async role => {
  const response = await BaseAPI.put(`roles/${role.id}/`, {
    role,
  });

  return response.data;
};

RolesAPI.DeleteRole = async role => {
  const response = await BaseAPI.delete(`roles/${role.id}/`, {
    role,
  });

  return response.data;
};

RolesAPI.FetchRole = async id => {
  const response = await BaseAPI.get(`roles/${id}/`);

  return response.data;
};

RolesAPI.FetchRoles = async params => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`roles/?${query}`);

  return response.data;
};

export default RolesAPI;
