import { UsersAPI, AccountAPI } from '../api';

export const TYPES = {
  USERS_FETCH_START: 'USERS_FETCH_START',
  USERS_FETCH_SUCCESS: 'USERS_FETCH_SUCCESS',
  USERS_FETCH_FAILURE: 'USERS_FETCH_FAILURE',
  USERS_FETCH_RESET: 'USERS_FETCH_RESET',

  USER_FETCH_START: 'USER_FETCH_START',
  USER_FETCH_SUCCESS: 'USER_FETCH_SUCCESS',
  USER_FETCH_FAILURE: 'USER_FETCH_FAILURE',
  USER_FETCH_RESET: 'USER_FETCH_RESET',

  USER_CREATE_START: 'USER_CREATE_START',
  USER_CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
  USER_CREATE_FAILURE: 'USER_CREATE_FAILURE',
  USER_CREATE_RESET: 'USER_CREATE_RESET',

  USER_UPDATE_START: 'USER_UPDATE_START',
  USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
  USER_UPDATE_FAILURE: 'USER_UPDATE_FAILURE',
  USER_UPDATE_RESET: 'USER_UPDATE_RESET',

  USER_DELETE_START: 'USER_DELETE_START',
  USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  USER_DELETE_FAILURE: 'USER_DELETE_FAILURE',
  USER_DELETE_RESET: 'USER_DELETE_RESET',

  USER_INVITE_START: 'USER_INVITE_START',
  USER_INVITE_SUCCESS: 'USER_INVITE_SUCCESS',
  USER_INVITE_FAILURE: 'USER_INVITE_FAILURE',
  USER_INVITE_RESET: 'USER_INVITE_RESET',

  USER_ROLES_FETCH_START: 'USER_ROLES_FETCH_START',
  USER_ROLES_FETCH_SUCCESS: 'USER_ROLES_FETCH_SUCCESS',
  USER_ROLES_FETCH_FAILURE: 'USER_ROLES_FETCH_FAILURE',
  USER_ROLES_FETCH_RESET: 'USER_ROLES_FETCH_RESET',

  USER_ROLES_ASSIGN_START: 'USER_ROLES_ASSIGN_START',
  USER_ROLES_ASSIGN_SUCCESS: 'USER_ROLES_ASSIGN_SUCCESS',
  USER_ROLES_ASSIGN_FAILURE: 'USER_ROLES_ASSIGN_FAILURE',

  USER_SESSIONS_FETCH_START: 'USER_SESSIONS_FETCH_START',
  USER_SESSIONS_FETCH_SUCCESS: 'USER_SESSIONS_FETCH_SUCCESS',
  USER_SESSIONS_FETCH_FAILURE: 'USER_SESSIONS_FETCH_FAILURE',
  USER_SESSIONS_FETCH_RESET: 'USER_SESSIONS_FETCH_RESET',

  USER_SESSIONS_DELETE_START: 'USER_SESSIONS_DELETE_START',
  USER_SESSIONS_DELETE_SUCCESS: 'USER_SESSIONS_DELETE_SUCCESS',
  USER_SESSIONS_DELETE_FAILURE: 'USER_SESSIONS_DELETE_FAILURE',

  USER_TAGS_FETCH_START: 'USER_TAGS_FETCH_START',
  USER_TAGS_FETCH_SUCCESS: 'USER_TAGS_FETCH_SUCCESS',
  USER_TAGS_FETCH_FAILURE: 'USER_TAGS_FETCH_FAILURE',
  USER_TAGS_FETCH_RESET: 'USER_TAGS_FETCH_RESET',

  USER_TAG_FETCH_START: 'USER_TAG_FETCH_START',
  USER_TAG_FETCH_SUCCESS: 'USER_TAG_FETCH_SUCCESS',
  USER_TAG_FETCH_FAILURE: 'USER_TAG_FETCH_FAILURE',
  USER_TAG_FETCH_RESET: 'USER_TAG_FETCH_RESET',

  USER_CHECKINS_FETCH_START: 'USER_CHECKINS_FETCH_START',
  USER_CHECKINS_FETCH_SUCCESS: 'USER_CHECKINS_FETCH_SUCCESS',
  USER_CHECKINS_FETCH_FAILURE: 'USER_CHECKINS_FETCH_FAILURE',
  USER_CHECKINS_FETCH_RESET: 'USER_CHECKINS_FETCH_RESET',
};

export const createUser = user => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.USER_CREATE_START, payload: user });
      const data = await UsersAPI.CreateUser(user);
      dispatch({ type: TYPES.USER_CREATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.USER_CREATE_FAILURE, payload: e });
    }
  };
};

export const inviteUser = user => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.USER_INVITE_START, payload: user });
      const data = await AccountAPI.InviteUser(user);
      dispatch({ type: TYPES.USER_INVITE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.USER_INVITE_FAILURE, payload: e });
    }
  };
};

export const updateUser = user => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.USER_UPDATE_START, payload: user });
      const data = await UsersAPI.UpdateUser(user);
      dispatch({ type: TYPES.USER_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.USER_UPDATE_FAILURE, payload: e });
    }
  };
};

export const deleteUser = user => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.USER_DELETE_START, payload: user });
      const data = await UsersAPI.DeleteUser(user);
      dispatch({ type: TYPES.USER_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.USER_DELETE_FAILURE, payload: e });
    }
  };
};

export const fetchUser = id => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.USER_FETCH_START, payload: id });
      const data = await UsersAPI.FetchUser(id);
      dispatch({ type: TYPES.USER_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.USER_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchUsers = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.USERS_FETCH_START, payload: params });
      const data = await UsersAPI.FetchUsers(params);
      dispatch({ type: TYPES.USERS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.USERS_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchUserRoles = (id, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.USER_ROLES_FETCH_START, payload: params });
      const data = await UsersAPI.FetchUserRoles(id, params);
      dispatch({ type: TYPES.USER_ROLES_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.USER_ROLES_FETCH_FAILURE, payload: e });
    }
  };
};

export const addUserRole = (userId, roleId) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.USER_ROLES_ASSIGN_START, payload: { userId, roleId } });
      const data = await UsersAPI.AddUserRole(userId, roleId);
      dispatch({ type: TYPES.USER_ROLES_ASSIGN_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.USER_ROLES_ASSIGN_FAILURE, payload: e });
    }
  };
};

export const deleteUserRole = (userId, roleId) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.USER_ROLES_ASSIGN_START, payload: { userId, roleId } });
      const data = await UsersAPI.DeleteUserRole(userId, roleId);
      dispatch({ type: TYPES.USER_ROLES_ASSIGN_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.USER_ROLES_ASSIGN_FAILURE, payload: e });
    }
  };
};

export const fetchUserSessions = (userId, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.USER_SESSIONS_FETCH_START, payload: params });
      const data = await UsersAPI.FetchUserSessions(userId, params);
      dispatch({ type: TYPES.USER_SESSIONS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.USER_SESSIONS_FETCH_FAILURE, payload: e });
    }
  };
};

export const deleteUserSession = (userId, roleId) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.USER_SESSIONS_DELETE_START, payload: { userId, roleId } });
      const data = await UsersAPI.DeleteUserSession(userId, roleId);
      dispatch({ type: TYPES.USER_SESSIONS_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.USER_SESSIONS_DELETE_FAILURE, payload: e });
    }
  };
};

export const resetUsersList = () => {
  return {
    type: TYPES.USERS_FETCH_RESET,
  };
};

export const resetActiveUser = () => {
  return {
    type: TYPES.USER_FETCH_RESET,
  };
};

export const setActiveUser = gender => {
  return {
    type: TYPES.USER_FETCH_SUCCESS,
    payload: gender,
  };
};

export const setActiveTag = tag => {
  return {
    type: TYPES.USER_TAG_FETCH_SUCCESS,
    payload: tag,
  };
};

export const resetActiveTag = () => {
  return {
    type: TYPES.USER_TAG_FETCH_RESET,
  };
};

export const fetchTags = (userId, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.USER_TAGS_FETCH_START, payload: params });
      const data = await UsersAPI.FetchTags(userId, params);
      dispatch({ type: TYPES.USER_TAGS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.USER_TAGS_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchTag = id => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.USER_TAG_FETCH_START, payload: id });
      const data = await UsersAPI.FetchTag(id);
      dispatch({ type: TYPES._TAG_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.USER_TAG_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchCheckins = userId => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.USER_CHECKINS_FETCH_START, payload: userId });
      const data = await UsersAPI.FetchCheckins(userId);
      dispatch({ type: TYPES.USER_CHECKINS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.USER_CHECKINS_FETCH_FAILURE, payload: e });
    }
  };
};
