import BaseAPI from './base-api';

const AuthAPI = {};

AuthAPI.GoogleLogin = async token => {
  const response = await BaseAPI.post(`auth/google`, {
    ...token,
  });

  return response.data;
};

AuthAPI.FacebookLogin = async token => {
  const response = await BaseAPI.post(`auth/facebook`, {
    ...token,
  });

  return response.data;
};

export default AuthAPI;
