import BaseAPI from './base-api';

const UploadAPI = {};

UploadAPI.GenerateUploadURL = async params => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`upload/?${query}`);
  return response.data;
};

UploadAPI.UploadFile = async (file, url) => {
  const response = await BaseAPI.put(url, file, {
    headers: {
      'Content-Type': file.type,
      'x-goog-acl': 'public-read',
    },
  });

  return response.data;
};

export default UploadAPI;
