import { asyncComponent } from './lib/async-component';

const routes = [
  {
    path: '/',
    exact: true,
    component: asyncComponent(() => import('./pages/layouts/landingPublic')),
    page: asyncComponent(() => import('./pages/home/Home.jsx')),
    meta: {
      title: 'Home',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/tag/:tag_id?',
    exact: true,
    component: asyncComponent(() => import('./pages/layouts/landingPublic')),
    page: asyncComponent(() => import('./pages/home/HomeTag.jsx')),
    meta: {
      title: 'HomeTag',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/support',
    exact: true,
    component: asyncComponent(() => import('./pages/layouts/landingPublic')),
    page: asyncComponent(() => import('./pages/support/Support.jsx')),
    meta: {
      title: 'Support',
      description: 'Support page for TheTag users',
      keywords: '',
    },
  },
  {
    path: '/privacy-policy',
    component: asyncComponent(() => import('./pages/layouts/public')),
    page: asyncComponent(() => import('./pages/legal/privacy-policy')),
    meta: {
      title: 'Privacy Policy',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/terms-of-services',
    component: asyncComponent(() => import('./pages/layouts/public')),
    page: asyncComponent(() => import('./pages/legal/terms-of-services')),
    meta: {
      title: 'Terms Of Services',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/login',
    component: asyncComponent(() => import('./pages/layouts/public')),
    page: asyncComponent(() => import('./pages/login')),
    meta: {
      title: 'Login',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/forgot-password',
    component: asyncComponent(() => import('./pages/layouts/public')),
    page: asyncComponent(() => import('./pages/forgot-password')),
    meta: {
      title: 'Forgot Password',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/reset-password/:token',
    component: asyncComponent(() => import('./pages/layouts/public')),
    page: asyncComponent(() => import('./pages/reset-password')),
    meta: {
      title: 'Reset Password',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/accept-invitation/:token',
    component: asyncComponent(() => import('./pages/layouts/public')),
    page: asyncComponent(() => import('./pages/accept-invitation')),
    meta: {
      title: 'Accept Invitation',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/confirm-email/:token',
    component: asyncComponent(() => import('./pages/layouts/public')),
    page: asyncComponent(() => import('./pages/confirm-email')),
    meta: {
      title: 'Confirm Email',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/info',
    component: asyncComponent(() => import('./pages/layouts/payment')),
    page: asyncComponent(() => import('./pages/info-page')),
    meta: {
      title: 'The Tag Info page',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/landing-page/:partner_id/:referral_code?',
    component: asyncComponent(() => import('./pages/layouts/public')),
    page: asyncComponent(() => import('./pages/landing-page/index')),
    meta: {
      title: 'The Tag payment',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/referral/:referral_code',
    component: asyncComponent(() => import('./pages/layouts/public')),
    page: asyncComponent(() => import('./pages/referral_page')),
    meta: {
      title: 'The Tag payment',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/tag-payment/:partner_id/series/:tag_series_id',
    component: asyncComponent(() => import('./pages/layouts/payment')),
    page: asyncComponent(() => import('./pages/tag-payment')),
    meta: {
      title: 'The Tag payment',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/tag-payment-success/:session_id',
    component: asyncComponent(() => import('./pages/layouts/public')),
    page: asyncComponent(() => import('./pages/tag-payment-success')),
    meta: {
      title: 'Payment success',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/tag-payment-cancel',
    component: asyncComponent(() => import('./pages/layouts/public')),
    page: asyncComponent(() => import('./pages/tag-payment-cancel')),
    meta: {
      title: 'Payment cancelled',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/forbidden',
    component: asyncComponent(() => import('./pages/layouts/public')),
    page: asyncComponent(() => import('./pages/forbidden')),
    meta: {
      title: 'Forbidden',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/confirm-delete-account/:id',
    page: asyncComponent(() => import('./pages/confirm-delete-token')),
    component: asyncComponent(() => import('./pages/layouts/public')),
    meta: {
      title: 'Confirm Delete Token',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/dashboard',
    exact: true,
    name: 'APP.ROUTE.DASHBOARD',
    sidebar: true,
    icon: 'icon-home',
    permissions: ['Admin', 'Partner'],
    component: asyncComponent(() => import('./pages/layouts/dashboard')),
    page: asyncComponent(() => import('./pages/dashboard/dashboard')),
    meta: {
      title: 'Dashboard',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/partners',
    exact: true,
    name: 'APP.ROUTE.PARTNER-SETTINGS',
    sidebar: true,
    icon: 'icon-book-open',
    permissions: ['Partner'],
    component: asyncComponent(() => import('./pages/layouts/dashboard')),
    page: asyncComponent(() => import('./pages/partners')),
    meta: {
      title: 'Settings',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/partners',
    exact: true,
    name: 'APP.ROUTE.PARTNERS',
    sidebar: true,
    icon: 'icon-book-open',
    permissions: ['Admin'],
    component: asyncComponent(() => import('./pages/layouts/dashboard')),
    page: asyncComponent(() => import('./pages/partners')),
    meta: {
      title: 'Partners',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/partners/:id',
    name: '"APP.ROUTE.PARTNERS"',
    permissions: ['Admin', 'Partner'],
    component: asyncComponent(() => import('./pages/layouts/dashboard')),
    page: asyncComponent(() => import('./pages/partner-details')),
    meta: {
      title: 'Partner Details',
      description: '',
      keywords: '',
    },
    routes: [
      // {
      //   path: '/partners/:id/dashboard',
      //   name: 'APP.ROUTE.PARTNERS.DASHBOARD',
      //   component: asyncComponent(() => import('./pages/partners/dashboard')),
      // },
      {
        path: '/partners/:id/profile',
        name: 'APP.ROUTE.PARTNERS.PROFILE',
        component: asyncComponent(() => import('./pages/partners/profile')),
      },
      {
        path: '/partners/:id/events',
        name: 'APP.ROUTE.PARTNERS.EVENTS',
        component: asyncComponent(() => import('./pages/partners/events')),
      },
      {
        path: '/partners/:id/checkins',
        name: 'APP.ROUTE.PARTNERS.CHECKINS',
        component: asyncComponent(() => import('./pages/partners/checkins')),
      },
      {
        path: '/partners/:id/boosters',
        name: 'APP.ROUTE.PARTNERS.BOOSTERS',
        component: asyncComponent(() => import('./pages/partners/boosters')),
      },
      {
        path: '/partners/:id/boosters-templates',
        name: 'APP.ROUTE.PARTNERS.BOOSTERS-TEMPLATES',
        component: asyncComponent(() => import('./pages/partners/boosters-templates')),
      },
      {
        path: '/partners/:id/rewards',
        name: 'APP.ROUTE.PARTNERS.REWARDS',
        component: asyncComponent(() => import('./pages/partners/rewards')),
      },
      {
        path: '/partners/:id/tag-series',
        name: 'APP.ROUTE.PARTNERS.TAG-SERIES',
        component: asyncComponent(() => import('./pages/partners/tag-series')),
      },
      {
        path: '/partners/:id/tags',
        name: 'APP.ROUTE.PARTNERS.TAGS',
        component: asyncComponent(() => import('./pages/partners/tags')),
      },
      {
        permissions: ['Admin'],
        path: '/partners/:id/team',
        name: 'APP.ROUTE.PARTNERS.TEAM',
        component: asyncComponent(() => import('./pages/partners/team')),
      },
      {
        path: '/partners/:id/leaderboard',
        name: 'APP.ROUTE.PARTNERS.LEADERBOARD',
        component: asyncComponent(() => import('./pages/partners/leaderboard')),
      },
      {
        path: '/partners/:id/stores',
        name: 'APP.ROUTE.PARTNERS.STORE',
        component: asyncComponent(() => import('./pages/partners/partner-stores')),
      },
      {
        path: '/partners/:id/sponsors',
        name: 'APP.ROUTE.PARTNERS.SPONSORS',
        component: asyncComponent(() => import('./pages/partners/partner-sponsors')),
      },
    ],
  },
  {
    path: '/users',
    exact: true,
    name: 'APP.ROUTE.USERS',
    sidebar: true,
    icon: 'icon-user',
    permissions: ['Admin'],
    component: asyncComponent(() => import('./pages/layouts/dashboard')),
    page: asyncComponent(() => import('./pages/users')),
    meta: {
      title: 'Users',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/users/:id',
    name: 'APP.ROUTE.USERS',
    permissions: ['Admin'],
    component: asyncComponent(() => import('./pages/layouts/dashboard')),
    page: asyncComponent(() => import('./pages/user-details')),
    meta: {
      title: 'User Details',
      description: '',
      keywords: '',
    },
    routes: [
      {
        path: '/users/:id/profile',
        name: 'APP.ROUTE.USERS.PROFILE',
        component: asyncComponent(() => import('./pages/users/profile')),
      },
      {
        path: '/users/:id/tags',
        name: 'APP.ROUTE.USERS.TAGS',
        component: asyncComponent(() => import('./pages/users/tags')),
      },
      {
        path: '/users/:id/checkins',
        name: 'APP.ROUTE.USERS.CHECKINS',
        component: asyncComponent(() => import('./pages/users/checkins')),
      },
      {
        path: '/users/:id/sessions',
        name: 'APP.ROUTE.USERS.SESSIONS',
        component: asyncComponent(() => import('./pages/users/sessions')),
      },
      {
        path: '/users/:id/roles',
        name: 'APP.ROUTE.USERS.ROLES',
        component: asyncComponent(() => import('./pages/users/roles')),
      },
    ],
  },
  {
    path: '/categories',
    exact: true,
    name: 'APP.ROUTE.CATEGORIES',
    sidebar: true,
    icon: 'icon-layers',
    permissions: ['Admin'],
    component: asyncComponent(() => import('./pages/layouts/dashboard')),
    page: asyncComponent(() => import('./pages/categories')),
    meta: {
      title: 'Categories',
      description: '',
      keywords: '',
    },
  },

  {
    path: '/genders',
    exact: true,
    name: 'APP.ROUTE.GENDERS',
    sidebar: true,
    icon: 'icon-target',
    permissions: ['Admin'],
    component: asyncComponent(() => import('./pages/layouts/dashboard')),
    page: asyncComponent(() => import('./pages/genders')),
    meta: {
      title: 'Genders',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/platforms',
    exact: true,
    name: 'APP.ROUTE.PLATFORMS',
    sidebar: true,
    icon: 'icon-shield',
    permissions: ['Admin'],
    component: asyncComponent(() => import('./pages/layouts/dashboard')),
    page: asyncComponent(() => import('./pages/platforms')),
    meta: {
      title: 'Platforms',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/roles',
    exact: true,
    name: 'APP.ROUTE.ROLES',
    sidebar: true,
    icon: 'icon-graduation',
    permissions: ['Admin'],
    component: asyncComponent(() => import('./pages/layouts/dashboard')),
    page: asyncComponent(() => import('./pages/roles')),
    meta: {
      title: 'Roles',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/tag-address',
    exact: true,
    name: 'Tag Address',
    sidebar: true,
    icon: 'icon-map',
    permissions: ['Admin'],
    component: asyncComponent(() => import('./pages/layouts/dashboard')),
    page: asyncComponent(() => import('./pages/tag-address')),
    meta: {
      title: 'Tag Address',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/notification-requests',
    name: 'Notifications',
    sidebar: true,
    icon: 'icon-bell',
    permissions: ['Partner'],
    component: asyncComponent(() => import('./pages/layouts/dashboard')),
    page: asyncComponent(() => import('./pages/notification-requests')),
    meta: {
      title: 'Notifications',
      description: '',
      keywords: '',
    },
    routes: [
      {
        path: '/notification-requests/requests',
        name: 'APP.ROUTE.NOTIFICATION.REQUESTS',
        component: asyncComponent(() => import('./pages/notification/requests')),
      },
      {
        path: '/notification-requests/search',
        name: 'APP.ROUTE.NOTIFICATION.SEARCH',
        component: asyncComponent(() => import('./pages/notification/search')),
      },
    ],
  },
  {
    path: '/notification-reqeusts-admin',
    name: 'Notifications',
    sidebar: true,
    icon: 'icon-book-open',
    permissions: ['Admin'],
    component: asyncComponent(() => import('./pages/layouts/dashboard')),
    page: asyncComponent(() => import('./pages/notification-requests-admin')),
    meta: {
      title: 'Notifications',
      description: '',
      keywords: '',
    },
    routes: [
      {
        path: '/notification-reqeusts-admin/requests',
        name: 'APP.ROUTE.NOTIFICATION.REQUESTS',
        component: asyncComponent(() => import('./pages/notification/requests-admin')),
      },
      // {
      //   path: '/notification-requests/search',
      //   name: 'APP.ROUTE.NOTIFICATION.SEARCH',
      //   component: asyncComponent(() => import('./pages/notification/search')),
      // },
    ],
  },
  {
    path: '/checkout-sessions',
    exact: true,
    name: 'Checkout Sessions',
    sidebar: true,
    icon: 'icon-check',
    permissions: ['Admin'],
    component: asyncComponent(() => import('./pages/layouts/dashboard')),
    page: asyncComponent(() => import('./pages/checkout-sessions')),
    meta: {
      title: 'Checkout Sessions',
      description: '',
      keywords: '',
    },
  },
  {
    path: '/settings',
    component: asyncComponent(() => import('./pages/layouts/dashboard')),
    page: asyncComponent(() => import('./pages/settings')),
    meta: {
      title: 'Settings',
      description: '',
      keywords: '',
    },
    routes: [
      {
        path: '/settings/profile',
        name: 'APP.ROUTE.SETTINGS.PROFILE',
        component: asyncComponent(() => import('./pages/settings/profile')),
      },
      {
        path: '/settings/password',
        name: 'APP.ROUTE.SETTINGS.PASSWORD',
        component: asyncComponent(() => import('./pages/settings/password')),
      },
    ],
  },
  {
    page: asyncComponent(() => import('./pages/page-404')),
    component: asyncComponent(() => import('./pages/layouts/public')),
    meta: {
      title: 'Page Not found',
    },
  },
];

export default routes;
