// local configuration object
const local = {
  // API_BASE_URL: 'http://localhost:3000/v1',
  API_BASE_URL: 'https://dev-api.thetag.app/v1',
  GOOGLE_CLIENT_ID: '249695749434-edebsd8crrbqps42h5u0kv5lgesdv7ub.apps.googleusercontent.com',
  FACEBOOK_CLIENT_ID: '678724035924792',
  refreshTokenTreshold: 1,
  GOOGLE_MAPS_API_KEY: 'AIzaSyDY7wkcUtSYlhJA9gprb6YyZW1lSk3--wE',
  STRIPE_PUBLIC_KEY: 'pk_test_lgk2pmBzhNMh9l4JC8YIBJhN00iOGaSUVK',
  MIXPANEL_TOKEN: '35c495e75748cc463203e9d8b99b3b50',
  CONFIRM_EMAIL_MOBILE: 'https://mobile-dev.thetag.app/confirm-email',
};

// dev environment
const development = {
  API_BASE_URL: 'https://dev-api.thetag.app/v1',
  refreshTokenTreshold: 1,
  GOOGLE_CLIENT_ID: '249695749434-edebsd8crrbqps42h5u0kv5lgesdv7ub.apps.googleusercontent.com',
  FACEBOOK_CLIENT_ID: '678724035924792',
  GOOGLE_MAPS_API_KEY: 'AIzaSyDY7wkcUtSYlhJA9gprb6YyZW1lSk3--wE',
  STRIPE_PUBLIC_KEY: 'pk_test_lgk2pmBzhNMh9l4JC8YIBJhN00iOGaSUVK',
  MIXPANEL_TOKEN: '35c495e75748cc463203e9d8b99b3b50',
  CONFIRM_EMAIL_MOBILE: 'https://mobile-dev.thetag.app/confirm-email',
};

// staging environment
const staging = {
  API_BASE_URL: 'https://stg-api.thetag.app/v1',
  refreshTokenTreshold: 1,
  GOOGLE_CLIENT_ID: '',
  FACEBOOK_CLIENT_ID: '',
  GOOGLE_MAPS_API_KEY: 'AIzaSyDY7wkcUtSYlhJA9gprb6YyZW1lSk3--wE',
  STRIPE_PUBLIC_KEY: 'pk_test_lgk2pmBzhNMh9l4JC8YIBJhN00iOGaSUVK',
  MIXPANEL_TOKEN: '35c495e75748cc463203e9d8b99b3b50',
  CONFIRM_EMAIL_MOBILE: 'https://mobile-stg.thetag.app/confirm-email',
};

const production = {
  API_BASE_URL: 'https://api-v2.thetag.app/v1',
  refreshTokenTreshold: 1,
  GOOGLE_CLIENT_ID: '',
  FACEBOOK_CLIENT_ID: '',
  GOOGLE_MAPS_API_KEY: 'AIzaSyDY7wkcUtSYlhJA9gprb6YyZW1lSk3--wE',
  STRIPE_PUBLIC_KEY: 'pk_live_LcwmagUloAeMoIw2F2bAwUsD00CRZFbGrW',
  MIXPANEL_TOKEN: '4f2c4ee7f0efd5f41bd5b97ba635e0f7',
  CONFIRM_EMAIL_MOBILE: 'https://mobile.thetag.app/confirm-email',
};

const config =
  process.env.REACT_APP_BUILD_TARGET === 'PRODUCTION'
    ? production
    : process.env.REACT_APP_BUILD_TARGET === 'STAGING'
    ? staging
    : process.env.REACT_APP_BUILD_TARGET === 'DEVELOPMENT'
    ? development
    : local;

export default {
  BUILD_TARGET: process.env.REACT_APP_BUILD_TARGET,
  PLATFORM: 'web-dashboard',
  VERSION: 1,
  defaultLanguage: 'en',
  whitelistedRoles: ['Admin', 'Partner'],
  languages: [
    { code: 'en', title: 'APP.LANG.ENGLISH', flag: 'United-Kingdom' },
    { code: 'sv', title: 'APP.LANG.SWEDISH', flag: 'Sweden' },
  ],
  CRYPTO_SETTINGS: {
    key: 'kYp3s6v9ySB&E)H@',
    iv: 'PeSgVkYp3s6v9ySB',
    keyType: 'utf8',
    cryptoType: 'base64',
  },
  ...config,
};
