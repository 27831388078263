import { AnalyticsActions } from '../actions';

import { MODE } from './mode';

const INITIAL_STATE = {
  checkinAnalytics: { data: null, error: null, mode: null },
  activecheckinAnalytics: { data: null, error: null, mode: null },
  soldTagsWithFee: { data: null, error: null, mode: null },
  activeSoldTagsWithFee: { data: null, error: null, mode: null },
  soldTags: { data: null, error: null, mode: null },
  activeSoldTags: { data: null, error: null, mode: null },
  lastEvent: { data: null, error: null, mode: null },
  activeLastEvent: { data: null, error: null, mode: null },
  topSellerByGenre: { data: [], params: {}, pager: null, error: null, loading: false },
  tagAddresesAnalytics: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerEventsAnalytics: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerUpcomingRewardsAnalytics: {
    data: [],
    params: {},
    pager: null,
    error: null,
    loading: false,
  },
  partnerPastRewardsAnalytics: { data: [], params: {}, pager: null, error: null, loading: false },
  // topSellerByGenre: { data: null, error: null, mode: null },
  activeTopSellerByGenre: { data: null, error: null, mode: null },
};

export const analytics = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AnalyticsActions.TYPES.ANALYTICS_CHECKINS_RESET:
      return {
        ...state,
        activecheckinAnalytics: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== ANALYTICS CHECKINS FETCH START ==================================//
    case AnalyticsActions.TYPES.ANALYTICS_CHECKINS_FETCH_START:
      return {
        ...state,
        activecheckinAnalytics: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_CHECKINS_FETCH_FAILURE:
      return {
        ...state,
        activecheckinAnalytics: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_CHECKINS_FETCH_SUCCESS:
      return {
        ...state,
        activecheckinAnalytics: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  ANALYTICS CHECKINS FETCH END  ==================================//
    //================================== ANALYTICS SOLD TAGS WITH FEE FETCH START ==================================//
    case AnalyticsActions.TYPES.ANALYTICS_SOLD_TAGS_WITH_FEE_FETCH_START:
      return {
        ...state,
        activeSoldTagsWithFee: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_SOLD_TAGS_WITH_FEE_FAILURE:
      return {
        ...state,
        activeSoldTagsWithFee: {
          activecheckinAnalytics: {
            data: null,
            loading: false,
            error: action.payload,
            mode: MODE.VIEW,
          },
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_SOLD_TAGS_WITH_FEE_SUCCESS:
      return {
        ...state,
        activeSoldTagsWithFee: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  ANALYTICS SOLD TAGS WITH FEE  FETCH END  ==================================//
    //================================== ANALYTICS SOLD TAGS FETCH START ==================================//
    case AnalyticsActions.TYPES.ANALYTICS_SOLD_TAGS_FETCH_START:
      return {
        ...state,
        activeSoldTags: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_SOLD_TAGS_FAILURE:
      return {
        ...state,
        activeSoldTags: {
          activeSoldTags: {
            data: null,
            loading: false,
            error: action.payload,
            mode: MODE.VIEW,
          },
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_SOLD_TAGS_SUCCESS:
      return {
        ...state,
        activeSoldTags: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  ANALYTICS SOLD TAGS FETCH END  ==================================//
    //================================== ANALYTICS LAST EVENT FETCH START ==================================//
    case AnalyticsActions.TYPES.ANALYTICS_LAST_EVENT_FETCH_START:
      return {
        ...state,
        activeLastEvent: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_LAST_EVENT_FAILURE:
      return {
        ...state,
        activeLastEvent: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_LAST_EVENT_SUCCESS:
      return {
        ...state,
        activeLastEvent: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  ANALYTICS LAST EVENT FETCH END  ==================================//
    //================================== ANALYTICS GENRE TOP SELLER FETCH START ==================================//
    case AnalyticsActions.TYPES.ANALYTICS_GENRE_TOP_SELLER_FETCH_START:
      return {
        ...state,
        topSellerByGenre: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_GENRE_TOP_SELLER_FETCH_FAILURE:
      return {
        ...state,
        topSellerByGenre: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_GENRE_TOP_SELLER_FETCH_SUCCESS:
      return {
        ...state,
        topSellerByGenre: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  ANALYTICS GENRE TOP SELLER FETCH END  ==================================//
    //================================== ANALYTICS TAG ADDRESES FETCH START ==================================//
    case AnalyticsActions.TYPES.ANALYTICS_TAG_ADDRESES_FETCH_START:
      return {
        ...state,
        tagAddresesAnalytics: {
          ...state.tagAddresesAnalytics,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_TAG_ADDRESES_FETCH_FAILURE:
      return {
        ...state,
        tagAddresesAnalytics: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_TAG_ADDRESES_FETCH_SUCCESS:
      return {
        ...state,
        tagAddresesAnalytics: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_PARTNER_EVENTS_FETCH_START:
      return {
        ...state,
        partnerEventsAnalytics: {
          ...state.partnerEventsAnalytics,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_PARTNER_EVENTS_FETCH_FAILURE:
      return {
        ...state,
        partnerEventsAnalytics: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_PARTNER_EVENTS_FETCH_SUCCESS:
      return {
        ...state,
        partnerEventsAnalytics: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_PARTNER_REWARDS_FETCH_START:
      return {
        ...state,
        partnerUpcomingRewardsAnalytics: {
          ...state.partnerUpcomingRewardsAnalytics,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_PARTNER_REWARDS_FETCH_FAILURE:
      return {
        ...state,
        partnerUpcomingRewardsAnalytics: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_PARTNER_REWARDS_FETCH_SUCCESS:
      return {
        ...state,
        partnerUpcomingRewardsAnalytics: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_PARTNER_PAST_REWARDS_FETCH_START:
      return {
        ...state,
        partnerPastRewardsAnalytics: {
          ...state.partnerPastRewardsAnalytics,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_PARTNER_PAST_REWARDS_FETCH_FAILURE:
      return {
        ...state,
        partnerPastRewardsAnalytics: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case AnalyticsActions.TYPES.ANALYTICS_PARTNER_PAST_REWARDS_FETCH_SUCCESS:
      return {
        ...state,
        partnerPastRewardsAnalytics: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  ANALYTICS TAG ADDRESES FETCH END  ==================================//
    default:
      return state;
  }
};
