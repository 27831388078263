import { LookupsAPI } from '../api';

export const TYPES = {
  ROOT_CATEGORIES_FETCH_START: 'LOOKUP_ROOT_CATEGORIES_FETCH_START',
  ROOT_CATEGORIES_FETCH_SUCCESS: 'LOOKUP_ROOT_CATEGORIES_FETCH_SUCCESS',
  ROOT_CATEGORIES_FETCH_FAILURE: 'LOOKUP_ROOT_CATEGORIES_FETCH_FAILURE',

  CATEGORIES_FETCH_START: 'LOOKUP_CATEGORIES_FETCH_START',
  CATEGORIES_FETCH_SUCCESS: 'LOOKUP_CATEGORIES_FETCH_SUCCESS',
  CATEGORIES_FETCH_FAILURE: 'LOOKUP_CATEGORIES_FETCH_FAILURE',

  GENDERS_FETCH_START: 'LOOKUP_GENDERS_FETCH_START',
  GENDERS_FETCH_SUCCESS: 'LOOKUP_GENDERS_FETCH_SUCCESS',
  GENDERS_FETCH_FAILURE: 'LOOKUP_GENDERS_FETCH_FAILURE',

  PARTNER_ROLES_FETCH_START: 'LOOKUP_PARTNER_ROLES_FETCH_START',
  PARTNER_ROLES_FETCH_SUCCESS: 'LOOKUP_PARTNER_ROLES_FETCH_SUCCESS',
  PARTNER_ROLES_FETCH_FAILURE: 'LOOKUP_GENDERS_PARTNER_ROLES_FAILURE',
};

export const fetchRootCategories = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ROOT_CATEGORIES_FETCH_START, payload: params });
      const data = await LookupsAPI.FetchRootCategories(params);
      dispatch({ type: TYPES.ROOT_CATEGORIES_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ROOT_CATEGORIES_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchCategories = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.CATEGORIES_FETCH_START, payload: params });
      const data = await LookupsAPI.FetchCategories(params);
      dispatch({ type: TYPES.CATEGORIES_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.CATEGORIES_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchGenders = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.GENDERS_FETCH_START, payload: params });
      const data = await LookupsAPI.FetchGenders(params);
      dispatch({ type: TYPES.GENDERS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.GENDERS_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchPartnerRoles = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_ROLES_FETCH_START, payload: params });
      const data = await LookupsAPI.FetchPartnerRoles(params);
      dispatch({ type: TYPES.PARTNER_ROLES_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_ROLES_FETCH_FAILURE, payload: e });
    }
  };
};
