import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { rootReducer } from './reducers';
import refreshTokenMiddleware from './middleware/refresh-token';

export default initialState =>
  createStore(
    rootReducer,
    initialState,
    compose(
      applyMiddleware(thunk, refreshTokenMiddleware),
      window.devToolsExtension ? window.devToolsExtension() : f => f
    )
  );
