import { PlatformActions } from '../actions';

import { MODE } from './mode';

const INITIAL_STATE = {
  platformsList: { data: [], params: {}, pager: null, error: null, loading: false },
  activePlatform: { data: null, error: null, mode: null },
};

export const platforms = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PlatformActions.TYPES.PLATFORM_CREATE_RESET:
    case PlatformActions.TYPES.PLATFORM_UPDATE_RESET:
    case PlatformActions.TYPES.PLATFORM_DELETE_RESET:
    case PlatformActions.TYPES.PLATFORM_FETCH_RESET:
      return {
        ...state,
        activePlatform: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== PLATFORM CREATE START ==================================//
    case PlatformActions.TYPES.PLATFORM_CREATE_START:
      return {
        ...state,
        activePlatform: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case PlatformActions.TYPES.PLATFORM_CREATE_FAILURE:
      return {
        ...state,
        activePlatform: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PlatformActions.TYPES.PLATFORM_CREATE_SUCCESS:
      return {
        ...state,
        platformsList: {
          ...state.platformsList,
          data: state.platformsList.data.concat(action.payload),
          error: null,
          loading: false,
        },
        activePlatform: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  PLATFORM CREATE END  ==================================//
    //================================== PLATFORM UPDATE START ==================================//
    case PlatformActions.TYPES.PLATFORM_UPDATE_START:
      return {
        ...state,
        activePlatform: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case PlatformActions.TYPES.PLATFORM_UPDATE_FAILURE:
      return {
        ...state,
        activePlatform: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case PlatformActions.TYPES.PLATFORM_UPDATE_SUCCESS:
      return {
        ...state,
        platformsList: {
          ...state.platformsList,
          data: state.platformsList.data.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activePlatform: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  PLATFORM UPDATE END  ==================================//
    //================================== PLATFORM DELETE START ==================================//
    case PlatformActions.TYPES.PLATFORM_DELETE_START:
      return {
        ...state,
        activePlatform: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case PlatformActions.TYPES.PLATFORM_DELETE_FAILURE:
      return {
        ...state,
        activePlatform: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case PlatformActions.TYPES.PLATFORM_DELETE_SUCCESS:
      return {
        ...state,
        platformsList: {
          ...state.platformsList,
          data: state.platformsList.data.filter(platform => platform.id !== action.payload.id),
          error: null,
          loading: false,
        },
        activePlatform: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  PLATFORM DELETE END  ==================================//
    //================================== PLATFORM FETCH START ==================================//
    case PlatformActions.TYPES.PLATFORM_FETCH_START:
      return {
        ...state,
        activePlatform: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PlatformActions.TYPES.PLATFORM_FETCH_FAILURE:
      return {
        ...state,
        activePlatform: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case PlatformActions.TYPES.PLATFORM_FETCH_SUCCESS:
      return {
        ...state,
        activePlatform: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  PLATFORM FETCH END  ==================================//
    //================================== PLATFORMS FETCH START ==================================//
    case PlatformActions.TYPES.PLATFORMS_FETCH_START:
      return {
        ...state,
        platformsList: {
          ...state.platformsList,
          params: action.payload,
          loading: true,
        },
      };
    case PlatformActions.TYPES.PLATFORMS_FETCH_SUCCESS:
      return {
        ...state,
        platformsList: {
          ...state.platformsList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case PlatformActions.TYPES.PLATFORMS_FETCH_FAILURE:
      return {
        ...state,
        platformsList: {
          ...state.platformsList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case PlatformActions.TYPES.PLATFORMS_FETCH_RESET:
      return {
        ...state,
        platformsList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PLATFORMS FETCH END  ==================================//
    default:
      return state;
  }
};
