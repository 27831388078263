import { CategoryActions } from '../actions';

import { MODE } from './mode';

const INITIAL_STATE = {
  categoriesList: { data: [], params: {}, pager: null, error: null, loading: false },
  activeCategory: { data: null, error: null, mode: null },
};

export const categories = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CategoryActions.TYPES.CATEGORY_CREATE_RESET:
    case CategoryActions.TYPES.CATEGORY_UPDATE_RESET:
    case CategoryActions.TYPES.CATEGORY_DELETE_RESET:
    case CategoryActions.TYPES.CATEGORY_FETCH_RESET:
      return {
        ...state,
        activeCategory: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== CATEGORY CREATE START ==================================//
    case CategoryActions.TYPES.CATEGORY_CREATE_START:
      return {
        ...state,
        activeCategory: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case CategoryActions.TYPES.CATEGORY_CREATE_FAILURE:
      return {
        ...state,
        activeCategory: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case CategoryActions.TYPES.CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        categoriesList: {
          ...state.categoriesList,
          data: state.categoriesList.data.concat(action.payload),
          error: null,
          loading: false,
        },
        activeCategory: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  CATEGORY CREATE END  ==================================//
    //================================== CATEGORY UPDATE START ==================================//
    case CategoryActions.TYPES.CATEGORY_UPDATE_START:
      return {
        ...state,
        activeCategory: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case CategoryActions.TYPES.CATEGORY_UPDATE_FAILURE:
      return {
        ...state,
        activeCategory: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case CategoryActions.TYPES.CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        categoriesList: {
          ...state.categoriesList,
          data: state.categoriesList.data.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeCategory: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  CATEGORY UPDATE END  ==================================//
    //================================== CATEGORY DELETE START ==================================//
    case CategoryActions.TYPES.CATEGORY_DELETE_START:
      return {
        ...state,
        activeCategory: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case CategoryActions.TYPES.CATEGORY_DELETE_FAILURE:
      return {
        ...state,
        activeCategory: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case CategoryActions.TYPES.CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        categoriesList: {
          ...state.categoriesList,
          data: state.categoriesList.data.filter(category => category.id !== action.payload.id),
          error: null,
          loading: false,
        },
        activeCategory: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  CATEGORY DELETE END  ==================================//
    //================================== CATEGORY FETCH START ==================================//
    case CategoryActions.TYPES.CATEGORY_FETCH_START:
      return {
        ...state,
        activeCategory: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case CategoryActions.TYPES.CATEGORY_FETCH_FAILURE:
      return {
        ...state,
        activeCategory: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case CategoryActions.TYPES.CATEGORY_FETCH_SUCCESS:
      return {
        ...state,
        activeCategory: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  CATEGORY FETCH END  ==================================//
    //================================== CATEGORIES FETCH START ==================================//
    case CategoryActions.TYPES.CATEGORIES_FETCH_START:
      return {
        ...state,
        categoriesList: {
          ...state.categoriesList,
          params: action.payload,
          error: null,
          loading: true,
        },
      };
    case CategoryActions.TYPES.CATEGORIES_FETCH_SUCCESS:
      return {
        ...state,
        categoriesList: {
          ...state.categoriesList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case CategoryActions.TYPES.CATEGORIES_FETCH_FAILURE:
      return {
        ...state,
        categoriesList: {
          ...state.categoriesList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case CategoryActions.TYPES.CATEGORIES_FETCH_RESET:
      return {
        ...state,
        categoriesList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  CATEGORIES FETCH END  ==================================//
    default:
      return state;
  }
};
