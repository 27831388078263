import { AnalyticsApi } from '../api';

export const TYPES = {
  ANALYTICS_CHECKINS_FETCH_START: 'ANALYTICS_CHECKINS_FETCH_START',
  ANALYTICS_CHECKINS_FETCH_SUCCESS: 'ANALYTICS_CHECKINS_FETCH_SUCCESS',
  ANALYTICS_CHECKINS_FETCH_FAILURE: 'ANALYTICS_CHECKINS_FETCH_FAILURE',
  ANALYTICS_CHECKINS_FETCH_RESET: 'ANALYTICS_CHECKINS_FETCH_RESET',

  ANALYTICS_SOLD_TAGS_WITH_FEE_FETCH_START: 'ANALYTICS_SOLD_TAGS_WITH_FEE_START',
  ANALYTICS_SOLD_TAGS_WITH_FEE_SUCCESS: 'ANALYTICS_SOLD_TAGS_WITH_FEE_SUCCESS',
  ANALYTICS_SOLD_TAGS_WITH_FEE_FAILURE: 'ANALYTICS_SOLD_TAGS_WITH_FEE_FAILURE',
  ANALYTICS_SOLD_TAGS_WITH_FEE_RESET: 'ANALYTICS_SOLD_TAGS_WITH_FEE_RESET',

  ANALYTICS_SOLD_TAGS_FETCH_START: 'ANALYTICS_SOLD_TAGS_FETCH_START',
  ANALYTICS_SOLD_TAGS_SUCCESS: 'ANALYTICS_SOLD_TAGS_FETCH_SUCCESS',
  ANALYTICS_SOLD_TAGS_FAILURE: 'ANALYTICS_SOLD_TAGS_FETCH_FAILURE',
  ANALYTICS_SOLD_TAGS_RESET: 'ANALYTICS_SOLD_TAGS_FETCH_RESET',

  ANALYTICS_LAST_EVENT_FETCH_START: 'ANALYTICS_LAST_EVENT_FETCH_START',
  ANALYTICS_LAST_EVENT_SUCCESS: 'ANALYTICS_LAST_EVENT_FETCH_SUCCESS',
  ANALYTICS_LAST_EVENT_FAILURE: 'ANALYTICS_LAST_EVENT_FETCH_FAILURE',
  ANALYTICS_LAST_EVENT_RESET: 'ANALYTICS_LAST_EVENT_FETCH_RESET',

  ANALYTICS_GENRE_TOP_SELLER_FETCH_START: 'ANALYTICS_GENRE_TOP_SELLER_FETCH_START',
  ANALYTICS_GENRE_TOP_SELLER_FETCH_SUCCESS: 'ANALYTICS_GENRE_TOP_SELLER_FETCH_SUCCESS',
  ANALYTICS_GENRE_TOP_SELLER_FETCH_FAILURE: 'ANALYTICS_GENRE_TOP_SELLER_FETCH_FAILURE',
  ANALYTICS_GENRE_TOP_SELLER_FETCH_RESET: 'ANALYTICS_GENRE_TOP_SELLER_FETCH_RESET',

  ANALYTICS_TAG_ADDRESES_FETCH_START: 'ANALYTICS_TAG_ADDRESES_FETCH_START',
  ANALYTICS_TAG_ADDRESES_FETCH_SUCCESS: 'ANALYTICS_TAG_ADDRESES_FETCH_SUCCESS',
  ANALYTICS_TAG_ADDRESES_FETCH_FAILURE: 'ANALYTICS_TAG_ADDRESES_FETCH_FAILURE',
  ANALYTICS_TAG_ADDRESES_FETCH_RESET: 'ANALYTICS_TAG_ADDRESES_FETCH_RESET',

  ANALYTICS_PARTNER_EVENTS_FETCH_START: 'ANALYTICS_PARTNER_EVENTS_FETCH_START',
  ANALYTICS_PARTNER_EVENTS_FETCH_SUCCESS: 'ANALYTICS_PARTNER_EVENTS_FETCH_SUCCESS',
  ANALYTICS_PARTNER_EVENTS_FETCH_FAILURE: 'ANALYTICS_PARTNER_EVENTS_FETCH_FAILURE',
  ANALYTICS_PARTNER_EVENTS_FETCH_RESET: 'ANALYTICS_PARTNER_EVENTS_FETCH_RESET',

  ANALYTICS_PARTNER_REWARDS_FETCH_START: 'ANALYTICS_PARTNER_REWARDS_FETCH_START',
  ANALYTICS_PARTNER_REWARDS_FETCH_SUCCESS: 'ANALYTICS_PARTNER_REWARDS_FETCH_SUCCESS',
  ANALYTICS_PARTNER_REWARDS_FETCH_FAILURE: 'ANALYTICS_PARTNER_REWARDS_FETCH_FAILURE',
  ANALYTICS_PARTNER_REWARDS_FETCH_RESET: 'ANALYTICS_PARTNER_REWARDS_FETCH_RESET',

  ANALYTICS_PARTNER_PAST_REWARDS_FETCH_START: 'ANALYTICS_PARTNER_PAST_REWARDS_FETCH_START',
  ANALYTICS_PARTNER_PAST_REWARDS_FETCH_SUCCESS: 'ANALYTICS_PARTNER_PAST_REWARDS_FETCH_SUCCESS',
  ANALYTICS_PARTNER_PAST_REWARDS_FETCH_FAILURE: 'ANALYTICS_PARTNER_PAST_REWARDS_FETCH_FAILURE',
  ANALYTICS_PARTNER_PAST_REWARDS_FETCH_RESET: 'ANALYTICS_PARTNER_PAST_REWARDS_FETCH_RESET',
};

export const fetchCheckinAnalytics = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ANALYTICS_CHECKINS_FETCH_START, payload: params });
      const data = await AnalyticsApi.FetchCheckinAnalytics(params);
      dispatch({ type: TYPES.ANALYTICS_CHECKINS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ANALYTICS_CHECKINS_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchSoldTagsWithFee = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ANALYTICS_SOLD_TAGS_WITH_FEE_FETCH_START, payload: params });
      const data = await AnalyticsApi.SoldTagsWithFee(params);
      dispatch({ type: TYPES.ANALYTICS_SOLD_TAGS_WITH_FEE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ANALYTICS_SOLD_TAGS_WITH_FEE_FAILURE, payload: e });
    }
  };
};

export const fetchSoldTags = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ANALYTICS_SOLD_TAGS_FETCH_START, payload: params });
      const data = await AnalyticsApi.SoldTags(params);
      dispatch({ type: TYPES.ANALYTICS_SOLD_TAGS_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ANALYTICS_SOLD_TAGS_FAILURE, payload: e });
    }
  };
};

export const fetchLastEvent = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ANALYTICS_LAST_EVENT_FETCH_START, payload: params });
      const data = await AnalyticsApi.LastEvent(params);
      dispatch({ type: TYPES.ANALYTICS_LAST_EVENT_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ANALYTICS_LAST_EVENT_FAILURE, payload: e });
    }
  };
};

export const fetchGenreTopSeller = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ANALYTICS_GENRE_TOP_SELLER_FETCH_START, payload: params });
      const data = await AnalyticsApi.GenreTopSeller(params);
      dispatch({ type: TYPES.ANALYTICS_GENRE_TOP_SELLER_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ANALYTICS_GENRE_TOP_SELLER_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchTagAddressesAnalytics = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ANALYTICS_TAG_ADDRESES_FETCH_START, payload: params });
      const data = await AnalyticsApi.FetchTagAddressesAnalytics(params);
      dispatch({ type: TYPES.ANALYTICS_TAG_ADDRESES_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ANALYTICS_TAG_ADDRESES_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchPartnerEvents = (partnerId, filter) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ANALYTICS_PARTNER_EVENTS_FETCH_START, payload: partnerId });
      const data = await AnalyticsApi.FetchPartnerEvents(partnerId, filter);
      dispatch({ type: TYPES.ANALYTICS_PARTNER_EVENTS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ANALYTICS_PARTNER_EVENTS_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchUpcomingPartnerRewards = (partnerId, filter) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ANALYTICS_PARTNER_REWARDS_FETCH_START, payload: partnerId });
      const data = await AnalyticsApi.FetchPartnerRewards(partnerId, filter);
      dispatch({ type: TYPES.ANALYTICS_PARTNER_REWARDS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ANALYTICS_PARTNER_REWARDS_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchPastPartnerRewards = (partnerId, filter) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.ANALYTICS_PARTNER_PAST_REWARDS_FETCH_START, payload: partnerId });
      const data = await AnalyticsApi.FetchPartnerRewards(partnerId, filter);
      dispatch({ type: TYPES.ANALYTICS_PARTNER_PAST_REWARDS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.ANALYTICS_PARTNER_PAST_REWARDS_FETCH_FAILURE, payload: e });
    }
  };
};
