import promise from 'promise';
import axios from 'axios';

import { AppConfig } from '../config';

// custom axios instance
const BaseAPI = axios.create({
  baseURL: AppConfig.API_BASE_URL,
});

let retryCount = [];
let maxRetry = 5;

//configure request interceptors
BaseAPI.interceptors.request.use(
  config => {
    if (!AppConfig.BUILD_TARGET && AppConfig.API_BASE_URL.startsWith('./')) {
      config.url = `data/${config.url}_${config.method}.json`;
      config.method = 'get';
    }

    if (typeof localStorage.getItem('token') === 'string') {
      // add request interceptors, like token, custom headers,...
      config.headers.authorization = 'Bearer ' + localStorage.getItem('token');
    }

    config.headers['app-platform'] = AppConfig.PLATFORM;
    config.headers['app-version'] = AppConfig.VERSION;
    return config;
  },
  error => {
    return promise.reject(error);
  }
);

// configure response interceptor
BaseAPI.interceptors.response.use(
  response => {
    // do something with response, perhaps try to unify api responses
    return response.data ? response.data : response;
  },
  error => {
    if (error) {
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }

      if (error && error.response && error.response.status === 451) {
        localStorage.clear();
        window.location.href = '/';
        return Promise.reject(error);
      }

      if (error.response && (error.response.status === 400 || error.response.status === 500)) {
        if (error.response.data && error.response.data.error) {
          if (error.response.data.error.validation) {
            const key = Object.keys(error.response.data.error.validation)[0];
            const value = Object.values(error.response.data.error.validation)[0];

            if (key && value) {
              return Promise.reject(`API-VALIDATION.${key.toUpperCase()}.${value.toUpperCase()}`);
            }
          }
          return Promise.reject(error.response.data.error.message);
        }
        return Promise.reject(error);
      }
      if (error.response && error.response.status === 401 && error.config) {
        //check retry count
        if (retryCount[error.config.url] > maxRetry) {
          localStorage.clear();
          window.location.href = '/';
          return Promise.reject(error);
        }

        // retry api call in 1 second
        const retryPromise = new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              let val = retryCount[error.config.url];
              if (!val || (val && isNaN(val))) {
                val = 1;
              } else {
                val += 1;
              }

              retryCount[error.config.url] = val;
              const resp = await BaseAPI(error.config);
              resolve(resp);
            } catch (e) {
              reject(e);
            }
          }, 1000);
        });

        return retryPromise;
      }

      if (error.message) {
        return Promise.reject(error.message);
      }
    }

    return Promise.reject(error);
  }
);

BaseAPI.objectToQueryParams = obj => {
  if (!obj) return '';
  const searchParams = new URLSearchParams();
  Object.keys(obj).forEach(key => searchParams.append(key, obj[key]));
  return searchParams.toString();
};

export default BaseAPI;
