import BaseAPI from './base-api';

const PaymentAPI = {};

PaymentAPI.FetchAvailableTag = async (partnerId, tagSeriesId, params) => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(
    `/payment/partners/${partnerId}/tag-series/${tagSeriesId}/tags?${query}`
  );
  return response.data;
};

PaymentAPI.CreateCheckoutSession = async (partnerId, checkout, params) => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.post(`/payment/session/${partnerId}/?${query}`, {
    ...checkout,
  });

  return response.data;
};

PaymentAPI.CheckoutSessionSuccess = async checkoutSessionId => {
  const response = await BaseAPI.get(`/payment/session-success/${checkoutSessionId}`);

  return response.data;
};

PaymentAPI.SaveShippingAddress = async (checkoutSessionId, tagShippingAddress) => {
  const response = await BaseAPI.post(`/payment/session-shipping-address/${checkoutSessionId}`, {
    ...tagShippingAddress,
  });

  return response.data;
};

PaymentAPI.CreateShipment = async (partnerId, shippingAddress) => {
  const response = await BaseAPI.post(`/shipping/partners/${partnerId}/shipment`, {
    ...shippingAddress,
  });

  return response.data;
};

PaymentAPI.FetchPartner = async id => {
  const response = await BaseAPI.get(`/payment/partners/${id}/`);

  return response.data;
};

PaymentAPI.FetchTagSeries = async (partnerId, tagSeriesId, params) => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(
    `payment/partners/${partnerId}/tag-series/${tagSeriesId}/?${query}`
  );

  return response.data;
};

PaymentAPI.SaveEmail = async email => {
  const response = await BaseAPI.post(`/payment/save-email/`, {
    ...email,
  });
  return response.data;
};

PaymentAPI.FetchTagAddress = async params => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`/payment-admin/tag-address/?${query}`);
  return response.data;
};

PaymentAPI.FetchCheckoutSessions = async params => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`/payment-admin/checkout-sessions/?${query}`);
  return response.data;
};

PaymentAPI.FetchPartnerData = async id => {
  const response = await BaseAPI.get(`payment/partners/${id}/details`);

  return response.data;
};

export default PaymentAPI;
