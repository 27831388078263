import * as AccountActions from './account';
import * as AnalyticsActions from './analytics';
import * as CategoryActions from './categories';
import * as GenderActions from './genders';
import * as LookupActions from './lookups';
import * as NotificationActions from './notification';
import * as PartnerActions from './partners';
import * as PaymentActions from './payment';
import * as PlatformActions from './platforms';
import * as RoleActions from './roles';
import * as TagActions from './tags';
import * as UploadActions from './upload';
import * as UserActions from './users';

export {
  AccountActions,
  AnalyticsActions,
  CategoryActions,
  GenderActions,
  LookupActions,
  NotificationActions,
  PartnerActions,
  PaymentActions,
  PlatformActions,
  RoleActions,
  TagActions,
  UploadActions,
  UserActions,
};
