import { GenderActions } from '../actions';

import { MODE } from './mode';

const INITIAL_STATE = {
  gendersList: { data: [], params: {}, pager: null, error: null, loading: false },
  activeGender: { data: null, error: null, mode: null },
};

export const genders = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GenderActions.TYPES.GENDER_CREATE_RESET:
    case GenderActions.TYPES.GENDER_UPDATE_RESET:
    case GenderActions.TYPES.GENDER_DELETE_RESET:
    case GenderActions.TYPES.GENDER_FETCH_RESET:
      return {
        ...state,
        activeGender: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== GENDER CREATE START ==================================//
    case GenderActions.TYPES.GENDER_CREATE_START:
      return {
        ...state,
        activeGender: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case GenderActions.TYPES.GENDER_CREATE_FAILURE:
      return {
        ...state,
        activeGender: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case GenderActions.TYPES.GENDER_CREATE_SUCCESS:
      return {
        activeGender: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
        gendersList: {
          ...state.gendersList,
          data: state.gendersList.data.concat(action.payload),
          error: null,
          loading: false,
        },
      };

    //==================================  GENDER CREATE END  ==================================//
    //================================== GENDER UPDATE START ==================================//
    case GenderActions.TYPES.GENDER_UPDATE_START:
      return {
        ...state,
        activeGender: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case GenderActions.TYPES.GENDER_UPDATE_FAILURE:
      return {
        ...state,
        activeGender: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case GenderActions.TYPES.GENDER_UPDATE_SUCCESS:
      return {
        ...state,
        gendersList: {
          ...state.gendersList,
          data: state.gendersList.data.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeGender: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  GENDER UPDATE END  ==================================//
    //================================== GENDER DELETE START ==================================//
    case GenderActions.TYPES.GENDER_DELETE_START:
      return {
        ...state,
        activeGender: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case GenderActions.TYPES.GENDER_DELETE_FAILURE:
      return {
        ...state,
        activeGender: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case GenderActions.TYPES.GENDER_DELETE_SUCCESS:
      return {
        ...state,
        gendersList: {
          ...state.gendersList,
          data: state.gendersList.data.filter(gender => gender.id !== action.payload.id),
          error: null,
          loading: false,
        },
        activeGender: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  GENDER DELETE END  ==================================//
    //================================== GENDER FETCH START ==================================//
    case GenderActions.TYPES.GENDER_FETCH_START:
      return {
        ...state,
        activeGender: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case GenderActions.TYPES.GENDER_FETCH_FAILURE:
      return {
        ...state,
        activeGender: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case GenderActions.TYPES.GENDER_FETCH_SUCCESS:
      return {
        ...state,
        activeGender: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  GENDER FETCH END  ==================================//
    //================================== GENDERS FETCH START ==================================//
    case GenderActions.TYPES.GENDERS_FETCH_START:
      return {
        ...state,
        gendersList: {
          ...state.gendersList,
          params: action.payload,
          error: null,
          loading: true,
        },
      };
    case GenderActions.TYPES.GENDERS_FETCH_SUCCESS:
      return {
        ...state,
        gendersList: {
          ...state.gendersList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case GenderActions.TYPES.GENDERS_FETCH_FAILURE:
      return {
        ...state,
        gendersList: {
          ...state.gendersList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case GenderActions.TYPES.GENDERS_FETCH_RESET:
      return {
        ...state,
        gendersList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  GENDERS FETCH END  ==================================//
    default:
      return state;
  }
};
