import { NotificationsAPI } from '../api';

export const TYPES = {
  NOTIFICATION_TAG_ADDRESS_SEARCH_START: 'NOTIFICATION_TAG_ADDRESS_SEARCH_START',
  NOTIFICATION_TAG_ADDRESS_SEARCH_SUCCESS: 'NOTIFICATION_TAG_ADDRESS_SEARCH_SUCCESS',
  NOTIFICATION_TAG_ADDRESS_SEARCH_FALIURE: 'NOTIFICATION_TAG_ADDRESS_SEARCH_FALIURE',
  NOTIFICATION_TAG_ADDRESS_SEARCH_RESET: 'NOTIFICATION_TAG_ADDRESS_SEARCH_RESET',

  NOTIFICATION_CREATE_REQUEST_START: 'NOTIFICATION_CREATE_REQUEST_START',
  NOTIFICATION_CREATE_REQUEST_SUCCESS: 'NOTIFICATION_CREATE_REQUEST_SUCCESS',
  NOTIFICATION_CREATE_REQUEST_FALIURE: 'NOTIFICATION_CREATE_REQUEST_FALIURE',
  NOTIFICATION_CREATE_REQUEST_RESET: 'NOTIFICATION_CREATE_REQUEST_RESET',

  NOTIFICATION_FETCH_REQUESTS_START: 'NOTIFICATION_FETCH_REQUESTS_START',
  NOTIFICATION_FETCH_REQUESTS_SUCCESS: 'NOTIFICATION_FETCH_REQUESTS_SUCCESS',
  NOTIFICATION_FETCH_REQUESTS_FALIURE: 'NOTIFICATION_FETCH_REQUESTS_FALIURE',
  NOTIFICATION_FETCH_REQUESTS_RESET: 'NOTIFICATION_FETCH_REQUESTS_RESET',

  NOTIFICATION_FETCH_REQUESTS_ADMIN_START: 'NOTIFICATION_FETCH_REQUESTS_ADMIN_START',
  NOTIFICATION_FETCH_REQUESTS_ADMIN_SUCCESS: 'NOTIFICATION_FETCH_REQUESTS_ADMIN_SUCCESS',
  NOTIFICATION_FETCH_REQUESTS_ADMIN_FALIURE: 'NOTIFICATION_FETCH_REQUESTS_ADMIN_FALIURE',
  NOTIFICATION_FETCH_REQUESTS_ADMIN_RESET: 'NOTIFICATION_FETCH_REQUESTS_ADMIN_RESET',

  NOTIFICATION_APPROVE_REQUEST_START: 'NOTIFICATION_APPROVE_REQUEST_START',
  NOTIFICATION_APPROVE_REQUEST_SUCCESS: 'NOTIFICATION_APPROVE_REQUEST_SUCCESS',
  NOTIFICATION_APPROVE_REQUEST_FALIURE: 'NOTIFICATION_APPROVE_REQUEST_FALIURE',
  NOTIFICATION_APPROVE_REQUEST_RESET: 'NOTIFICATION_APPROVE_REQUEST_RESET',

  NOTIFICATION_FORBID_REQUEST_START: 'NOTIFICATION_FORBID_REQUEST_START',
  NOTIFICATION_FORBID_REQUEST_SUCCESS: 'NOTIFICATION_TAG_ADDRESS_SEARCH_SUCCESS',
  NOTIFICATION_FORBID_REQUEST_FALIURE: 'NOTIFICATION_FORBID_REQUEST_FALIURE',
  NOTIFICATION_FORBID_REQUEST_RESET: 'NOTIFICATION_FORBID_REQUEST_RESET',
};

export const searchTagAddresses = (city, country) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.NOTIFICATION_TAG_ADDRESS_SEARCH_START, payload: city, country });
      const data = await NotificationsAPI.SearchTagAddresses(city, country);
      dispatch({ type: TYPES.NOTIFICATION_TAG_ADDRESS_SEARCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.NOTIFICATION_TAG_ADDRESS_SEARCH_FALIURE, payload: e });
    }
  };
};

export const createNotificationRequest = (city, country, message) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.NOTIFICATION_CREATE_REQUEST_START,
        payload: city,
        country,
        message,
      });
      const data = await NotificationsAPI.CreateNotificationRequest(city, country, message);
      dispatch({ type: TYPES.NOTIFICATION_CREATE_REQUEST_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.NOTIFICATION_CREATE_REQUEST_FALIURE, payload: e });
    }
  };
};

export const fetchNotificationRequests = () => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.NOTIFICATION_FETCH_REQUESTS_START, payload: {} });
      const data = await NotificationsAPI.FetchNotificationRequests();
      dispatch({ type: TYPES.NOTIFICATION_FETCH_REQUESTS_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.NOTIFICATION_FETCH_REQUESTS_FALIURE, payload: e });
    }
  };
};

export const fetchNotificationRequestsAdmin = () => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.NOTIFICATION_FETCH_REQUESTS_ADMIN_START, payload: {} });
      const data = await NotificationsAPI.FetchNotificationRequestsAdmin();
      dispatch({ type: TYPES.NOTIFICATION_FETCH_REQUESTS_ADMIN_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.NOTIFICATION_FETCH_REQUESTS_ADMIN_FALIURE, payload: e });
    }
  };
};

export const approveRequest = (notificationRequestID, onApproveSuccess) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.NOTIFICATION_APPROVE_REQUEST_START,
        payload: notificationRequestID,
      });
      const data = await NotificationsAPI.ApproveRequest(notificationRequestID);
      if (onApproveSuccess) {
        onApproveSuccess();
      }
      dispatch({ type: TYPES.NOTIFICATION_APPROVE_REQUEST_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.NOTIFICATION_APPROVE_REQUEST_FALIURE, payload: e });
    }
  };
};

export const forbidRequest = (notificationRequestID, status, message, onForbidSuccess) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.NOTIFICATION_FORBID_REQUEST_START,
        payload: notificationRequestID,
        status,
        message,
      });
      const data = await NotificationsAPI.ForbidRequest(notificationRequestID, status, message);
      if (onForbidSuccess) {
        onForbidSuccess();
      }
      dispatch({ type: TYPES.NOTIFICATION_FORBID_REQUEST_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.NOTIFICATION_FORBID_REQUEST_FALIURE, payload: e });
    }
  };
};
