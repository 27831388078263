import { NotificationActions } from '../actions';

import { MODE } from './mode';

const INITIAL_STATE = {
  tagAddresses: { data: [], error: null, mode: null, loading: false },
  notificationRequests: { data: [], error: null, mode: null, loading: false },
  notificationRequestsAdmin: { data: [], error: null, mode: null, loading: false },
  activeRequest: { data: null, error: null, mode: null },
};

export const notifications = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //================================== NOTIFICATION_TAG_ADDRESS_SEARCH_START ==================================//
    case NotificationActions.TYPES.NOTIFICATION_TAG_ADDRESS_SEARCH_START:
      return {
        ...state,
        tagAddresses: {
          ...state.tagAddresses,
          params: action.payload,
          data: [],
          pager: null,
          error: null,
          loading: true,
        },
      };
    case NotificationActions.TYPES.NOTIFICATION_TAG_ADDRESS_SEARCH_SUCCESS:
      return {
        ...state,
        tagAddresses: {
          ...state.tagAddresses,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case NotificationActions.TYPES.NOTIFICATION_TAG_ADDRESS_SEARCH_FALIURE:
      return {
        ...state,
        tagAddresses: {
          ...state.tagAddresses,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case NotificationActions.TYPES.NOTIFICATION_TAG_ADDRESS_SEARCH_RESET:
      return {
        ...state,
        tagAddresses: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };

    //==================================  NOTIFICATION_TAG_ADDRESS_SEARCH_END  ==================================//
    //================================== NOTIFICATION_CREATE_REQUEST_START ==================================//
    case NotificationActions.TYPES.NOTIFICATION_CREATE_REQUEST_START:
      return {
        ...state,
        activeRequest: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case NotificationActions.TYPES.NOTIFICATION_CREATE_REQUEST_SUCCESS:
      return {
        ...state,
        activeRequest: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case NotificationActions.TYPES.NOTIFICATION_CREATE_REQUEST_FALIURE:
      return {
        ...state,
        activeRequest: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };

    //==================================  NOTIFICATION_CREATE_REQUEST_END  ==================================//
    //================================== NOTIFICATION_TAG_ADDRESS_SEARCH_START ==================================//
    case NotificationActions.TYPES.NOTIFICATION_FETCH_REQUESTS_START:
      return {
        ...state,
        notificationRequests: {
          ...state.notificationRequests,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case NotificationActions.TYPES.NOTIFICATION_FETCH_REQUESTS_SUCCESS:
      return {
        ...state,
        notificationRequests: {
          ...state.notificationRequests,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case NotificationActions.TYPES.NOTIFICATION_FETCH_REQUESTS_FALIURE:
      return {
        ...state,
        notificationRequests: {
          ...state.notificationRequests,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case NotificationActions.TYPES.NOTIFICATION_FETCH_REQUESTS_RESET:
      return {
        ...state,
        notificationRequests: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };

    //==================================  NOTIFICATION_TAG_ADDRESS_SEARCH_END  ==================================//
    //================================== NOTIFICATION_TAG_ADDRESS_SEARCH_START ==================================//
    case NotificationActions.TYPES.NOTIFICATION_FETCH_REQUESTS_ADMIN_START:
      return {
        ...state,
        notificationRequestsAdmin: {
          ...state.notificationRequestsAdmin,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case NotificationActions.TYPES.NOTIFICATION_FETCH_REQUESTS_ADMIN_SUCCESS:
      return {
        ...state,
        notificationRequestsAdmin: {
          ...state.notificationRequestsAdmin,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case NotificationActions.TYPES.NOTIFICATION_FETCH_REQUESTS_ADMIN_FALIURE:
      return {
        ...state,
        notificationRequestsAdmin: {
          ...state.notificationRequestsAdmin,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case NotificationActions.TYPES.NOTIFICATION_FETCH_REQUESTS_ADMIN_RESET:
      return {
        ...state,
        notificationRequestsAdmin: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };

    //==================================  NOTIFICATION_TAG_ADDRESS_SEARCH_END  ==================================//
    //================================== NOTIFICATION_CREATE_REQUEST_START ==================================//
    case NotificationActions.TYPES.NOTIFICATION_APPROVE_REQUEST_START:
      return {
        ...state,
        activeRequest: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case NotificationActions.TYPES.NOTIFICATION_APPROVE_REQUEST_SUCCESS:
      return {
        ...state,
        activeRequest: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case NotificationActions.TYPES.NOTIFICATION_APPROVE_REQUEST_FALIURE:
      return {
        ...state,
        activeRequest: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };

    //==================================  NOTIFICATION_CREATE_REQUEST_END  ==================================//
    //================================== NOTIFICATION_CREATE_REQUEST_START ==================================//
    case NotificationActions.TYPES.NOTIFICATION_FORBID_REQUEST_START:
      return {
        ...state,
        activeRequest: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case NotificationActions.TYPES.NOTIFICATION_FORBID_REQUEST_SUCCESS:
      return {
        ...state,
        activeRequest: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case NotificationActions.TYPES.NOTIFICATION_FORBID_REQUEST_FALIURE:
      return {
        ...state,
        activeRequest: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };

    //==================================  NOTIFICATION_CREATE_REQUEST_END  ==================================//
    default:
      return state;
  }
};
