import { PartnersAPI, UploadAPI } from '../api';

export const TYPES = {
  PARTNERS_FETCH_START: 'PARTNERS_FETCH_START',
  PARTNERS_FETCH_SUCCESS: 'PARTNERS_FETCH_SUCCESS',
  PARTNERS_FETCH_FAILURE: 'PARTNERS_FETCH_FAILURE',
  PARTNERS_FETCH_RESET: 'PARTNERS_FETCH_RESET',

  PARTNER_FETCH_START: 'PARTNER_FETCH_START',
  PARTNER_FETCH_SUCCESS: 'PARTNER_FETCH_SUCCESS',
  PARTNER_FETCH_FAILURE: 'PARTNER_FETCH_FAILURE',
  PARTNER_FETCH_RESET: 'PARTNER_FETCH_RESET',

  REFERRAL_CODE_FETCH_START: 'REFERRAL_CODE_FETCH_START',
  REFERRAL_CODE_FETCH_SUCCESS: 'REFERRAL_CODE_FETCH_SUCCESS',
  REFERRAL_CODE_FETCH_FAILURE: 'REFERRAL_CODE_FETCH_FAILURE',
  REFERRAL_CODE_FETCH_RESET: 'REFERRAL_CODE_FETCH_RESET',

  PARTNER_CREATE_START: 'PARTNER_CREATE_START',
  PARTNER_CREATE_SUCCESS: 'PARTNER_CREATE_SUCCESS',
  PARTNER_CREATE_FAILURE: 'PARTNER_CREATE_FAILURE',
  PARTNER_CREATE_RESET: 'PARTNER_CREATE_RESET',

  PARTNER_UPDATE_START: 'PARTNER_UPDATE_START',
  PARTNER_UPDATE_SUCCESS: 'PARTNER_UPDATE_SUCCESS',
  PARTNER_UPDATE_FAILURE: 'PARTNER_UPDATE_FAILURE',
  PARTNER_UPDATE_RESET: 'PARTNER_UPDATE_RESET',

  PARTNER_DELETE_START: 'PARTNER_DELETE_START',
  PARTNER_DELETE_SUCCESS: 'PARTNER_DELETE_SUCCESS',
  PARTNER_DELETE_FAILURE: 'PARTNER_DELETE_FAILURE',
  PARTNER_DELETE_RESET: 'PARTNER_DELETE_RESET',

  PARTNER_USERS_FETCH_START: 'PARTNER_USERS_FETCH_START',
  PARTNER_USERS_FETCH_SUCCESS: 'PARTNER_USERS_FETCH_SUCCESS',
  PARTNER_USERS_FETCH_FAILURE: 'PARTNER_USERS_FETCH_FAILURE',
  PARTNER_USERS_FETCH_RESET: 'PARTNER_USERS_FETCH_RESET',

  PARTNER_USER_CREATE_START: 'PARTNER_USER_CREATE_START',
  PARTNER_USER_CREATE_SUCCESS: 'PARTNER_USER_CREATE_SUCCESS',
  PARTNER_USER_CREATE_FAILURE: 'PARTNER_USER_CREATE_FAILURE',
  PARTNER_USER_CREATE_RESET: 'PARTNER_USER_CREATE_RESET',

  PARTNER_USER_DELETE_START: 'PARTNER_USER_DELETE_START',
  PARTNER_USER_DELETE_SUCCESS: 'PARTNER_USER_DELETE_SUCCESS',
  PARTNER_USER_DELETE_FAILURE: 'PARTNER_USER_DELETE_FAILURE',
  PARTNER_USER_DELETE_RESET: 'PARTNER_USER_DELETE_RESET',

  PARTNER_TAG_SERIES_FETCH_START: 'PARTNER_TAG_SERIES_FETCH_START',
  PARTNER_TAG_SERIES_FETCH_SUCCESS: 'PARTNER_TAG_SERIES_FETCH_SUCCESS',
  PARTNER_TAG_SERIES_FETCH_FAILURE: 'PARTNER_TAG_SERIES_FETCH_FAILURE',
  PARTNER_TAG_SERIES_FETCH_RESET: 'PARTNER_TAG_SERIES_FETCH_RESET',

  PARTNER_TAG_SERIE_FETCH_START: 'PARTNER_TAG_SERIE_FETCH_START',
  PARTNER_TAG_SERIE_FETCH_SUCCESS: 'PARTNER_TAG_SERIE_FETCH_SUCCESS',
  PARTNER_TAG_SERIE_FETCH_FAILURE: 'PARTNER_TAG_SERIE_FETCH_FAILURE',
  PARTNER_TAG_SERIE_FETCH_RESET: 'PARTNER_TAG_SERIE_FETCH_RESET',

  PARTNER_TAG_SERIES_CREATE_START: 'PARTNER_TAG_SERIES_CREATE_START',
  PARTNER_TAG_SERIES_CREATE_SUCCESS: 'PARTNER_TAG_SERIES_CREATE_SUCCESS',
  PARTNER_TAG_SERIES_CREATE_FAILURE: 'PARTNER_TAG_SERIES_CREATE_FAILURE',
  PARTNER_TAG_SERIES_CREATE_RESET: 'PARTNER_TAG_SERIES_CREATE_RESET',

  PARTNER_TAG_SERIES_UPDATE_START: 'PARTNER_TAG_SERIES_UPDATE_START',
  PARTNER_TAG_SERIES_UPDATE_SUCCESS: 'PARTNER_TAG_SERIES_UPDATE_SUCCESS',
  PARTNER_TAG_SERIES_UPDATE_FAILURE: 'PARTNER_TAG_SERIES_UPDATE_FAILURE',
  PARTNER_TAG_SERIES_UPDATE_RESET: 'PARTNER_TAG_SERIES_UPDATE_RESET',

  PARTNER_TAG_SERIES_DELETE_START: 'PARTNER_TAG_SERIES_DELETE_START',
  PARTNER_TAG_SERIES_DELETE_SUCCESS: 'PARTNER_TAG_SERIES_DELETE_SUCCESS',
  PARTNER_TAG_SERIES_DELETE_FAILURE: 'PARTNER_TAG_SERIES_DELETE_FAILURE',
  PARTNER_TAG_SERIES_DELETE_RESET: 'PARTNER_TAG_SERIES_DELETE_RESET',

  PARTNER_TAGS_FETCH_START: 'PARTNER_TAGS_FETCH_START',
  PARTNER_TAGS_FETCH_SUCCESS: 'PARTNER_TAGS_FETCH_SUCCESS',
  PARTNER_TAGS_FETCH_FAILURE: 'PARTNER_TAGS_FETCH_FAILURE',
  PARTNER_TAGS_FETCH_RESET: 'PARTNER_TAGS_FETCH_RESET',

  PARTNER_TAGS_PRINT_FETCH_START: 'PARTNER_TAGS_PRINT_FETCH_START',
  PARTNER_TAGS_PRINT_FETCH_SUCCESS: 'PARTNER_TAGS_PRINT_FETCH_SUCCESS',
  PARTNER_TAGS_PRINT_FETCH_FAILURE: 'PARTNER_TAGS_PRINT_FETCH_FAILURE',
  PARTNER_TAGS_PRINT_FETCH_RESET: 'PARTNER_TAGS_PRINT_FETCH_RESET',

  SHIPPING_LABELS_PRINT_FETCH_START: 'SHIPPING_LABELS_PRINT_FETCH_START',
  SHIPPING_LABELS_PRINT_FETCH_SUCCESS: 'SHIPPING_LABELS_PRINT_FETCH_SUCCESS',
  SHIPPING_LABELS_PRINT_FETCH_FAILURE: 'SHIPPING_LABELS_PRINT_FETCH_FAILURE',
  SHIPPING_LABELS_PRINT_FETCH_RESET: 'SHIPPING_LABELS_PRINT_FETCH_RESET',

  PARTNER_TAG_FETCH_START: 'PARTNER_TAG_FETCH_START',
  PARTNER_TAG_FETCH_SUCCESS: 'PARTNER_TAG_FETCH_SUCCESS',
  PARTNER_TAG_FETCH_FAILURE: 'PARTNER_TAG_FETCH_FAILURE',
  PARTNER_TAG_FETCH_RESET: 'PARTNER_TAG_FETCH_RESET',

  PARTNER_TAG_UPDATE_START: 'PARTNER_TAG_UPDATE_START',
  PARTNER_TAG_UPDATE_SUCCESS: 'PARTNER_TAG_UPDATE_SUCCESS',
  PARTNER_TAG_UPDATE_FAILURE: 'PARTNER_TAG_UPDATE_FAILURE',
  PARTNER_TAG_UPDATE_RESET: 'PARTNER_TAG_UPDATE_RESET',

  PARTNER_EVENTS_FETCH_START: 'PARTNER_EVENTS_FETCH_START',
  PARTNER_EVENTS_FETCH_SUCCESS: 'PARTNER_EVENTS_FETCH_SUCCESS',
  PARTNER_EVENTS_FETCH_FAILURE: 'PARTNER_EVENTS_FETCH_FAILURE',
  PARTNER_EVENTS_FETCH_RESET: 'PARTNER_EVENTS_FETCH_RESET',

  PARTNER_EVENT_FETCH_START: 'PARTNER_EVENT_FETCH_START',
  PARTNER_EVENT_FETCH_SUCCESS: 'PARTNER_EVENT_FETCH_SUCCESS',
  PARTNER_EVENT_FETCH_FAILURE: 'PARTNER_EVENT_FETCH_FAILURE',
  PARTNER_EVENT_FETCH_RESET: 'PARTNER_EVENT_FETCH_RESET',

  PARTNER_EVENT_CREATE_START: 'PARTNER_EVENT_CREATE_START',
  PARTNER_EVENT_CREATE_SUCCESS: 'PARTNER_EVENT_CREATE_SUCCESS',
  PARTNER_EVENT_CREATE_FAILURE: 'PARTNER_EVENT_CREATE_FAILURE',
  PARTNER_EVENT_CREATE_RESET: 'PARTNER_EVENT_CREATE_RESET',

  PARTNER_EVENT_UPDATE_START: 'PARTNER_EVENT_UPDATE_START',
  PARTNER_EVENT_UPDATE_SUCCESS: 'PARTNER_EVENT_UPDATE_SUCCESS',
  PARTNER_EVENT_UPDATE_FAILURE: 'PARTNER_EVENT_UPDATE_FAILURE',
  PARTNER_EVENT_UPDATE_RESET: 'PARTNER_EVENT_UPDATE_RESET',

  PARTNER_EVENT_DELETE_START: 'PARTNER_EVENT_DELETE_START',
  PARTNER_EVENT_DELETE_SUCCESS: 'PARTNER_EVENT_DELETE_SUCCESS',
  PARTNER_EVENT_DELETE_FAILURE: 'PARTNER_EVENT_DELETE_FAILURE',
  PARTNER_EVENT_DELETE_RESET: 'PARTNER_EVENT_DELETE_RESET',

  PARTNER_STORES_FETCH_START: 'PARTNER_STORES_FETCH_START',
  PARTNER_STORES_FETCH_SUCCESS: 'PARTNER_STORES_FETCH_SUCCESS',
  PARTNER_STORES_FETCH_FAILURE: 'PARTNER_STORES_FETCH_FAILURE',
  PARTNER_STORES_FETCH_RESET: 'PARTNER_STORES_FETCH_RESET',

  PARTNER_STORE_FETCH_START: 'PARTNER_STORE_FETCH_START',
  PARTNER_STORE_FETCH_SUCCESS: 'PARTNER_STORE_FETCH_SUCCESS',
  PARTNER_STORE_FETCH_FAILURE: 'PARTNER_STORE_FETCH_FAILURE',
  PARTNER_STORE_FETCH_RESET: 'PARTNER_STORE_FETCH_RESET',

  PARTNER_STORE_CREATE_START: 'PARTNER_STORE_CREATE_START',
  PARTNER_STORE_CREATE_SUCCESS: 'PARTNER_STORE_CREATE_SUCCESS',
  PARTNER_STORE_CREATE_FAILURE: 'PARTNER_STORE_CREATE_FAILURE',
  PARTNER_STORE_CREATE_RESET: 'PARTNER_STORE_CREATE_RESET',

  PARTNER_STORE_UPDATE_START: 'PARTNER_STORE_UPDATE_START',
  PARTNER_STORE_UPDATE_SUCCESS: 'PARTNER_STORE_UPDATE_SUCCESS',
  PARTNER_STORE_UPDATE_FAILURE: 'PARTNER_STORE_UPDATE_FAILURE',
  PARTNER_STORE_UPDATE_RESET: 'PARTNER_STORE_UPDATE_RESET',

  PARTNER_STORE_DELETE_START: 'PARTNER_STORE_DELETE_START',
  PARTNER_STORE_DELETE_SUCCESS: 'PARTNER_STORE_DELETE_SUCCESS',
  PARTNER_STORE_DELETE_FAILURE: 'PARTNER_STORE_DELETE_FAILURE',
  PARTNER_STORE_DELETE_RESET: 'PARTNER_STORE_DELETE_RESET',

  PARTNER_SPONSORS_FETCH_START: 'PARTNER_SPONSORS_FETCH_START',
  PARTNER_SPONSORS_FETCH_SUCCESS: 'PARTNER_SPONSORS_FETCH_SUCCESS',
  PARTNER_SPONSORS_FETCH_FAILURE: 'PARTNER_SPONSORS_FETCH_FAILURE',
  PARTNER_SPONSORS_FETCH_RESET: 'PARTNER_SPONSORS_FETCH_RESET',

  PARTNER_SPONSOR_FETCH_START: 'PARTNER_SPONSOR_FETCH_START',
  PARTNER_SPONSOR_FETCH_SUCCESS: 'PARTNER_SPONSOR_FETCH_SUCCESS',
  PARTNER_SPONSOR_FETCH_FAILURE: 'PARTNER_SPONSOR_FETCH_FAILURE',
  PARTNER_SPONSOR_FETCH_RESET: 'PARTNER_SPONSOR_FETCH_RESET',

  PARTNER_SPONSOR_CREATE_START: 'PARTNER_SPONSOR_CREATE_START',
  PARTNER_SPONSOR_CREATE_SUCCESS: 'PARTNER_SPONSOR_CREATE_SUCCESS',
  PARTNER_SPONSOR_CREATE_FAILURE: 'PARTNER_SPONSOR_CREATE_FAILURE',
  PARTNER_SPONSOR_CREATE_RESET: 'PARTNER_SPONSOR_CREATE_RESET',

  PARTNER_SPONSOR_UPDATE_START: 'PARTNER_SPONSOR_UPDATE_START',
  PARTNER_SPONSOR_UPDATE_SUCCESS: 'PARTNER_SPONSOR_UPDATE_SUCCESS',
  PARTNER_SPONSOR_UPDATE_FAILURE: 'PARTNER_SPONSOR_UPDATE_FAILURE',
  PARTNER_SPONSOR_UPDATE_RESET: 'PARTNER_SPONSOR_UPDATE_RESET',

  PARTNER_SPONSOR_DELETE_START: 'PARTNER_SPONSOR_DELETE_START',
  PARTNER_SPONSOR_DELETE_SUCCESS: 'PARTNER_SPONSOR_DELETE_SUCCESS',
  PARTNER_SPONSOR_DELETE_FAILURE: 'PARTNER_SPONSOR_DELETE_FAILURE',
  PARTNER_SPONSOR_DELETE_RESET: 'PARTNER_SPONSOR_DELETE_RESET',

  PARTNER_REWARDS_FETCH_START: 'PARTNER_REWARDS_FETCH_START',
  PARTNER_REWARDS_FETCH_SUCCESS: 'PARTNER_REWARDS_FETCH_SUCCESS',
  PARTNER_REWARDS_FETCH_FAILURE: 'PARTNER_REWARDS_FETCH_FAILURE',
  PARTNER_REWARDS_FETCH_RESET: 'PARTNER_REWARDS_FETCH_RESET',

  PARTNER_REWARD_FETCH_START: 'PARTNER_REWARD_FETCH_START',
  PARTNER_REWARD_FETCH_SUCCESS: 'PARTNER_REWARD_FETCH_SUCCESS',
  PARTNER_REWARD_FETCH_FAILURE: 'PARTNER_REWARD_FETCH_FAILURE',
  PARTNER_REWARD_FETCH_RESET: 'PARTNER_REWARD_FETCH_RESET',

  PARTNER_REWARD_CREATE_START: 'PARTNER_REWARD_CREATE_START',
  PARTNER_REWARD_CREATE_SUCCESS: 'PARTNER_REWARD_CREATE_SUCCESS',
  PARTNER_REWARD_CREATE_FAILURE: 'PARTNER_REWARD_CREATE_FAILURE',
  PARTNER_REWARD_CREATE_RESET: 'PARTNER_REWARD_CREATE_RESET',

  PARTNER_REWARD_UPDATE_START: 'PARTNER_REWARD_UPDATE_START',
  PARTNER_REWARD_UPDATE_SUCCESS: 'PARTNER_REWARD_UPDATE_SUCCESS',
  PARTNER_REWARD_UPDATE_FAILURE: 'PARTNER_REWARD_UPDATE_FAILURE',
  PARTNER_REWARD_UPDATE_RESET: 'PARTNER_REWARD_UPDATE_RESET',

  PARTNER_REWARD_DELETE_START: 'PARTNER_REWARD_DELETE_START',
  PARTNER_REWARD_DELETE_SUCCESS: 'PARTNER_REWARD_DELETE_SUCCESS',
  PARTNER_REWARD_DELETE_FAILURE: 'PARTNER_REWARD_DELETE_FAILURE',
  PARTNER_REWARD_DELETE_RESET: 'PARTNER_REWARD_DELETE_RESET',

  PARTNER_BOOSTERS_FETCH_START: 'PARTNER_BOOSTERS_FETCH_START',
  PARTNER_BOOSTERS_FETCH_SUCCESS: 'PARTNER_BOOSTERS_FETCH_SUCCESS',
  PARTNER_BOOSTERS_FETCH_FAILURE: 'PARTNER_BOOSTERS_FETCH_FAILURE',
  PARTNER_BOOSTERS_FETCH_RESET: 'PARTNER_BOOSTERS_FETCH_RESET',

  PARTNER_BOOSTER_FETCH_START: 'PARTNER_BOOSTER_FETCH_START',
  PARTNER_BOOSTER_FETCH_SUCCESS: 'PARTNER_BOOSTER_FETCH_SUCCESS',
  PARTNER_BOOSTER_FETCH_FAILURE: 'PARTNER_BOOSTER_FETCH_FAILURE',
  PARTNER_BOOSTER_FETCH_RESET: 'PARTNER_BOOSTER_FETCH_RESET',

  PARTNER_BOOSTER_CREATE_START: 'PARTNER_BOOSTER_CREATE_START',
  PARTNER_BOOSTER_CREATE_SUCCESS: 'PARTNER_BOOSTER_CREATE_SUCCESS',
  PARTNER_BOOSTER_CREATE_FAILURE: 'PARTNER_BOOSTER_CREATE_FAILURE',
  PARTNER_BOOSTER_CREATE_RESET: 'PARTNER_BOOSTER_CREATE_RESET',

  PARTNER_BOOSTER_UPDATE_START: 'PARTNER_BOOSTER_UPDATE_START',
  PARTNER_BOOSTER_UPDATE_SUCCESS: 'PARTNER_BOOSTER_UPDATE_SUCCESS',
  PARTNER_BOOSTER_UPDATE_FAILURE: 'PARTNER_BOOSTER_UPDATE_FAILURE',
  PARTNER_BOOSTER_UPDATE_RESET: 'PARTNER_BOOSTER_UPDATE_RESET',

  PARTNER_BOOSTER_DELETE_START: 'PARTNER_BOOSTER_DELETE_START',
  PARTNER_BOOSTER_DELETE_SUCCESS: 'PARTNER_BOOSTER_DELETE_SUCCESS',
  PARTNER_BOOSTER_DELETE_FAILURE: 'PARTNER_BOOSTER_DELETE_FAILURE',
  PARTNER_BOOSTER_DELETE_RESET: 'PARTNER_BOOSTER_DELETE_RESET',

  PARTNER_BOOSTERS_TEMPLATES_FETCH_START: 'PARTNER_BOOSTERS_TEMPLATES_FETCH_START',
  PARTNER_BOOSTERS_TEMPLATES_FETCH_SUCCESS: 'PARTNER_BOOSTERS_TEMPLATES_FETCH_SUCCESS',
  PARTNER_BOOSTERS_TEMPLATES_FETCH_FAILURE: 'PARTNER_BOOSTERS_TEMPLATES_FETCH_FAILURE',
  PARTNER_BOOSTERS_TEMPLATES_FETCH_RESET: 'PARTNER_BOOSTERS_TEMPLATES_FETCH_RESET',

  PARTNER_BOOSTER_TEMPLATE_FETCH_START: 'PARTNER_BOOSTER_TEMPLATE_FETCH_START',
  PARTNER_BOOSTER_TEMPLATE_FETCH_SUCCESS: 'PARTNER_BOOSTER_TEMPLATE_FETCH_SUCCESS',
  PARTNER_BOOSTER_TEMPLATE_FETCH_FAILURE: 'PARTNER_BOOSTER_TEMPLATE_FETCH_FAILURE',
  PARTNER_BOOSTER_TEMPLATE_FETCH_RESET: 'PARTNER_BOOSTER_TEMPLATE_FETCH_RESET',

  PARTNER_BOOSTER_TEMPLATE_CREATE_START: 'PARTNER_BOOSTER_TEMPLATE_CREATE_START',
  PARTNER_BOOSTER_TEMPLATE_CREATE_SUCCESS: 'PARTNER_BOOSTER_TEMPLATE_CREATE_SUCCESS',
  PARTNER_BOOSTER_TEMPLATE_CREATE_FAILURE: 'PARTNER_BOOSTER_TEMPLATE_CREATE_FAILURE',
  PARTNER_BOOSTER_TEMPLATE_CREATE_RESET: 'PARTNER_BOOSTER_TEMPLATE_CREATE_RESET',

  PARTNER_BOOSTER_TEMPLATE_UPDATE_START: 'PARTNER_BOOSTER_TEMPLATE_UPDATE_START',
  PARTNER_BOOSTER_TEMPLATE_UPDATE_SUCCESS: 'PARTNER_BOOSTER_TEMPLATE_UPDATE_SUCCESS',
  PARTNER_BOOSTER_TEMPLATE_UPDATE_FAILURE: 'PARTNER_BOOSTER_TEMPLATE_UPDATE_FAILURE',
  PARTNER_BOOSTER_TEMPLATE_UPDATE_RESET: 'PARTNER_BOOSTER_TEMPLATE_UPDATE_RESET',

  PARTNER_BOOSTER_TEMPLATE_DELETE_START: 'PARTNER_BOOSTER_TEMPLATE_DELETE_START',
  PARTNER_BOOSTER_TEMPLATE_DELETE_SUCCESS: 'PARTNER_BOOSTER_TEMPLATE_DELETE_SUCCESS',
  PARTNER_BOOSTER_TEMPLATE_DELETE_FAILURE: 'PARTNER_BOOSTER_TEMPLATE_DELETE_FAILURE',
  PARTNER_BOOSTER_TEMPLATE_DELETE_RESET: 'PARTNER_BOOSTER_TEMPLATE_DELETE_RESET',

  PARTNER_FETCH_LEADERBOARD_START: 'PARTNER_FETCH_LEADERBOAR_START',
  PARTNER_FETCH_LEADERBOARD_SUCCESS: 'PARTNER_FETCH_LEADERBOAR_SUCCESS',
  PARTNER_FETCH_LEADERBOARD_FAILURE: 'PARTNER_FETCH_LEADERBOAR_FAILURE',
  PARTNER_FETCH_LEADERBOARD_RESET: 'PARTNER_FETCH_LEADERBOAR_RESET',

  PARTNER_CHECKINS_FETCH_START: 'PARTNER_CHECKINS_FETCH_START',
  PARTNER_CHECKINS_FETCH_SUCCESS: 'PARTNER_CHECKINS_FETCH_SUCCESS',
  PARTNER_CHECKINS_FETCH_FAILURE: 'PARTNER_CHECKINS_FETCH_FAILURE',
  PARTNER_CHECKINS_FETCH_RESET: 'PARTNER_CHECKINS_FETCH_RESET',

  PARTNER_SELL_TAGS_START: 'PARTNER_SELL_TAGS_START',
  PARTNER_SELL_TAGS_SUCCESS: 'PARTNER_SELL_TAGS_SUCCESS',
  PARTNER_SELL_TAGS_FAILURE: 'PARTNER_SELL_TAGS_FAILURE',
  PARTNER_SELL_TAGS_RESET: 'PARTNER_SELL_TAGS_RESET',
};

export const createPartner = partner => {
  return async dispatch => {
    try {
      if (partner.file && partner.file.length > 0) {
        const file = partner.file[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.image = imageData.access_url;
        delete partner.file;
      }

      if (partner.bg_file && partner.bg_file.length > 0) {
        const file = partner.bg_file[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.bg_image = imageData.access_url;
        delete partner.bg_file;
      }

      if (partner.landing_page_file && partner.landing_page_file.length > 0) {
        const file = partner.landing_page_file[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.partner_page_attributes.landing_page_image = imageData.access_url;
        delete partner.landing_page_file;
      }

      if (partner.landing_page_file2 && partner.landing_page_file2.length > 0) {
        const file = partner.landing_page_file2[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.partner_page_attributes.landing_page_image2 = imageData.access_url;
        delete partner.landing_page_file2;
      }

      if (partner.landing_page_file3 && partner.landing_page_file3.length > 0) {
        const file = partner.landing_page_file3[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.partner_page_attributes.landing_page_image3 = imageData.access_url;
        delete partner.landing_page_file3;
      }

      if (partner.thumbnail_file1 && partner.thumbnail_file1.length > 0) {
        const file = partner.thumbnail_file1[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.partner_page_attributes.thumbnail_image1 = imageData.access_url;
        delete partner.thumbnail_file1;
      }
      if (partner.thumbnail_file2 && partner.thumbnail_file2.length > 0) {
        const file = partner.thumbnail_file2[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.partner_page_attributes.thumbnail_image2 = imageData.access_url;
        delete partner.thumbnail_file2;
      }
      if (partner.thumbnail_file3 && partner.thumbnail_file3.length > 0) {
        const file = partner.thumbnail_file3[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.partner_page_attributes.thumbnail_image3 = imageData.access_url;
        delete partner.thumbnail_file3;
      }
      dispatch({ type: TYPES.PARTNER_CREATE_START, payload: partner });
      const data = await PartnersAPI.CreatePartner(partner);
      dispatch({ type: TYPES.PARTNER_CREATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_CREATE_FAILURE, payload: e });
    }
  };
};

export const updatePartner = partner => {
  return async dispatch => {
    try {
      if (partner.file && partner.file.length > 0) {
        const file = partner.file[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.image = imageData.access_url;
        delete partner.file;
      }

      if (partner.bg_file && partner.bg_file.length > 0) {
        const file = partner.bg_file[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.bg_image = imageData.access_url;
        delete partner.bg_file;
      }

      if (partner.landing_page_file && partner.landing_page_file.length > 0) {
        const file = partner.landing_page_file[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.partner_page_attributes.landing_page_image = imageData.access_url;
        delete partner.landing_page_file;
      }

      if (partner.landing_page_file2 && partner.landing_page_file2.length > 0) {
        const file = partner.landing_page_file2[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.partner_page_attributes.landing_page_image2 = imageData.access_url;
        delete partner.landing_page_file2;
      }

      if (partner.landing_page_file3 && partner.landing_page_file3.length > 0) {
        const file = partner.landing_page_file3[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.partner_page_attributes.landing_page_image3 = imageData.access_url;
        delete partner.landing_page_file3;
      }

      if (partner.thumbnail_file1 && partner.thumbnail_file1.length > 0) {
        const file = partner.thumbnail_file1[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.partner_page_attributes.thumbnail_image1 = imageData.access_url;
        delete partner.thumbnail_file1;
      }
      if (partner.thumbnail_file2 && partner.thumbnail_file2.length > 0) {
        const file = partner.thumbnail_file2[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.partner_page_attributes.thumbnail_image2 = imageData.access_url;
        delete partner.thumbnail_file2;
      }
      if (partner.thumbnail_file3 && partner.thumbnail_file3.length > 0) {
        const file = partner.thumbnail_file3[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partner.partner_page_attributes.thumbnail_image3 = imageData.access_url;
        delete partner.thumbnail_file3;
      }

      dispatch({ type: TYPES.PARTNER_UPDATE_START, payload: partner });
      const data = await PartnersAPI.UpdatePartner(partner);
      dispatch({ type: TYPES.PARTNER_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_UPDATE_FAILURE, payload: e });
    }
  };
};

export const deletePartner = partner => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_DELETE_START, payload: partner });
      const data = await PartnersAPI.DeletePartner(partner);
      dispatch({ type: TYPES.PARTNER_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_DELETE_FAILURE, payload: e });
    }
  };
};

export const fetchPartner = id => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_FETCH_START, payload: id });
      const data = await PartnersAPI.FetchPartner(id);
      dispatch({ type: TYPES.PARTNER_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchReferralCode = refCode => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.REFERRAL_CODE_FETCH_START, payload: refCode });
      const data = await PartnersAPI.FetchReferralCode(refCode);
      dispatch({ type: TYPES.REFERRAL_CODE_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.REFERRAL_CODE_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchPartners = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNERS_FETCH_START, payload: params });
      const data = await PartnersAPI.FetchPartners(params);
      dispatch({ type: TYPES.PARTNERS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNERS_FETCH_FAILURE, payload: e });
    }
  };
};

export const resetPartnersList = () => {
  return {
    type: TYPES.PARTNERS_FETCH_RESET,
  };
};

export const resetActivePartner = () => {
  return {
    type: TYPES.PARTNER_FETCH_RESET,
  };
};

export const resetActivePartnerErrorAndMode = () => {
  return {
    type: TYPES.PARTNER_USER_CREATE_RESET,
  };
};

export const setActivePartner = gender => {
  return {
    type: TYPES.PARTNER_FETCH_SUCCESS,
    payload: gender,
  };
};

export const fetchPartnerUsers = (id, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_USERS_FETCH_START, payload: params });
      const data = await PartnersAPI.FetchPartnerUsers(id, params);
      dispatch({ type: TYPES.PARTNER_USERS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_USERS_FETCH_FAILURE, payload: e });
    }
  };
};

export const addPartnerUser = (partnerId, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_USER_CREATE_START, payload: params });
      const data = await PartnersAPI.AddPartnerUser(partnerId, params);
      dispatch({ type: TYPES.PARTNER_USER_CREATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_USER_CREATE_FAILURE, payload: e });
    }
  };
};

export const deletePartnerUser = (partnerId, userId) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_USER_DELETE_START, payload: { partnerId, userId } });
      const data = await PartnersAPI.DeletePartnerUser(partnerId, userId);
      dispatch({ type: TYPES.PARTNER_USER_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_USER_DELETE_FAILURE, payload: e });
    }
  };
};

export const fetchTagSeries = (partnerId, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_TAG_SERIES_FETCH_START, payload: params });
      const data = await PartnersAPI.FetchTagSeries(partnerId, params);
      dispatch({ type: TYPES.PARTNER_TAG_SERIES_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_TAG_SERIES_FETCH_FAILURE, payload: e });
    }
  };
};

export const insertTagSeries = (partnerId, tagSeries) => {
  return async dispatch => {
    try {
      if (tagSeries.file && tagSeries.file.length > 0) {
        const file = tagSeries.file[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        tagSeries.tag_image = imageData.access_url;
        delete tagSeries.file;
      }

      if (tagSeries.bg_file && tagSeries.bg_file.length > 0) {
        const file = tagSeries.bg_file[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        tagSeries.tag_series_bg_image = imageData.access_url;
        delete tagSeries.bg_file;
      }
      dispatch({ type: TYPES.PARTNER_TAG_SERIES_CREATE_START, payload: { partnerId, tagSeries } });
      const data = await PartnersAPI.InsertTagSeries(partnerId, tagSeries);
      dispatch({ type: TYPES.PARTNER_TAG_SERIES_CREATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_TAG_SERIES_CREATE_FAILURE, payload: e });
    }
  };
};

export const updatePartnerTagSeries = (partnerId, tagSeries) => {
  return async dispatch => {
    try {
      if (tagSeries.file && tagSeries.file.length > 0) {
        const file = tagSeries.file[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        tagSeries.tag_image = imageData.access_url;
        delete tagSeries.file;
      }

      if (tagSeries.bg_file && tagSeries.bg_file.length > 0) {
        const file = tagSeries.bg_file[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        tagSeries.tag_series_bg_image = imageData.access_url;
        delete tagSeries.bg_file;
      }

      dispatch({ type: TYPES.PARTNER_TAG_SERIES_UPDATE_START, payload: { partnerId, tagSeries } });
      const data = await PartnersAPI.UpdatePartnerTagSeries(partnerId, tagSeries);
      dispatch({ type: TYPES.PARTNER_TAG_SERIES_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_TAG_SERIES_UPDATE_FAILURE, payload: e });
    }
  };
};

export const deletePartnerTagSeries = (partnerId, tagSeriesId) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.PARTNER_TAG_SERIES_DELETE_START,
        payload: { partnerId, tagSeriesId },
      });
      const data = await PartnersAPI.DeletePartnerTagSeries(partnerId, tagSeriesId);
      dispatch({ type: TYPES.PARTNER_TAG_SERIES_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_TAG_SERIES_DELETE_FAILURE, payload: e });
    }
  };
};

export const generateTags = (partnerId, tagSeriesId) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.PARTNER_TAG_SERIES_UPDATE_START,
        payload: { partnerId, tagSeriesId },
      });
      const data = await PartnersAPI.GenerateTags(partnerId, tagSeriesId);
      dispatch({ type: TYPES.PARTNER_TAG_SERIES_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_TAG_SERIES_UPDATE_FAILURE, payload: e });
    }
  };
};

export const setActivePartnerTagSerie = tagSerie => {
  return {
    type: TYPES.PARTNER_TAG_SERIE_FETCH_SUCCESS,
    payload: tagSerie,
  };
};

export const resetActivePartnerTagSerie = () => {
  return {
    type: TYPES.PARTNER_TAG_SERIE_FETCH_RESET,
  };
};

export const fetchTag = id => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_TAG_FETCH_START, payload: id });
      const data = await PartnersAPI.FetchTag(id);
      dispatch({ type: TYPES.PARTNER_TAG_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_TAG_FETCH_FAILURE, payload: e });
    }
  };
};

export const setActiveTag = tag => {
  return {
    type: TYPES.PARTNER_TAG_FETCH_SUCCESS,
    payload: tag,
  };
};

export const resetActiveTag = () => {
  return {
    type: TYPES.PARTNER_TAG_FETCH_RESET,
  };
};

export const fetchTags = (partnerId, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_TAGS_FETCH_START, payload: params });
      const data = await PartnersAPI.FetchTags(partnerId, params);
      dispatch({ type: TYPES.PARTNER_TAGS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_TAGS_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchTagsPrint = (partnerId, params) => {
  return async dispatch => {
    try {
      params.per_page = 10000;
      dispatch({ type: TYPES.PARTNER_TAGS_PRINT_FETCH_START, payload: params });
      const data = await PartnersAPI.FetchTags(partnerId, params);
      dispatch({ type: TYPES.PARTNER_TAGS_PRINT_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_TAGS_PRINT_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchLabelsPrint = (partnerId, params) => {
  return async dispatch => {
    try {
      params.per_page = 100;
      dispatch({ type: TYPES.SHIPPING_LABELS_PRINT_FETCH_START, payload: params });
      const data = await PartnersAPI.FetchPrintLabels(partnerId, params);
      dispatch({ type: TYPES.SHIPPING_LABELS_PRINT_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.SHIPPING_LABELS_PRINT_FETCH_FAILURE, payload: e });
    }
  };
};

export const resetTagsPrintList = () => {
  return {
    type: TYPES.PARTNER_TAGS_PRINT_FETCH_RESET,
  };
};

export const resetLabelsPrintList = () => {
  return {
    type: TYPES.SHIPPING_LABELS_PRINT_FETCH_RESET,
  };
};

export const fetchPartnerStores = (partnerId, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_STORES_FETCH_START, payload: params });
      const data = await PartnersAPI.FetchPartnerStores(partnerId, params);
      dispatch({ type: TYPES.PARTNER_STORES_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_STORES_FETCH_FAILURE, payload: e });
    }
  };
};

export const insertPartnerStore = (partnerId, partnerStore) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_STORE_CREATE_START, payload: { partnerId, partnerStore } });
      const data = await PartnersAPI.InsertPartnerStore(partnerId, partnerStore);
      dispatch({ type: TYPES.PARTNER_STORE_CREATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_STORE_CREATE_FAILURE, payload: e });
    }
  };
};

export const updatePartnerStore = (partnerId, partnerStore) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_STORE_UPDATE_START, payload: { partnerId, partnerStore } });

      const data = await PartnersAPI.UpdatePartnerStore(partnerId, partnerStore);
      dispatch({ type: TYPES.PARTNER_STORE_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_STORE_UPDATE_FAILURE, payload: e });
    }
  };
};

export const deletePartnerStore = (partnerId, partnerStoreID) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.PARTNER_STORE_DELETE_START,
        payload: { partnerId, partnerStoreID },
      });
      const data = await PartnersAPI.DeletePartnerStore(partnerId, partnerStoreID);
      dispatch({ type: TYPES.PARTNER_STORE_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_STORE_DELETE_FAILURE, payload: e });
    }
  };
};

export const setActivePartnerStore = partnerStore => {
  return {
    type: TYPES.PARTNER_STORE_FETCH_SUCCESS,
    payload: partnerStore,
  };
};

export const resetActivePartnerStore = () => {
  return {
    type: TYPES.PARTNER_STORE_FETCH_RESET,
  };
};

export const fetchPartnerSponsors = (partnerId, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_SPONSORS_FETCH_START, payload: params });
      const data = await PartnersAPI.FetchPartnerSponsors(partnerId, params);
      dispatch({ type: TYPES.PARTNER_SPONSORS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_SPONSORS_FETCH_FAILURE, payload: e });
    }
  };
};

export const insertPartnerSponsor = (partnerId, partnerSponsor) => {
  return async dispatch => {
    try {
      if (partnerSponsor.file && partnerSponsor.file.length > 0) {
        const file = partnerSponsor.file[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partnerSponsor.image = imageData.access_url;
        delete partnerSponsor.file;
      }

      if (partnerSponsor.booster_file && partnerSponsor.booster_file.name) {
        partnerSponsor.boosters_image = await uploadImageByField(partnerSponsor.booster_file);
      }
      dispatch({
        type: TYPES.PARTNER_SPONSOR_CREATE_START,
        payload: { partnerId, partnerSponsor },
      });
      const data = await PartnersAPI.InsertPartnerSponsor(partnerId, partnerSponsor);
      dispatch({ type: TYPES.PARTNER_SPONSOR_CREATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_SPONSOR_CREATE_FAILURE, payload: e });
    }
  };
};

export const updatePartnerSponsor = (partnerId, partnerSponsor) => {
  return async dispatch => {
    try {
      if (partnerSponsor.file && partnerSponsor.file.length > 0) {
        const file = partnerSponsor.file[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partnerSponsor.image = imageData.access_url;
        delete partnerSponsor.file;
      }

      if (partnerSponsor.booster_file && partnerSponsor.booster_file.name) {
        partnerSponsor.boosters_image = await uploadImageByField(partnerSponsor.booster_file);
      }
      dispatch({
        type: TYPES.PARTNER_SPONSOR_UPDATE_START,
        payload: { partnerId, partnerSponsor },
      });

      const data = await PartnersAPI.UpdatePartnerSponsor(partnerId, partnerSponsor);
      dispatch({ type: TYPES.PARTNER_SPONSOR_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_SPONSOR_UPDATE_FAILURE, payload: e });
    }
  };
};

export const deletePartnerSponsor = (partnerId, partnerSponsorID) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.PARTNER_SPONSOR_DELETE_START,
        payload: { partnerId, partnerSponsorID },
      });
      const data = await PartnersAPI.DeletePartnerSponsor(partnerId, partnerSponsorID);
      dispatch({ type: TYPES.PARTNER_SPONSOR_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_SPONSOR_DELETE_FAILURE, payload: e });
    }
  };
};

export const setActivePartnerSponsor = partnerSponsor => {
  return {
    type: TYPES.PARTNER_SPONSOR_FETCH_SUCCESS,
    payload: partnerSponsor,
  };
};

export const resetActivePartnerSponsor = () => {
  return {
    type: TYPES.PARTNER_SPONSOR_FETCH_RESET,
  };
};

export const fetchPartnerRewards = (partnerId, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_REWARDS_FETCH_START, payload: params });
      const data = await PartnersAPI.FetchPartnerRewards(partnerId, params);
      dispatch({ type: TYPES.PARTNER_REWARDS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_REWARDS_FETCH_FAILURE, payload: e });
    }
  };
};

export const insertPartnerReward = (partnerId, partnerReward) => {
  return async dispatch => {
    try {
      if (partnerReward.file && partnerReward.file.length > 0) {
        const file = partnerReward.file[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partnerReward.image = imageData.access_url;
        delete partnerReward.file;
      }

      dispatch({
        type: TYPES.PARTNER_REWARD_CREATE_START,
        payload: { partnerId, partnerReward },
      });
      const data = await PartnersAPI.InsertPartnerReward(partnerId, partnerReward);
      dispatch({ type: TYPES.PARTNER_REWARD_CREATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_REWARD_CREATE_FAILURE, payload: e });
    }
  };
};

export const updatePartnerReward = (partnerId, partnerReward) => {
  return async dispatch => {
    try {
      if (partnerReward.file && partnerReward.file.length > 0) {
        const file = partnerReward.file[0];
        const params = {
          filename: file.name,
          'content-type': file.type,
          kind: 'images',
          size: file.size,
        };

        const imageData = await UploadAPI.GenerateUploadURL(params);
        await UploadAPI.UploadFile(file, imageData.url);
        partnerReward.image = imageData.access_url;
        delete partnerReward.file;
      }

      dispatch({
        type: TYPES.PARTNER_REWARD_UPDATE_START,
        payload: { partnerId, partnerReward },
      });

      const data = await PartnersAPI.UpdatePartnerReward(partnerId, partnerReward);
      dispatch({ type: TYPES.PARTNER_REWARD_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_REWARD_UPDATE_FAILURE, payload: e });
    }
  };
};

export const deletePartnerReward = (partnerId, partnerRewardID) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.PARTNER_REWARD_DELETE_START,
        payload: { partnerId, partnerRewardID },
      });
      const data = await PartnersAPI.DeletePartnerReward(partnerId, partnerRewardID);
      dispatch({ type: TYPES.PARTNER_REWARD_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_REWARD_DELETE_FAILURE, payload: e });
    }
  };
};

export const setActivePartnerReward = partnerReward => {
  return {
    type: TYPES.PARTNER_REWARD_FETCH_SUCCESS,
    payload: partnerReward,
  };
};

export const resetActivePartnerReward = () => {
  return {
    type: TYPES.PARTNER_REWARD_FETCH_RESET,
  };
};

export const fetchEvents = (partnerId, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_EVENTS_FETCH_START, payload: params });
      const data = await PartnersAPI.FetchEvents(partnerId, params);
      dispatch({ type: TYPES.PARTNER_EVENTS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_EVENTS_FETCH_FAILURE, payload: e });
    }
  };
};

export const insertEvent = (partnerId, event) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_EVENT_CREATE_START, payload: { partnerId, event } });

      if (event.image1 && event.image1.name) {
        event.image1 = await uploadImageByField(event.image1);
      }

      if (event.image2 && event.image2.name) {
        event.image2 = await uploadImageByField(event.image2);
      }

      if (event.image3 && event.image3.name) {
        event.image3 = await uploadImageByField(event.image3);
      }

      if (event.image4 && event.image4.name) {
        event.image4 = await uploadImageByField(event.image4);
      }

      const data = await PartnersAPI.InsertEvent(partnerId, event);
      dispatch({ type: TYPES.PARTNER_EVENT_CREATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_EVENT_CREATE_FAILURE, payload: e });
    }
  };
};

export const insertBooster = (partnerId, booster) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_BOOSTER_CREATE_START, payload: { partnerId, booster } });
      if (booster.image1 && booster.image1.name) {
        booster.sponsor_image = await uploadImageByField(booster.image1);
      }

      const data = await PartnersAPI.InsertBooster(partnerId, booster);
      dispatch({ type: TYPES.PARTNER_BOOSTER_CREATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_BOOSTER_CREATE_FAILURE, payload: e });
    }
  };
};

export const updateBooster = (partnerId, booster) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_BOOSTER_UPDATE_START, payload: { partnerId, booster } });

      if (booster.image1 && booster.image1.name) {
        booster.sponsor_image = await uploadImageByField(booster.image1);
      }

      const data = await PartnersAPI.UpdateBooster(partnerId, booster);
      dispatch({ type: TYPES.PARTNER_BOOSTER_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_BOOSTER_UPDATE_FAILURE, payload: e });
    }
  };
};

export const deleteBooster = (partnerId, boosterId) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.PARTNER_BOOSTER_DELETE_START,
        payload: { partnerId, boosterId },
      });
      const data = await PartnersAPI.DeleteBooster(partnerId, boosterId);
      dispatch({ type: TYPES.PARTNER_BOOSTER_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_BOOSTER_DELETE_FAILURE, payload: e });
    }
  };
};

export const fetchBoosters = (partnerId, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_BOOSTERS_FETCH_START, payload: params });
      const data = await PartnersAPI.FetchBoosters(partnerId, params);
      dispatch({ type: TYPES.PARTNER_BOOSTERS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_BOOSTERS_FETCH_FAILURE, payload: e });
    }
  };
};

export const setActiveBooster = booster => {
  return {
    type: TYPES.PARTNER_BOOSTER_FETCH_SUCCESS,
    payload: booster,
  };
};

export const resetActiveBooster = () => {
  return {
    type: TYPES.PARTNER_BOOSTER_FETCH_RESET,
  };
};

export const updateEvent = (partnerId, event) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_EVENT_UPDATE_START, payload: { partnerId, event } });

      if (event.image1.name) {
        event.image1 = await uploadImageByField(event.image1);
      }

      if (event.image2.name) {
        event.image2 = await uploadImageByField(event.image2);
      }

      if (event.image3.name) {
        event.image3 = await uploadImageByField(event.image3);
      }

      if (event.image4.name) {
        event.image4 = await uploadImageByField(event.image4);
      }

      const data = await PartnersAPI.UpdateEvent(partnerId, event);
      dispatch({ type: TYPES.PARTNER_EVENT_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_EVENT_UPDATE_FAILURE, payload: e });
    }
  };
};

const uploadImageByField = async image => {
  const params = {
    filename: image.name,
    'content-type': image.type,
    kind: 'images',
    size: image.size,
  };

  const imageData = await UploadAPI.GenerateUploadURL(params);
  await UploadAPI.UploadFile(image, imageData.url);
  return imageData.access_url;
};

export const deleteEvent = (partnerId, eventId) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.PARTNER_EVENT_DELETE_START,
        payload: { partnerId, eventId },
      });
      const data = await PartnersAPI.DeleteEvent(partnerId, eventId);
      dispatch({ type: TYPES.PARTNER_EVENT_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_EVENT_DELETE_FAILURE, payload: e });
    }
  };
};

export const setActiveEvent = event => {
  return {
    type: TYPES.PARTNER_EVENT_FETCH_SUCCESS,
    payload: event,
  };
};

export const resetActiveEvent = () => {
  return {
    type: TYPES.PARTNER_EVENT_FETCH_RESET,
  };
};

export const fetchLeaderboard = (partnerId, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_FETCH_LEADERBOARD_START, payload: params });
      const data = await PartnersAPI.FetchLeaderboard(partnerId);
      dispatch({ type: TYPES.PARTNER_FETCH_LEADERBOARD_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_FETCH_LEADERBOARD_FAILURE, payload: e });
    }
  };
};

export const tagChangePin = tag => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_TAG_UPDATE_START, payload: { tag } });
      const data = await PartnersAPI.TagChangePin(tag);
      dispatch({ type: TYPES.PARTNER_TAG_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_TAG_UPDATE_FAILURE, payload: e });
    }
  };
};

export const fetchCheckins = (partnerId, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_CHECKINS_FETCH_START, payload: params });
      const data = await PartnersAPI.FetchCheckins(partnerId, params);
      dispatch({ type: TYPES.PARTNER_CHECKINS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_CHECKINS_FETCH_FAILURE, payload: e });
    }
  };
};

export const sellTags = (partnerId, tagSeriesId, quantity) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_SELL_TAGS_START, payload: quantity });
      const data = await PartnersAPI.SellTags(partnerId, tagSeriesId, quantity);
      dispatch({ type: TYPES.PARTNER_SELL_TAGS_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_SELL_TAGS_FAILURE, payload: e });
    }
  };
};

export const insertBoosterTemplate = (partnerId, template) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.PARTNER_BOOSTER_TEMPLATE_CREATE_START,
        payload: { partnerId, template },
      });

      const data = await PartnersAPI.InsertBoosterTemplate(partnerId, template);
      dispatch({ type: TYPES.PARTNER_BOOSTER_TEMPLATE_CREATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_BOOSTER_TEMPLATE_CREATE_FAILURE, payload: e });
    }
  };
};

export const updateBoosterTemplate = (partnerId, template) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.PARTNER_BOOSTER_TEMPLATE_UPDATE_START,
        payload: { partnerId, template },
      });

      const data = await PartnersAPI.UpdateBoosterTemplate(partnerId, template);
      dispatch({ type: TYPES.PARTNER_BOOSTER_TEMPLATE_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_BOOSTER_TEMPLATE_UPDATE_FAILURE, payload: e });
    }
  };
};

export const deleteBoosterTemplate = (partnerId, boosterId) => {
  return async dispatch => {
    try {
      dispatch({
        type: TYPES.PARTNER_BOOSTER_TEMPLATE_DELETE_START,
        payload: { partnerId, boosterId },
      });
      const data = await PartnersAPI.DeleteBoosterTemplate(partnerId, boosterId);
      dispatch({ type: TYPES.PARTNER_BOOSTER_TEMPLATE_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_BOOSTER_TEMPLATE_DELETE_FAILURE, payload: e });
    }
  };
};

export const fetchBoostersTemplates = (partnerId, params) => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.PARTNER_BOOSTERS_TEMPLATES_FETCH_START, payload: params });
      const data = await PartnersAPI.FetchBoostersTemplates(partnerId, params);
      dispatch({ type: TYPES.PARTNER_BOOSTERS_TEMPLATES_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.PARTNER_BOOSTERS_TEMPLATES_FETCH_FAILURE, payload: e });
    }
  };
};

export const setActiveBoosterTemplate = template => {
  return {
    type: TYPES.PARTNER_BOOSTER_TEMPLATE_FETCH_SUCCESS,
    payload: template,
  };
};

export const resetActiveBoosterTemplate = () => {
  return {
    type: TYPES.PARTNER_BOOSTER_TEMPLATE_FETCH_RESET,
  };
};
