import { LookupActions } from '../actions';

const INITIAL_STATE = {
  gendersList: { data: [], params: {}, pager: null, error: null, loading: false },
  rootCategoriesList: { data: [], params: {}, pager: null, error: null, loading: false },
  categoriesList: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerRolesList: { data: [], params: {}, pager: null, error: null, loading: false },
};

export const lookups = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //================================== GENDERS FETCH START ==================================//
    case LookupActions.TYPES.GENDERS_FETCH_START:
      return {
        ...state,
        gendersList: {
          ...state.gendersList,
          params: action.payload,
          error: null,
          loading: true,
        },
      };
    case LookupActions.TYPES.GENDERS_FETCH_SUCCESS:
      return {
        ...state,
        gendersList: {
          ...state.gendersList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case LookupActions.TYPES.GENDERS_FETCH_FAILURE:
      return {
        ...state,
        gendersList: {
          ...state.gendersList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    //==================================  GENDERS FETCH END  ==================================//
    //================================== ROOT CATEGORIES FETCH START ==================================//
    case LookupActions.TYPES.ROOT_CATEGORIES_FETCH_START:
      return {
        ...state,
        rootCategoriesList: {
          ...state.rootCategoriesList,
          params: action.payload,
          error: null,
          loading: true,
        },
      };
    case LookupActions.TYPES.ROOT_CATEGORIES_FETCH_SUCCESS:
      return {
        ...state,
        rootCategoriesList: {
          ...state.rootCategoriesList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case LookupActions.TYPES.ROOT_CATEGORIES_FETCH_FAILURE:
      return {
        ...state,
        rootCategoriesList: {
          ...state.rootCategoriesList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    //==================================  ROOT CATEGORIES FETCH END  ==================================//
    //================================== CATEGORIES FETCH START ==================================//
    case LookupActions.TYPES.CATEGORIES_FETCH_START:
      return {
        ...state,
        categoriesList: {
          ...state.categoriesList,
          params: action.payload,
          error: null,
          loading: true,
        },
      };
    case LookupActions.TYPES.CATEGORIES_FETCH_SUCCESS:
      return {
        ...state,
        categoriesList: {
          ...state.categoriesList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case LookupActions.TYPES.CATEGORIES_FETCH_FAILURE:
      return {
        ...state,
        rcategoriesList: {
          ...state.categoriesList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    //==================================  CATEGORIES FETCH END  ==================================//
    //================================== GENDERS FETCH START ==================================//
    case LookupActions.TYPES.PARTNER_ROLES_FETCH_START:
      return {
        ...state,
        partnerRolesList: {
          ...state.partnerRolesList,
          params: action.payload,
          error: null,
          loading: true,
        },
      };
    case LookupActions.TYPES.PARTNER_ROLES_FETCH_SUCCESS:
      return {
        ...state,
        partnerRolesList: {
          ...state.partnerRolesList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case LookupActions.TYPES.PARTNER_ROLES_FETCH_FAILURE:
      return {
        ...state,
        partnerRolesList: {
          ...state.partnerRolesList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    //==================================  GENDERS FETCH END  ==================================//
    default:
      return state;
  }
};
