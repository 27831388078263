import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import I18n from 'redux-i18n';
import { Helmet } from 'react-helmet';
import ReactModal from 'react-modal';

import { AppRoute } from './lib/app-route';
import { AppConfig } from './config';
import Store from './store';
import { translations } from './translations';
import routes from './routes';

import './assets/styles/vendors/react-select/react-select.css';
// import react ladda
import 'ladda/dist/ladda-themeless.min.css';
// import spinkit styles
import 'spinkit/css/spinkit.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/css/simple-line-icons.css';

import './assets/styles/style.css';

const StoreInstance = Store();

const lang =
  (window.localStorage && window.localStorage.getItem('lang')) || AppConfig.defaultLanguage;

const App = () => {
  ReactModal.setAppElement('body');
  return (
    <Provider store={StoreInstance}>
      <Helmet titleTemplate={`%s - TheTag`} />
      <I18n translations={translations} initialLang={lang} fallbackLang="en">
        <BrowserRouter>
          <Switch>
            {routes.map((route, index) => {
              return <AppRoute key={index} {...route} />;
            })}
          </Switch>
        </BrowserRouter>
      </I18n>
    </Provider>
  );
};

export default App;
