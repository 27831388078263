import { UserActions } from '../actions';

import { MODE } from './mode';

const INITIAL_STATE = {
  usersList: { data: [], params: {}, pager: null, error: null, loading: false },
  activeUser: { data: null, error: null, mode: null },
  userRolesList: { data: [], params: {}, pager: null, error: null, loading: false },
  userSessionsList: { data: [], params: {}, pager: null, error: null, loading: false },
  userTagsList: { data: [], params: {}, pager: null, error: null, loading: false },
  userCheckinsList: { data: [], params: {}, pager: null, error: null, loading: false },
  activeTag: { data: null, error: null, mode: null },
};

export const users = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActions.TYPES.USER_CREATE_RESET:
    case UserActions.TYPES.USER_UPDATE_RESET:
    case UserActions.TYPES.USER_DELETE_RESET:
    case UserActions.TYPES.USER_FETCH_RESET:
      return {
        ...state,
        activeUser: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== USER CREATE START ==================================//
    case UserActions.TYPES.USER_CREATE_START:
      return {
        ...state,
        activeUser: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case UserActions.TYPES.USER_CREATE_FAILURE:
      return {
        ...state,
        activeUser: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case UserActions.TYPES.USER_CREATE_SUCCESS:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: state.usersList.data.concat(action.payload),
          error: null,
          loading: false,
        },
        activeUser: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  USER CREATE END  ==================================//
    //================================== USER INVITE START ==================================//
    case UserActions.TYPES.USER_INVITE_START:
      return {
        ...state,
        activeUser: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case UserActions.TYPES.USER_INVITE_FAILURE:
      return {
        ...state,
        activeUser: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case UserActions.TYPES.USER_INVITE_SUCCESS:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: state.usersList.data.concat(action.payload),
          error: null,
          loading: false,
        },
        activeUser: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };

    //==================================  USER INVITE END  ==================================//
    //================================== USER UPDATE START ==================================//
    case UserActions.TYPES.USER_UPDATE_START:
      return {
        ...state,
        activeUser: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case UserActions.TYPES.USER_UPDATE_FAILURE:
      return {
        ...state,
        activeUser: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case UserActions.TYPES.USER_UPDATE_SUCCESS:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: state.usersList.data.map(perm => {
            if (perm.id === action.payload.id) {
              perm = action.payload;
            }
            return perm;
          }),
          error: null,
          loading: false,
        },
        activeUser: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  USER UPDATE END  ==================================//
    //================================== USER DELETE START ==================================//
    case UserActions.TYPES.USER_DELETE_START:
      return {
        ...state,
        activeUser: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case UserActions.TYPES.USER_DELETE_FAILURE:
      return {
        ...state,
        activeUser: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case UserActions.TYPES.USER_DELETE_SUCCESS:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: state.usersList.data.filter(user => user.id !== action.payload.id),
          error: null,
          loading: false,
        },
        activeUser: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  USER DELETE END  ==================================//
    //================================== USER FETCH START ==================================//
    case UserActions.TYPES.USER_FETCH_START:
      return {
        ...state,
        activeUser: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case UserActions.TYPES.USER_FETCH_FAILURE:
      return {
        ...state,
        activeUser: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case UserActions.TYPES.USER_FETCH_SUCCESS:
      return {
        ...state,
        activeUser: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  USER FETCH END  ==================================//
    //================================== USERS FETCH START ==================================//
    case UserActions.TYPES.USERS_FETCH_START:
      return {
        ...state,
        usersList: {
          data: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case UserActions.TYPES.USERS_FETCH_SUCCESS:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case UserActions.TYPES.USERS_FETCH_FAILURE:
      return {
        ...state,
        usersList: {
          ...state.usersList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case UserActions.TYPES.USERS_FETCH_RESET:
      return {
        ...state,
        usersList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  USERS FETCH END  ==================================//
    //================================== USER ROLES FETCH START ==================================//
    case UserActions.TYPES.USER_ROLES_FETCH_START:
      return {
        ...state,
        userRolesList: {
          ...state.userRolesList,
          params: action.payload,
          error: null,
          loading: true,
        },
      };
    case UserActions.TYPES.USER_ROLES_FETCH_SUCCESS:
      return {
        ...state,
        userRolesList: {
          ...state.userRolesList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case UserActions.TYPES.USER_ROLES_FETCH_FAILURE:
      return {
        ...state,
        userRolesList: {
          ...state.userRolesList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case UserActions.TYPES.USER_ROLES_FETCH_RESET:
      return {
        ...state,
        userRolesList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  USERS FETCH END  ==================================//
    //================================== USER SESSIONS FETCH START ==================================//
    case UserActions.TYPES.USER_SESSIONS_FETCH_START:
      return {
        ...state,
        userSessionsList: {
          ...state.userSessionsList,
          params: action.payload,
          error: null,
          loading: true,
        },
      };
    case UserActions.TYPES.USER_SESSIONS_FETCH_SUCCESS:
      return {
        ...state,
        userSessionsList: {
          ...state.userSessionsList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case UserActions.TYPES.USER_SESSIONS_FETCH_FAILURE:
      return {
        ...state,
        userSessionsList: {
          ...state.userSessionsList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case UserActions.TYPES.USER_SESSIONS_FETCH_RESET:
      return {
        ...state,
        userSessionsList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  USERS FETCH END  ==================================//
    //================================== USER TAGS FETCH START ==================================//
    case UserActions.TYPES.USER_TAGS_FETCH_START:
      return {
        ...state,
        userTagsList: {
          ...state.userTagsList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case UserActions.TYPES.USER_TAGS_FETCH_SUCCESS:
      return {
        ...state,
        userTagsList: {
          ...state.userTagsList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case UserActions.TYPES.USER_TAG_S_FETCH_FAILURE:
      return {
        ...state,
        userTagsList: {
          ...state.userTagsList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case UserActions.TYPES.USER_TAGS_FETCH_RESET:
      return {
        ...state,
        userTagsList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  USER TAGS FETCH END  ==================================//
    //==================================  USER TAG FETCH START ==================================//
    case UserActions.TYPES.USER_TAG_FETCH_START:
      return {
        ...state,
        activeTag: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case UserActions.TYPES.USER_TAG_FETCH_FAILURE:
      return {
        ...state,
        activeTag: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case UserActions.TYPES.USER_TAG_FETCH_SUCCESS:
      return {
        ...state,
        activeTag: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  USER TAG FETCH END  ==================================//
    //================================== USER TAG UPDATE START ==================================//
    case UserActions.TYPES.USER_TAG_UPDATE_START:
      return {
        ...state,
        activeTag: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case UserActions.TYPES.USER_TAG_UPDATE_FAILURE:
      return {
        ...state,
        activeTag: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case UserActions.TYPES.USER_TAG_UPDATE_SUCCESS:
      return {
        ...state,
        activeTag: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  USER TAG UPDATE END  ==================================//
    //================================== USER CHECKINS FETCH START ==================================//
    case UserActions.TYPES.USER_CHECKINS_FETCH_START:
      return {
        ...state,
        userCheckinsList: {
          ...state.userCheckinsList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case UserActions.TYPES.USER_CHECKINS_FETCH_SUCCESS:
      return {
        ...state,
        userCheckinsList: {
          ...state.userCheckinsList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case UserActions.TYPES.USER_CHECKINS_FETCH_FAILURE:
      return {
        ...state,
        userCheckinsList: {
          ...state.userCheckinsList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case UserActions.TYPES.USER_CHECKINS_FETCH_RESET:
      return {
        ...state,
        userCheckinsList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  USER CHECKINS FETCH END  ==================================//
    default:
      return state;
  }
};
