import BaseAPI from './base-api';

const PartnersAPI = {};

PartnersAPI.CreatePartner = async partner => {
  const response = await BaseAPI.post(`partners/`, {
    ...partner,
  });

  return response.data;
};

PartnersAPI.UpdatePartner = async partner => {
  const response = await BaseAPI.put(`partners/${partner.id}/`, {
    ...partner,
  });

  return response.data;
};

PartnersAPI.DeletePartner = async partner => {
  const response = await BaseAPI.delete(`partners/${partner.id}/`, {
    ...partner,
  });

  return response.data;
};

PartnersAPI.FetchPartner = async id => {
  const response = await BaseAPI.get(`partners/${id}/`);

  return response.data;
};

PartnersAPI.FetchReferralCode = async refCode => {
  const response = await BaseAPI.get(`referrals/${refCode}`);

  return response.data;
};

PartnersAPI.FetchPartners = async params => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`partners/?${query}`);

  return response.data;
};

PartnersAPI.FetchPartnerUsers = async (partnerId, params) => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`partners/${partnerId}/team/?${query}`);

  return response.data;
};

PartnersAPI.AddPartnerUser = async (partnerId, data) => {
  const response = await BaseAPI.post(`partners/${partnerId}/team/`, {
    ...data,
  });
  return response.data;
};

PartnersAPI.DeletePartnerUser = async (partnerId, userId) => {
  const response = await BaseAPI.delete(`partners/${partnerId}/team/${userId}`);
  return response.data;
};

PartnersAPI.FetchTagSeries = async (partnerId, params) => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`partners/${partnerId}/tag-series/?${query}`);

  return response.data;
};

PartnersAPI.InsertTagSeries = async (partnerId, data) => {
  const response = await BaseAPI.post(`partners/${partnerId}/tag-series/`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.UpdatePartnerTagSeries = async (partnerId, data) => {
  const response = await BaseAPI.put(`partners/${partnerId}/tag-series/${data.id}/`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.DeletePartnerTagSeries = async (partnerId, tagSeriesId) => {
  const response = await BaseAPI.delete(`partners/${partnerId}/tag-series/${tagSeriesId}/`);
  return response.data;
};

PartnersAPI.GenerateTags = async (partnerId, tagSeriesId) => {
  const response = await BaseAPI.post(
    `partners/${partnerId}/tag-series/${tagSeriesId}/generate-tags`
  );

  return response.data;
};

PartnersAPI.FetchTag = async id => {
  const response = await BaseAPI.get(`tags/${id}/`);
  return response.data;
};

PartnersAPI.FetchTags = async (partnerId, params) => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`partners/${partnerId}/tags?${query}`);

  return response.data;
};

PartnersAPI.FetchPrintLabels = async (partnerId, params) => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`shipping/partners/${partnerId}/labels?${query}`);

  return response.data;
};

PartnersAPI.FetchEvents = async (partnerId, params) => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`partners/${partnerId}/events/?${query}`);

  return response.data;
};

PartnersAPI.InsertEvent = async (partnerId, data) => {
  const response = await BaseAPI.post(`partners/${partnerId}/events/`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.FetchBoosters = async (partnerId, params) => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`partners/${partnerId}/boosters/?${query}`);

  return response.data;
};

PartnersAPI.InsertBooster = async (partnerId, data) => {
  const response = await BaseAPI.post(`partners/${partnerId}/boosters/`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.UpdateBooster = async (partnerId, data) => {
  const response = await BaseAPI.put(`partners/${partnerId}/boosters/${data.id}/`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.DeleteBooster = async (partnerId, boosterId) => {
  const response = await BaseAPI.delete(`partners/${partnerId}/boosters/${boosterId}/`);
  return response.data;
};

PartnersAPI.UpdateEvent = async (partnerId, data) => {
  const response = await BaseAPI.put(`partners/${partnerId}/events/${data.id}/`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.FetchCheckins = async (partnerId, params) => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`partners/${partnerId}/checkins?${query}`);
  return response.data;
};

PartnersAPI.DeleteEvent = async (partnerId, eventId) => {
  const response = await BaseAPI.delete(`partners/${partnerId}/events/${eventId}/`);
  return response.data;
};

PartnersAPI.FetchLeaderboard = async partnerId => {
  const response = await BaseAPI.get(`me/partners/${partnerId}/leaderboard`);
  return response.data;
};

PartnersAPI.UpdateTag = async data => {
  const response = await BaseAPI.post(`tags/${data.id}/`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.SellTags = async (partnerId, tagSeriesId, data) => {
  const response = await BaseAPI.post(
    `partners/${partnerId}/tag-serieses/${tagSeriesId}/offline-sale`,
    {
      ...data,
    }
  );

  return response.data;
};

PartnersAPI.TagChangePin = async data => {
  const response = await BaseAPI.put(`tags/${data.id}/change-pin`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.FetchPartnerStores = async partnerId => {
  const response = await BaseAPI.get(`partners/${partnerId}/partner-stores/`);

  return response.data;
};

PartnersAPI.InsertPartnerStore = async (partnerId, data) => {
  const response = await BaseAPI.post(`partners/${partnerId}/partner-stores/`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.DeletePartnerStore = async (partnerId, partnerStoreID) => {
  const response = await BaseAPI.delete(`partners/${partnerId}/partner-stores/${partnerStoreID}/`);
  return response.data;
};

PartnersAPI.UpdatePartnerStore = async (partnerId, data) => {
  const response = await BaseAPI.put(`partners/${partnerId}/partner-stores/${data.id}/`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.FetchPartnerSponsors = async partnerId => {
  const response = await BaseAPI.get(`partners/${partnerId}/sponsors/`);

  return response.data;
};

PartnersAPI.InsertPartnerSponsor = async (partnerId, data) => {
  const response = await BaseAPI.post(`partners/${partnerId}/sponsors/`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.DeletePartnerSponsor = async (partnerId, partnerSponsorID) => {
  const response = await BaseAPI.delete(`partners/${partnerId}/sponsors/${partnerSponsorID}/`);
  return response.data;
};

PartnersAPI.UpdatePartnerSponsor = async (partnerId, data) => {
  const response = await BaseAPI.put(`partners/${partnerId}/sponsors/${data.id}/`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.FetchPartnerRewards = async partnerId => {
  const response = await BaseAPI.get(`partners/${partnerId}/rewards/`);

  return response.data;
};

PartnersAPI.InsertPartnerReward = async (partnerId, data) => {
  const response = await BaseAPI.post(`partners/${partnerId}/rewards/`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.DeletePartnerReward = async (partnerId, partnerRewardID) => {
  const response = await BaseAPI.delete(`partners/${partnerId}/rewards/${partnerRewardID}/`);
  return response.data;
};

PartnersAPI.UpdatePartnerReward = async (partnerId, data) => {
  const response = await BaseAPI.put(`partners/${partnerId}/rewards/${data.id}/`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.FetchBoostersTemplates = async (partnerId, params) => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`partners/${partnerId}/boosters-templates/?${query}`);

  return response.data;
};

PartnersAPI.InsertBoosterTemplate = async (partnerId, data) => {
  const response = await BaseAPI.post(`partners/${partnerId}/boosters-templates/`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.UpdateBoosterTemplate = async (partnerId, data) => {
  const response = await BaseAPI.put(`partners/${partnerId}/boosters-templates/${data.id}/`, {
    ...data,
  });

  return response.data;
};

PartnersAPI.DeleteBoosterTemplate = async (partnerId, templateId) => {
  const response = await BaseAPI.delete(`partners/${partnerId}/boosters-templates/${templateId}/`);
  return response.data;
};

export default PartnersAPI;
