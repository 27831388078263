import { TagsAPI } from '../api';

export const TYPES = {
  TAGS_FETCH_START: 'TAGS_FETCH_START',
  TAGS_FETCH_SUCCESS: 'TAGS_FETCH_SUCCESS',
  TAGS_FETCH_FAILURE: 'TAGS_FETCH_FAILURE',
  TAGS_FETCH_RESET: 'TAGS_FETCH_RESET',

  TAG_FETCH_START: 'TAG_FETCH_START',
  TAG_FETCH_SUCCESS: 'TAG_FETCH_SUCCESS',
  TAG_FETCH_FAILURE: 'TAG_FETCH_FAILURE',
  TAG_FETCH_RESET: 'TAG_FETCH_RESET',

  TAG_CREATE_START: 'TAG_CREATE_START',
  TAG_CREATE_SUCCESS: 'TAG_CREATE_SUCCESS',
  TAG_CREATE_FAILURE: 'TAG_CREATE_FAILURE',
  TAG_CREATE_RESET: 'TAG_CREATE_RESET',

  TAG_UPDATE_START: 'TAG_UPDATE_START',
  TAG_UPDATE_SUCCESS: 'TAG_UPDATE_SUCCESS',
  TAG_UPDATE_FAILURE: 'TAG_UPDATE_FAILURE',
  TAG_UPDATE_RESET: 'TAG_UPDATE_RESET',

  TAG_DELETE_START: 'TAG_DELETE_START',
  TAG_DELETE_SUCCESS: 'TAG_DELETE_SUCCESS',
  TAG_DELETE_FAILURE: 'TAG_DELETE_FAILURE',
  TAG_DELETE_RESET: 'TAG_DELETE_RESET',
};

export const createTag = tag => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.TAG_CREATE_START, payload: tag });
      const data = await TagsAPI.CreateTag(tag);
      dispatch({ type: TYPES.TAG_CREATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.TAG_CREATE_FAILURE, payload: e });
    }
  };
};

export const updateTag = tag => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.TAG_UPDATE_START, payload: tag });
      const data = await TagsAPI.UpdateTag(tag);
      dispatch({ type: TYPES.TAG_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.TAG_UPDATE_FAILURE, payload: e });
    }
  };
};

export const deleteTag = tag => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.TAG_DELETE_START, payload: tag });
      const data = await TagsAPI.DeleteTag(tag);
      dispatch({ type: TYPES.TAG_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.TAG_DELETE_FAILURE, payload: e });
    }
  };
};

export const fetchTag = id => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.TAG_FETCH_START, payload: id });
      const data = await TagsAPI.FetchTag(id);
      dispatch({ type: TYPES.TAG_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.TAG_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchTags = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.TAGS_FETCH_START, payload: params });
      const data = await TagsAPI.FetchTags(params);
      dispatch({ type: TYPES.TAGS_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.TAGS_FETCH_FAILURE, payload: e });
    }
  };
};

export const resetTagsList = () => {
  return {
    type: TYPES.TAGS_FETCH_RESET,
  };
};

export const resetActiveTag = () => {
  return {
    type: TYPES.TAG_FETCH_RESET,
  };
};
