import BaseAPI from './base-api';

const PlatformsAPI = {};

PlatformsAPI.CreatePlatform = async platform => {
  const response = await BaseAPI.post(`platforms/`, {
    ...platform,
  });

  return response.data;
};

PlatformsAPI.UpdatePlatform = async platform => {
  const response = await BaseAPI.put(`platforms/${platform.id}/`, {
    ...platform,
  });

  return response.data;
};

PlatformsAPI.DeletePlatform = async platform => {
  const response = await BaseAPI.delete(`platforms/${platform.id}/`, {
    ...platform,
  });

  return response.data;
};

PlatformsAPI.FetchPlatform = async id => {
  const response = await BaseAPI.get(`platforms/${id}/`);

  return response.data;
};

PlatformsAPI.FetchPlatforms = async params => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`platforms/?${query}`);

  return response.data;
};

export default PlatformsAPI;
