import { UploadActions } from '../actions';

const INITIAL_STATE = {
  data: null,
  error: null,
  loading: false,
  params: null,
};

export const upload = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UploadActions.TYPES.UPLOAD_FILE_START:
      return {
        ...state,
        error: null,
        loading: true,
        params: action.payload,
      };

    case UploadActions.TYPES.UPLOAD_FILE_SIGNED:
      return {
        ...state,
        data: action.payload,
        error: null,
        loading: true,
      };
    case UploadActions.TYPES.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    case UploadActions.TYPES.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
