import BaseAPI from './base-api';

const TagsAPI = {};

TagsAPI.CreateTag = async tag => {
  const response = await BaseAPI.post(`tags`, {
    tag,
  });

  return response.data;
};

TagsAPI.UpdateTag = async tag => {
  const response = await BaseAPI.put(`tags/${tag.id}/`, {
    tag,
  });

  return response.data;
};

TagsAPI.DeleteTag = async tag => {
  const response = await BaseAPI.delete(`tags/${tag.id}/`, {
    tag,
  });

  return response.data;
};

TagsAPI.FetchTag = async id => {
  const response = await BaseAPI.get(`tags/${id}/`);

  return response.data;
};

TagsAPI.FetchTags = async params => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`tags/?${query}`);

  return response.data;
};

export default TagsAPI;
