import * as moment from 'moment';

import { AppConfig } from '../config';
import { decodeToken } from '../lib/jwt';
import { AccountActions } from '../actions';

const refreshTokenMiddleware = store => next => action => {
  // check only action types that are starting
  if (
    action.type &&
    action.type !== 'ACCOUNT_REFRESH_TOKEN_START' &&
    action.type.indexOf('_START') > -1
  ) {
    // get token and refresh token from state
    const { token, refreshToken } = store.getState().account;
    // check if token and Refresh token exist
    if (token && token !== '' && refreshToken && refreshToken !== '') {
      // decode token
      const decoded = decodeToken(token);
      // create token treshold
      const refreshThreshold = moment()
        .add(AppConfig.refreshTokenTreshold, 'minute')
        .unix();

      if (decoded && refreshThreshold > decoded.exp) {
        // dispatch refresh token
        store.dispatch(AccountActions.refreshToken(refreshToken));
      }
    }
  }
  next(action);
};

export default refreshTokenMiddleware;
