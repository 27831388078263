import BaseAPI from './base-api';

const CategoriesAPI = {};

CategoriesAPI.CreateCategory = async category => {
  const response = await BaseAPI.post(`categories/`, {
    ...category,
  });

  return response.data;
};

CategoriesAPI.UpdateCategory = async category => {
  const response = await BaseAPI.put(`categories/${category.id}/`, {
    ...category,
  });

  return response.data;
};

CategoriesAPI.DeleteCategory = async category => {
  const response = await BaseAPI.delete(`categories/${category.id}/`, {
    ...category,
  });

  return response.data;
};

CategoriesAPI.FetchCategory = async id => {
  const response = await BaseAPI.get(`categories/${id}/`);

  return response.data;
};

CategoriesAPI.FetchCategories = async params => {
  const query = BaseAPI.objectToQueryParams(params);
  const response = await BaseAPI.get(`categories/?${query}`);

  return response.data;
};

export default CategoriesAPI;
