import { CategoriesAPI } from '../api';

export const TYPES = {
  CATEGORIES_FETCH_START: 'CATEGORIES_FETCH_START',
  CATEGORIES_FETCH_SUCCESS: 'CATEGORIES_FETCH_SUCCESS',
  CATEGORIES_FETCH_FAILURE: 'CATEGORIES_FETCH_FAILURE',
  CATEGORIES_FETCH_RESET: 'CATEGORIES_FETCH_RESET',

  CATEGORY_FETCH_START: 'CATEGORY_FETCH_START',
  CATEGORY_FETCH_SUCCESS: 'CATEGORY_FETCH_SUCCESS',
  CATEGORY_FETCH_FAILURE: 'CATEGORY_FETCH_FAILURE',
  CATEGORY_FETCH_RESET: 'CATEGORY_FETCH_RESET',

  CATEGORY_CREATE_START: 'CATEGORY_CREATE_START',
  CATEGORY_CREATE_SUCCESS: 'CATEGORY_CREATE_SUCCESS',
  CATEGORY_CREATE_FAILURE: 'CATEGORY_CREATE_FAILURE',
  CATEGORY_CREATE_RESET: 'CATEGORY_CREATE_RESET',

  CATEGORY_UPDATE_START: 'CATEGORY_UPDATE_START',
  CATEGORY_UPDATE_SUCCESS: 'CATEGORY_UPDATE_SUCCESS',
  CATEGORY_UPDATE_FAILURE: 'CATEGORY_UPDATE_FAILURE',
  CATEGORY_UPDATE_RESET: 'CATEGORY_UPDATE_RESET',

  CATEGORY_DELETE_START: 'CATEGORY_DELETE_START',
  CATEGORY_DELETE_SUCCESS: 'CATEGORY_DELETE_SUCCESS',
  CATEGORY_DELETE_FAILURE: 'CATEGORY_DELETE_FAILURE',
  CATEGORY_DELETE_RESET: 'CATEGORY_DELETE_RESET',
};

export const createCategory = category => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.CATEGORY_CREATE_START, payload: category });
      const data = await CategoriesAPI.CreateCategory(category);
      dispatch({ type: TYPES.CATEGORY_CREATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.CATEGORY_CREATE_FAILURE, payload: e });
    }
  };
};

export const updateCategory = category => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.CATEGORY_UPDATE_START, payload: category });
      const data = await CategoriesAPI.UpdateCategory(category);
      dispatch({ type: TYPES.CATEGORY_UPDATE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.CATEGORY_UPDATE_FAILURE, payload: e });
    }
  };
};

export const deleteCategory = category => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.CATEGORY_DELETE_START, payload: category });
      const data = await CategoriesAPI.DeleteCategory(category);
      dispatch({ type: TYPES.CATEGORY_DELETE_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.CATEGORY_DELETE_FAILURE, payload: e });
    }
  };
};

export const fetchCategory = id => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.CATEGORY_FETCH_START, payload: id });
      const data = await CategoriesAPI.FetchCategory(id);
      dispatch({ type: TYPES.CATEGORY_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.CATEGORY_FETCH_FAILURE, payload: e });
    }
  };
};

export const fetchCategories = params => {
  return async dispatch => {
    try {
      dispatch({ type: TYPES.CATEGORIES_FETCH_START, payload: params });
      const data = await CategoriesAPI.FetchCategories(params);
      dispatch({ type: TYPES.CATEGORIES_FETCH_SUCCESS, payload: data });
    } catch (e) {
      dispatch({ type: TYPES.CATEGORIES_FETCH_FAILURE, payload: e });
    }
  };
};

export const resetCategoriesList = () => {
  return {
    type: TYPES.CATEGORIES_FETCH_RESET,
  };
};

export const resetActiveCategory = () => {
  return {
    type: TYPES.CATEGORY_FETCH_RESET,
  };
};

export const setActiveCategory = category => {
  return {
    type: TYPES.CATEGORY_FETCH_SUCCESS,
    payload: category,
  };
};
