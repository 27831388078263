import { PaymentActions } from '../actions';

import { MODE } from './mode';

const INITIAL_STATE = {
  stripeSession: { data: [], params: {}, pager: null, error: null, loading: false },
  soldTag: { data: [], params: {}, pager: null, error: null, loading: false },
  tagShippingAddress: { data: null, error: null, mode: null, loading: false },
  partnerTagSeriesList: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerTagSeries: { data: [], params: {}, error: null, loading: false },
  activePartner: { data: null, error: null, mode: null },
  activeEmail: { data: null, error: null, mode: null },
  shipment: { data: null, error: null, mode: null },
  lockedTag: { data: [], params: {}, pager: null, error: null, loading: false },
  tagAddressList: { data: [], params: {}, pager: null, error: null, loading: false },
  checkoutSessionList: { data: [], params: {}, pager: null, error: null, loading: false },
  partnerDetails: { data: [], params: {}, pager: null, error: null, loading: false },
};

export const payment = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PaymentActions.TYPES.CATEGORY_CREATE_RESET:
      return {
        ...state,
        stripeSession: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== CREATE PAYMENT SESSION START ==================================//
    case PaymentActions.TYPES.CREATE_PAYMENT_SESSION_START:
      return {
        ...state,
        stripeSession: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case PaymentActions.TYPES.CREATE_PAYMENT_SESSION_FALIURE:
      return {
        ...state,
        stripeSession: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PaymentActions.TYPES.CREATE_PAYMENT_SESSION_SUCCESS:
      return {
        ...state,
        stripeSession: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };
    //================================== CREATE PAYMENT SESSION END ==================================//
    //================================== PAYMENT SESSION COMPLETE START ==================================//
    case PaymentActions.TYPES.PAYMENT_SESSION_COMPLETE_START:
      return {
        ...state,
        soldTag: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case PaymentActions.TYPES.PAYMENT_SESSION_COMPLETE_FALIURE:
      return {
        ...state,
        soldTag: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PaymentActions.TYPES.PAYMENT_SESSION_COMPLETE_SUCCESS:
      return {
        ...state,
        soldTag: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };
    //================================== PAYMENT SESSION COMPLETE END ==================================//
    //================================== SAVE SHIPPING DATA START ==================================//
    case PaymentActions.TYPES.SAVE_SHIPPING_DATA_START:
      return {
        ...state,
        tagShippingAddress: {
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
          mode: MODE.CREATE,
        },
      };
    case PaymentActions.TYPES.SAVE_SHIPPING_DATA_FALIURE:
      return {
        ...state,
        tagShippingAddress: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PaymentActions.TYPES.SAVE_SHIPPING_DATA_SUCCESS:
      return {
        ...state,
        tagShippingAddress: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };
    //================================== SAVE SHIPPING DATA START ==================================//
    //================================== FETCH AVAILABLE TAG START ==================================//
    case PaymentActions.TYPES.PAYMENT_AVAILABLE_TAGS_START:
      return {
        ...state,
        lockedTag: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case PaymentActions.TYPES.PAYMENT_AVAILABLE_TAGS_FALIURE:
      return {
        ...state,
        lockedTag: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PaymentActions.TYPES.PAYMENT_AVAILABLE_TAGS_SUCCESS:
      return {
        ...state,
        lockedTag: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };
    //================================== FETCH AVAILABLE TAG END ==================================//
    //================================== VALIDATE SHIPPING DATA START ==================================//
    case PaymentActions.TYPES.VALIDATE_SHIPPING_DATA_START:
      return {
        ...state,
        shipment: {
          params: action.payload,
          data: null,
          pager: null,
          error: null,
          loading: true,
          mode: MODE.CREATE,
        },
      };
    case PaymentActions.TYPES.VALIDATE_SHIPPING_DATA_FALIURE:
      return {
        ...state,
        shipment: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PaymentActions.TYPES.VALIDATE_SHIPPING_DATA_SUCCESS:
      return {
        ...state,
        shipment: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case PaymentActions.TYPES.SHIPMENT_RESET:
      return {
        ...state,
        shipment: {
          data: null,
          loading: false,
          error: null,
          mode: null,
        },
      };
    //================================== VALIDATE SHIPPING DATA START ==================================//
    //================================== PARTNER TAG SERIES FETCH START ==================================//
    case PaymentActions.TYPES.PAYMENT_TAG_SERIES_FETCH_START:
      return {
        ...state,
        partnerTagSeries: {
          ...state.partnerTagSeries,
          params: action.payload,
          error: null,
          loading: true,
        },
      };
    case PaymentActions.TYPES.PAYMENT_TAG_SERIES_FETCH_SUCCESS:
      return {
        ...state,
        partnerTagSeries: {
          ...state.partnerTagSeries,
          data: action.payload,
          error: null,
          loading: false,
        },
      };
    case PaymentActions.TYPES.PAYMENT_TAG_SERIES_FETCH_FAILURE:
      return {
        ...state,
        partnerTagSeries: {
          ...state.partnerTagSeries,
          data: [],
          error: action.payload,
          loading: false,
        },
      };
    case PaymentActions.TYPES.PAYMENT_TAG_SERIES_FETCH_RESET:
      return {
        ...state,
        partnerTagSeries: {
          data: [],
          params: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PARTNER TAG SERIES FETCH END  ==================================//
    //================================== PAYMENT PARTNER FETCH START ==================================//
    case PaymentActions.TYPES.PAYMENT_PARTNER_FETCH_START:
      return {
        ...state,
        activePartner: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PaymentActions.TYPES.PAYMENT_PARTNER_FETCH_FAILURE:
      return {
        ...state,
        activePartner: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case PaymentActions.TYPES.PAYMENT_PARTNER_FETCH_SUCCESS:
      return {
        ...state,
        activePartner: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  PAYMENT PARTNER FETCH END  ==================================//
    //================================== PAYMENT SAVE EMAIL START ==================================//
    case PaymentActions.TYPES.PAYMENT_SAVE_EMAIL_START:
      return {
        ...state,
        activeEmail: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case PaymentActions.TYPES.PAYMENT_SAVE_EMAIL_FAILURE:
      return {
        ...state,
        activeEmail: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case PaymentActions.TYPES.PAYMENT_SAVE_EMAIL_SUCCESS:
      return {
        ...state,
        activeEmail: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };
    //==================================  PAYMENT PARTNER FETCH END  ==================================//
    //================================== PAYMENT TAG ADDRESS FETCH START ==================================//
    case PaymentActions.TYPES.PAYMENT_TAG_ADDRESS_FETCH_START:
      return {
        ...state,
        tagAddressList: {
          ...state.tagAddressList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case PaymentActions.TYPES.PAYMENT_TAG_ADDRESS_FETCH_SUCCESS:
      return {
        ...state,
        tagAddressList: {
          ...state.tagAddressList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case PaymentActions.TYPES.PAYMENT_TAG_ADDRESS_FETCH_FAILURE:
      return {
        ...state,
        tagAddressList: {
          ...state.tagAddressList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case PaymentActions.TYPES.PAYMENT_TAG_ADDRESS_FETCH_RESET:
      return {
        ...state,
        tagAddressList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PAYMENT TAG ADDRESS FETCH END  ==================================//
    //================================== PAYMENT CHECKOUT SESSIONS FETCH START ==================================//
    case PaymentActions.TYPES.PAYMENT_CHECKOUT_SESSIONS_FETCH_START:
      return {
        ...state,
        checkoutSessionList: {
          ...state.checkoutSessionList,
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case PaymentActions.TYPES.PAYMENT_CHECKOUT_SESSIONS_FETCH_SUCCESS:
      return {
        ...state,
        checkoutSessionList: {
          ...state.checkoutSessionList,
          data: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case PaymentActions.TYPES.PAYMENT_CHECKOUT_SESSIONS_FETCH_FAILURE:
      return {
        ...state,
        checkoutSessionList: {
          ...state.checkoutSessionList,
          data: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case PaymentActions.TYPES.PAYMENT_CHECKOUT_SESSIONS_FETCH_RESET:
      return {
        ...state,
        checkoutSessionList: {
          data: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  PAYMENT CHECKOUT SESSIONS FETCH END  ==================================//
    //================================== PAYMENT LANDING PAGE DATA FETCH START ==================================//
    case PaymentActions.TYPES.PAYMENT_LANDING_PAGE_DATA_FETCH_START:
      return {
        ...state,
        partnerDetails: {
          data: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PaymentActions.TYPES.PAYMENT_LANDING_PAGE_DATA_FETCH_SUCCESS:
      return {
        ...state,
        partnerDetails: {
          data: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case PaymentActions.TYPES.PAYMENT_LANDING_PAGE_DATA_FETCH_FAILURE:
      return {
        ...state,
        partnerDetails: {
          data: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    //==================================  PAYMENT LANDING PAGE DATA FETCH END  ==================================//
    default:
      return state;
  }
};
